import { AwareTableConfig } from './symbols';

export const awareTableConfig: AwareTableConfig = {
    columns: [],
    externalSorting: true,
    limit: 25,
    sort: {
        prop: 'model.created_at',
        dir: 'desc',
    },
    column: {
        draggable: false,
        flexGrow: 1,
        resizeable: false,
    },
};
