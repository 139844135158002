import { State, StateContext, Action, createSelector } from '@ngxs/store';
import { Notification } from './Notification';
import {
    GetMyNotifications,
    ClearNotificationsByType,
    ClearNotificationsByUnit,
    ClearLocalNotifications,
    ClearNotificationsByValue,
} from './notification.actions';
import { tap, map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AwareHttpService } from '@appbolaget/aware-http';
import { Subscription } from 'rxjs';

export interface NotificationStateModel {
    notifications: Notification[];
}

@State<NotificationStateModel>({
    name: 'notification',
    defaults: {
        notifications: null,
    },
})
@Injectable()
export class NotificationState {
    notificationListener: Subscription;

    static notificationsByType(type: string) {
        return createSelector([NotificationState], (state: NotificationStateModel) => {
            return state.notifications ? state.notifications.filter((notification) => notification.type === type) : null;
        });
    }

    static notificationsByUnit(unit_id: number) {
        return createSelector([NotificationState], (state: NotificationStateModel) => {
            return state.notifications ? state.notifications.filter((notification) => notification.unit_id === unit_id) : null;
        });
    }

    static notificationsByValue(value: string) {
        return createSelector([NotificationState], (state: NotificationStateModel) => {
            return state.notifications ? state.notifications.filter((notification) => notification.value === value) : null;
        });
    }

    static notificationByValue(value: string) {
        return createSelector([NotificationState], (state: NotificationStateModel) => {
            return state.notifications?.find((notification) => notification.value === value);
        });
    }

    constructor(private api: AwareHttpService) {}

    @Action(ClearLocalNotifications)
    clearLocalNotifications({ patchState }: StateContext<NotificationStateModel>) {
        patchState({
            notifications: null,
        });
    }

    @Action(ClearNotificationsByType)
    clearNotificationsByType({ dispatch }: StateContext<NotificationStateModel>, { type }: ClearNotificationsByType) {
        return this.api
            .delete('clients/notifications', { type }, 'all')
            .module('')
            .execute()
            .pipe(switchMap((_) => dispatch(new GetMyNotifications())));
    }

    @Action(ClearNotificationsByUnit)
    clearNotificationsByUnit({ dispatch }: StateContext<NotificationStateModel>, { unit_id }: ClearNotificationsByUnit) {
        return this.api
            .delete('clients/notifications', { unit: unit_id }, 'all')
            .module('')
            .execute()
            .pipe(switchMap((_) => dispatch(new GetMyNotifications())));
    }

    @Action(ClearNotificationsByValue)
    clearNotificationsByValue({ dispatch }: StateContext<NotificationStateModel>, { value }: ClearNotificationsByValue) {
        return this.api
            .delete('clients/notifications', { value }, 'all')
            .module('')
            .execute()
            .pipe(switchMap((_) => dispatch(new GetMyNotifications())));
    }

    @Action(GetMyNotifications)
    getMyNotifications({ patchState }: StateContext<NotificationStateModel>) {
        return this.api
            .get('clients/notifications')
            .sort('created_at', 'desc')
            .module('')
            .execute()
            .pipe(
                map((result) => {
                    return result.data.map((notification) => new Notification(notification));
                }),
                tap((notifications) => {
                    patchState({
                        notifications,
                    });
                }),
            );
    }
}
