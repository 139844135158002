import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AwareHttpRequest, AwareHttpService } from '@appbolaget/aware-http';
import { Role } from '@appbolaget/aware-model';
import { AwareMultiSelectComponent } from '../multiselect-2/multiselect-2.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
    selector: 'notification-form',
    templateUrl: 'notification-form.component.html',
    imports: [FormsModule, ReactiveFormsModule, MatSlideToggleModule, AwareMultiSelectComponent]
})
export class NotificationFormComponent implements OnInit {
    @Input('group') formGroup: AbstractControl;

    get group(): UntypedFormGroup {
        return this.formGroup as UntypedFormGroup;
    }

    rolesRequest: AwareHttpRequest;
    constructor(private api: AwareHttpService) {}

    ngOnInit() {
        this.rolesRequest = this.api.get('roles').stream('down').toCollection(Role);
    }
}
