<mat-toolbar class="Topbar" [class.Topbar--hijacking]="hijacker$ | async">
  <div class="flex items-center gap-2">
    @if (!nav.opened) {
      <img class="Topbar__logo" src="/assets/images/ab_logo--color.png" />
    }
    @if (hasSidebar$ | async) {
      <button mat-icon-button type="button" (click)="toggleSideNav()">
        <mat-icon>menu</mat-icon>
      </button>
    }

    <ng-container *awIsGod>
      @if (api.apiEnv$ | async; as env) {
        <button
          mat-icon-button
          type="button"
          [matBadge]="env.name | shorten: 1 | ucfirst"
          [matMenuTriggerFor]="apiEnvMenu"
          [matMenuTriggerData]="{ envs: availableEnvs$ | async, activeEnv: env }"
          [matTooltip]="env.name + ': ' + env.url"
        >
          <mat-icon>api</mat-icon>
        </button>
      }
    </ng-container>
    @if (hijacker$ | async; as hijackState) {
      <div class="Topbar__pilot flex items-center justify-start">
        <mat-icon>fingerprint</mat-icon>
        Pilot: {{ hijackState.client.name }}
        <button matTooltip="Avbryt pilotläge" type="button" mat-icon-button (click)="cancelHijack()">
          <mat-icon>cancel</mat-icon>
        </button>
      </div>
    }
  </div>
  <app-topbar-unit-tree class="flex flex-col flex-1 overflow-hidden" (onSelectUnit)="setActiveUnit($event)"></app-topbar-unit-tree>
  <div class="flex items-center flex-none">
    @if (messagesConfig$ | async; as messagesConfig) {
      @if (messagesConfig.button) {
        <button
          mat-icon-button
          type="button"
          matTooltip="Meddelanden"
          [matBadge]="unreadThreads$ | async"
          [matBadgeHidden]="(unreadThreads$ | async) <= 0"
          matBadgePosition="above after"
          (click)="messagesButtonClicked($event)"
        >
          <mat-icon>chat</mat-icon>
        </button>
      }
    }

    @if (config.get('unitConfig.panel.feedback.enabled')) {
      <button mat-icon-button type="button" (click)="toggleFeedbackDialog()" matTooltip="Skicka feedback">
        <mat-icon>rate_review</mat-icon>
      </button>
    }

    @if (config.get('unitConfig.panel.systemnotices.enabled')) {
      <button
        mat-icon-button
        type="button"
        matTooltip="Systemuppdateringar"
        [matBadge]="(systemNotices$ | async)?.length"
        [matBadgeHidden]="(systemNotices$ | async)?.length <= 0"
        matBadgePosition="above after"
        (click)="toggleSystemNoticesDialog($event)"
      >
        <mat-icon>update</mat-icon>
      </button>
    }

    @if (config.get('unitConfig.panel.help.enabled')) {
      <button mat-icon-button type="button" matTooltip="Hjälpcenter" (click)="toggleHelpDialog($event)">
        <mat-icon>help_outline</mat-icon>
      </button>
    }

    @if (!config.get('unitConfig.panel.hideMediaInTopBar')) {
      <button mat-icon-button type="button" matTooltip="Mediabibliotek" (click)="openMediaDialog()" *awIsGranted="'filesystem.read'">
        <mat-icon>perm_media</mat-icon>
      </button>
    }

    <button class="Topbar__button" type="button" mat-button [matMenuTriggerFor]="apiLanguageMenu">
      <div class="flex items-center gap-2">
        <mat-icon>language</mat-icon>
        {{ languageService.getCurrentApiLanguage() }}
        <mat-icon>arrow_drop_down</mat-icon>
      </div>
    </button>

    @if (activeUnit$ | async; as unit) {
      <button class="Topbar__button" mat-button type="button" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{ unit: unit }">
        <div class="flex gap-2 items-center">
          <mat-icon>account_circle</mat-icon>
          {{ (client$ | async).given_name }}
          <!-- <div class="Topbar__button__badge" [hidden]="!badge || (hasSidebar$ | async)"></div> -->
          <mat-icon>arrow_drop_down</mat-icon>
        </div>
      </button>
    }
  </div>
  <mat-menu class="Topbar__menu" #menu="matMenu" xPosition="before" yPosition="below">
    <ng-template matMenuContent let-unit="unit">
      @if (units.length > 1) {
        <button mat-menu-item type="button" [matMenuTriggerFor]="unitMenu" [matMenuTriggerData]="{ unit: unit }" (click)="goToUnit(unit)">
          <mat-icon>home</mat-icon>
          {{ unit.title }}
        </button>
      }
      <mat-divider></mat-divider>
      <button mat-menu-item type="button" [matMenuTriggerFor]="languageMenu">
        <mat-icon>language</mat-icon>
        {{ 'LANGUAGES.LANGUAGE' | translate }}
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item type="button" (click)="goToSettings()">
        <mat-icon>settings</mat-icon>
        {{ 'COMMON.SETTINGS' | translate }}
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item type="button" (click)="signout()"><mat-icon>lock</mat-icon> {{ 'COMMON.LOGOUT' | translate }}</button>
    </ng-template>
  </mat-menu>

  <mat-menu #unitMenu="matMenu" xPosition="before">
    <ng-template matMenuContent let-unit="unit">
      @for (obj of units | orderBy: 'title'; track obj) {
        <button mat-menu-item type="button" (click)="setActiveUnit(obj)" [disabled]="obj.uuid === unit.uuid">
          <div class="flex flex-col">
            @if (obj.attr('parents', 'json'); as parents) {
              <p class="Org-list__item__path">
                @for (parent of $any(parents); track parent) {
                  <span>{{ parent.title }}</span>
                }
              </p>
            }
            <p class="Org-list__item__title">{{ obj.title }}</p>
          </div>
        </button>
      }
    </ng-template>
  </mat-menu>

  <mat-menu #languageMenu="matMenu" xPosition="before">
    @for (obj of languageService.languages | orderBy: 'title'; track obj) {
      <button mat-menu-item type="button" (click)="setLanguage(obj)" [disabled]="obj.code === languageService.activeLanguage">
        <div class="flex flex-col">
          <p class="Org-list__item__title">{{ obj.title | translate }}</p>
        </div>
      </button>
    }
  </mat-menu>
</mat-toolbar>

<mat-menu class="Topbar__menu" #apiLanguageMenu="matMenu" xPosition="before" yPosition="below">
  @for (obj of languageService.apiLanguages | orderBy: 'title'; track obj) {
    <button
      mat-menu-item
      type="button"
      mat-menu-item
      (click)="setApiLanguage(obj)"
      [disabled]="obj.code === languageService.activeApiLanguage"
    >
      {{ obj.title | translate }}
    </button>
  }
</mat-menu>

<mat-menu #apiEnvMenu="matMenu" xPosition="before">
  <ng-template matMenuContent let-envs="envs" let-activeEnv="activeEnv">
    @for (env of envs; track env) {
      <button mat-menu-item type="button" (click)="setApiEnv(env)" [disabled]="env.name === activeEnv.name">
        {{ env.name }}
      </button>
    }
    <mat-divider></mat-divider>
    <button mat-menu-item type="button" (click)="setApiEnv(null)">Sätt default</button>
  </ng-template>
</mat-menu>
