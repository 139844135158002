import { Component, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { BaseDialogComponent } from '@helpers/base';
import { RepeatDialogService } from './repeat-dialog.service';
import { Helpers } from '@helpers';
import moment from 'moment';
import { takeWhile, tap } from 'rxjs/operators';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';

@Component({
    selector: 'repeat-dialog',
    templateUrl: './repeat-dialog.html',
    styleUrls: ['./repeat-dialog.scss'],
    providers: [RepeatDialogService],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatToolbarModule,
        MatDialogModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        MatRadioModule,
        MatInputModule,
        MatDatepickerModule,
    ]
})
export class RepeatDialogComponent extends BaseDialogComponent {
    form: UntypedFormGroup;
    repeater: any;

    ALIVE: boolean;
    interval: any = Helpers.range(1, 99);
    min: string;

    constructor(
        public dialogRef: MatDialogRef<RepeatDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public fb: UntypedFormBuilder,
        public service: RepeatDialogService,
    ) {
        super();
    }

    ngOnInit() {
        this.ALIVE = true;
        this.repeater = this.data.repeater;
        this.min = moment(this.data.min ?? moment()).format('YYYY-MM-DD');

        if (!this.repeater) {
            this.repeater = {
                frequency: this.service.frequencies[0],
                interval: [],
                end_activity: 'never',
                end_date: this.min,
            };
        }

        this.buildForm();
    }

    ngOnDestroy() {
        this.ALIVE = false;
    }

    buildForm() {
        this.form = this.fb.group({
            frequency: [this.repeater.frequency, Validators.required],
            interval: [this.repeater.interval, Validators.required],
            end_activity: [this.repeater.end_activity, Validators.required],
            end_date: [this.repeater.end_date],
        });

        this.form
            .get('frequency')
            .valueChanges.pipe(
                takeWhile(() => this.ALIVE),
                tap((frequency) => {
                    let interval = frequency === 'weekdays' ? [] : null;

                    this.form.get('interval').setValue(interval);
                }),
            )
            .subscribe();
    }

    compareFn(c1: any, c2: any): boolean {
        return c1 && c2 ? c1.value === c2.value : c1 === c2;
    }

    submit() {
        const values = this.form.value;

        if (values.end_activity === 'date' && !moment(values.end_date).isValid()) {
            values.end_activity = 'never';
        }

        this.dismiss(Object.assign({}, values, { repeatLabel: this.service.getRepeatLabel(values) }));
    }
}
