import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Client, Thread } from '@appbolaget/aware-model';
import { BaseDialogComponent } from '@helpers/base';
import { THREAD_ACTIONS, ThreadAction } from '@pages/messages/symbols';
import { MessagesThreadListItemComponent } from '../thread-list-item/thread-list-item.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { AlertComponent } from '@components/index';
import { MatButton } from '@angular/material/button';

export interface ThreadSelectionPromptData {
    threads: Thread[];
    client?: Client;
    canAddToExisting: boolean;
    canCreateNew: boolean;
}

export type ThreadSelectionPromptResult = Thread | ThreadAction | null | boolean;

interface ThreadActionButton {
    icon: string;
    label: string;
    value: ThreadAction;
}

@Component({
    selector: 'app-messages-thread-selection-prompt',
    templateUrl: 'thread-selection-prompt.component.html',
    imports: [MessagesThreadListItemComponent, MatToolbarModule, MatIconModule, AlertComponent, MatButton],
})
export class ThreadSelectionPromptComponent extends BaseDialogComponent {
    get dialogMessage(): string {
        return this.data.threads.length > 0
            ? this.data.threads.length === 1
                ? this.data.threads[0].clients.length > 2
                    ? 'Du har redan en tråd med dessa personer. Vill du öppna den istället?'
                    : 'Du har redan en tråd med denna person. Vill du öppna den istället?'
                : this.data.threads[0].clients.length > 2
                  ? 'Du har redan flera trådar med dessa personer, vad vill du göra?'
                  : 'Du har redan flera trådar med denna person, vad vill du göra?'
            : 'Vad vill du göra?';
    }

    get hasMultipleThreads(): boolean {
        return this.data.threads.length > 1;
    }

    buttons: ThreadActionButton[] = [
        ...(this.data.canCreateNew
            ? [
                  {
                      label: this.data.threads.length === 1 ? 'Nej, skapa ny tråd' : 'Skapa ny tråd',
                      value: THREAD_ACTIONS.CreateNew,
                      icon: 'edit_note',
                  },
              ]
            : []),
        ...(this.data.canAddToExisting
            ? [
                  {
                      label: this.data.threads.length === 1 ? 'Nej, lägg till i nuvarande tråd' : 'Lägg till i nuvarande tråd',
                      value: THREAD_ACTIONS.AddToExisting,
                      icon: 'person_add',
                  },
              ]
            : []),
        ...(this.data.threads.length === 1
            ? [
                  {
                      label: 'Ja, öppna tråd',
                      value: THREAD_ACTIONS.OpenThread,
                      icon: 'open_in_new',
                  },
              ]
            : []),
    ];

    constructor(
        public dialogRef: MatDialogRef<ThreadSelectionPromptComponent, ThreadSelectionPromptResult>,
        @Inject(MAT_DIALOG_DATA) public data: ThreadSelectionPromptData,
    ) {
        super();
    }

    selectThreadAction(action: ThreadAction) {
        this.dialogRef.close(action);
    }
}
