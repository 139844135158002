import { Pipe, PipeTransform } from '@angular/core';
import { ThreadLastMessage } from '@appbolaget/aware-model';

@Pipe({
    name: 'lastMessage',
    standalone: true,
})
export class LastMessagePipe implements PipeTransform {
    transform(lastMessage: ThreadLastMessage): { message: string; icon: string } {
        let message = '';
        let icon = null;

        if (!lastMessage) {
            return null;
        }

        if (lastMessage.message) {
            message = lastMessage.message;
        }

        if (lastMessage.files?.length) {
            message = '<i>Bifogad fil</i>';
            icon = 'attach_file';
        }

        if (lastMessage.posts?.length) {
            message = '<i>Delade en post</i>';
            icon = 'share';
        }

        return { message, icon };
    }
}
