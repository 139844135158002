import { Component, Input } from '@angular/core';
import { TrustHtmlPipe } from '@helpers/pipes/trust-html.pipe';

@Component({
    selector: 'app-content-view',
    templateUrl: 'content-view.component.html',
    imports: [TrustHtmlPipe],
    host: {
        class: 'leading-6 text-gray-900',
    },
    styles: [
        `
            .ck-content {
                ul > li > ul,
                ol > li > ol {
                    margin-bottom: 0;

                    li:last-child {
                        padding-bottom: 0;
                    }
                }
            }
        `,
    ]
})
export class ContentViewComponent {
    @Input({ required: true }) content: string;
}
