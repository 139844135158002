@if (activeUnit$ | async; as unit) {
  <div class="flex items-center justify-center" topBarUnitTree (onHiddenElements)="hiddenUnitUuids$.next($event)">
    @if (hiddenUnits$ | async; as hiddenUnits) {
      @if (hiddenUnits.length) {
        <button mat-icon-button type="button" [matMenuTriggerFor]="childrenMenu" [matMenuTriggerData]="{ units: hiddenUnits }">
          <mat-icon>more_vert</mat-icon>
        </button>
      }
    }
    @for (obj of smartHierarchy$ | async; track obj) {
      <div class="flex items-center justify-center" [attr.data-uuid]="obj.uuid" #unitHierarchyItem>
        <button mat-button (click)="onSelectUnit.next(obj)">{{ obj.title }}</button>
        <span class="text-gray-300">/</span>
      </div>
    }
    <a mat-button class="flex-none" [routerLink]="['/app/units', unit.uuid]">{{ unit.title }}</a>
    @if (unit.children && unit.children.length) {
      <button
        mat-icon-button
        class="flex-none"
        type="button"
        [matMenuTriggerFor]="childrenMenu"
        [matMenuTriggerData]="{ units: unit.children }"
      >
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
    }
    <button mat-icon-button type="button" (click)="showUnitSearch()" *awIsGod>
      <mat-icon>search</mat-icon>
    </button>
  </div>
}

<mat-menu #childrenMenu="matMenu" [classList]="'Dropdown-menu Dropdown-menu--auto-width'">
  <ng-template matMenuContent let-units="units">
    @for (unit of units | orderBy: 'title'; track unit) {
      <button mat-menu-item type="button" (click)="onSelectUnit.next(unit)">
        {{ unit.title }}
      </button>
    }
  </ng-template>
</mat-menu>
