<mat-list dense class="Contact-list">
  @for (client of clients; track client) {
  <mat-list-item class="Contact-list__contact" (click)="expandContact(client)" [matMenuTriggerFor]="appMenu">
    <mat-icon matListItemIcon>person</mat-icon>
    <p matListItemTitle>{{ client.name }}</p>
  </mat-list-item>
  }
</mat-list>

<mat-menu #appMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item [hidden]="!activeClient?.getCommunicationWithType('phone')" (click)="call()">
    <mat-icon>contact_phone</mat-icon><span>Ring: {{ activeClient?.getCommunicationWithType('phone')?.value }}</span>
  </button>
  <button mat-menu-item [hidden]="!activeClient?.getCommunicationWithType('email')" (click)="email()">
    <mat-icon>contact_mail</mat-icon><span>Maila: {{ activeClient?.getCommunicationWithType('email')?.value }}</span>
  </button>
</mat-menu>
