import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Thread } from '@appbolaget/aware-model';
import { ClientAvatarComponent } from '@components/client-avatar/client-avatar.component';
import { ExcludeMyselfPipe } from '@helpers/pipes/exclude-myself.pipe';
import { HasUnreadMessagesPipe } from '@pages/messages/pipes/has-unread-messages.pipe';
import { LastMessagePipe } from '@pages/messages/pipes/last-message.pipe';
import { ThreadTitlePipe } from '@pages/messages/pipes/thread-title.pipe';
import { MomentModule } from 'ngx-moment';
@Component({
    selector: 'app-messages-thread-list-item',
    templateUrl: 'thread-list-item.component.html',
    imports: [
        ClientAvatarComponent,
        ExcludeMyselfPipe,
        ThreadTitlePipe,
        HasUnreadMessagesPipe,
        LastMessagePipe,
        MomentModule,
        MatIconModule,
    ],
    styles: [
        `
            :host {
                display: flex;
                flex-direction: column;
            }
        `,
    ]
})
export class MessagesThreadListItemComponent {
    @Input() thread: Thread;
    @Input() selected: boolean;
}
