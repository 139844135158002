import { Injectable } from '@angular/core';
import { AwareHttpRequest, IAwareCollection } from '@appbolaget/aware-http';

export type AwareTableStore<T = any> = {
    request: AwareHttpRequest<IAwareCollection<T>>;
    sort: { prop: string; sortcol?: string; dir: 'asc' | 'desc' };
    page: number;
    query: string;
    customFilter?: any;
};

@Injectable({ providedIn: 'root' })
export class AwareTableService {
    tables: Map<string, AwareTableStore> = new Map();

    updateStore(key: string, data: AwareTableStore) {
        let store = this.tables.get(key) || ({} as any);
        store = { ...store, ...data };

        this.tables.set(key, store);
    }

    updateCustomFilter(key: string, customFilter: any) {
        const store = this.tables.get(key) || ({} as any);
        store.customFilter = customFilter;

        this.tables.set(key, store);
    }
}
