import { Component, Input, Output, EventEmitter, OnDestroy, ViewChild, TemplateRef, AfterViewInit, inject } from '@angular/core';
import { ListDatePipe } from '@helpers/pipes';
import { debounceTime, tap, takeWhile, switchMap, map, delay, startWith, shareReplay } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { AwareHttpRequest } from '@appbolaget/aware-http';
import { BehaviorSubject, Observable, combineLatest, firstValueFrom, fromEvent, of } from 'rxjs';
import { AwareTableConfig } from './symbols';
import { SnackbarService } from '@viewservices';
import { IAwareCollection } from '@helpers/interfaces/collection.interface';
import { DatatableComponent, SelectionType, NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Unit } from '@appbolaget/aware-model';
import { AwareModel } from '@appbolaget/aware-model';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { UnitState } from 'app/state/unit.state';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AsyncPipe } from '@angular/common';
import { AwareTableService } from './aw-table-2.service';
import moment from 'moment';
import { awareTableConfig } from './aw-table-2.config';
import { SearchbarComponent } from '@components/searchbar/searchbar.component';

@Component({
    selector: 'aw-table-2',
    templateUrl: 'aw-table-2.component.html',
    styleUrls: ['./aw-table-2.scss'],
    providers: [ListDatePipe],
    imports: [NgxDatatableModule, MatButtonModule, MatIconModule, MatTooltipModule, TranslateModule, AsyncPipe, SearchbarComponent]
})
export class AwareTable2Component<T = any> implements AfterViewInit, OnDestroy {
    @ViewChild('datatable', { static: false }) datatable: DatatableComponent;
    @ViewChild('colorCellTemplate', { static: true }) colorCellTemplate: TemplateRef<any>;
    @ViewChild('titleCellTemplate', { static: true }) titleCellTemplate: TemplateRef<any>;
    @ViewChild('attributeSimpleCellTemplate', { static: true }) attributeSimpleCellTemplate: TemplateRef<any>;
    @ViewChild('attributeTextCellTemplate', { static: true }) attributeTextCellTemplate: TemplateRef<any>;

    #translateService = inject(TranslateService);
    #store = inject(Store);
    #snackbar = inject(SnackbarService);
    #awareTableService = inject(AwareTableService);

    unit: Unit;
    cellTemplates: { [key: string]: TemplateRef<any> };
    messages: any = {
        totalMessage: 'resultat',
        emptyMessage: this.#translateService.instant('COMMON.NO_DATA_IN_TABLE'),
    };
    selected: T[] = [];
    selectionTypes = SelectionType;

    ALIVE: boolean;
    IS_LOADING: boolean = true;
    READONLY = true;
    RESET_INITIAL_CONFIG = true;

    sort$: BehaviorSubject<{ prop: string; sortcol?: string; dir: 'asc' | 'desc' }> = new BehaviorSubject(null);
    sorts$ = this.sort$.asObservable().pipe(map((sort) => (sort ? [sort] : null)));
    page$: BehaviorSubject<number> = new BehaviorSubject(1);
    query$: BehaviorSubject<string> = new BehaviorSubject(null);
    config$: BehaviorSubject<AwareTableConfig> = new BehaviorSubject(null);
    request$: BehaviorSubject<AwareHttpRequest<IAwareCollection<T>>> = new BehaviorSubject(null);

    now = moment().format('YYYY-MM-DD HH:mm:ss');

    /**
     * Default Config is used because ngx-datatable alters the config object passed in to it,
     * so storing it here once before for reference.
     */
    #initialConfig: AwareTableConfig;

    #initialRequest: AwareHttpRequest<IAwareCollection<T>>;

    @Input() set config(config: AwareTableConfig) {
        this.#setConfig({ ...config });
    }

    get config() {
        return this.config$.value;
    }

    #storeKey: string;
    @Input() set storeKey(storeKey: string) {
        this.RESET_INITIAL_CONFIG = true;
        this.#storeKey = storeKey;
    }

    get storeKey(): string {
        return this.#storeKey;
    }

    @Input() set request(request: AwareHttpRequest) {
        if (!this.#initialRequest) {
            this.#initialRequest = request.clone();
        }

        const storedTable = this.#awareTableService.tables.get(this.storeKey);

        if (this.storeKey && storedTable) {
            this.page$.next(storedTable.page);
            this.sort$.next(storedTable.sort);
            this.query$.next(storedTable.query);

            if (storedTable.customFilter && this.gotFilter.observed && storedTable.customFilter !== this.customFilter) {
                setTimeout(() => {
                    this.gotFilter.emit(storedTable.customFilter);
                }, 30);
            }
        } else {
            this.page$.next(1);
            this.sort$.next(null);
            this.query$.next(null);
        }

        this.request$.next(request);
    }

    get request(): AwareHttpRequest {
        return this.request$.value;
    }

    #customFilter: any;
    @Input() set customFilter(customFilter: any) {
        this.#customFilter = customFilter;
        if (this.storeKey && customFilter) {
            this.#awareTableService.updateCustomFilter(this.storeKey, customFilter);
        }
    }

    get customFilter(): any {
        return this.#customFilter;
    }

    @Input() search: boolean;
    @Output() rowClick = new EventEmitter<any>();
    @Output() rowSelect = new EventEmitter();
    @Output() gotFilter = new EventEmitter<any>();

    viewInitted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    result$: Observable<IAwareCollection<T>>;

    constructor() {
        this.ALIVE = true;
    }

    ngOnInit() {
        if (this.rowClick.observed) {
            this.READONLY = false;
        }

        this.#initListeners();
    }

    ngAfterViewInit(): void {
        this.cellTemplates = {
            color: this.colorCellTemplate,
            title: this.titleCellTemplate,
            attrSimple: this.attributeSimpleCellTemplate,
            attrText: this.attributeTextCellTemplate,
        };

        this.viewInitted$.next(true);
    }

    ngOnDestroy(): void {
        this.ALIVE = false;
    }

    getColumnConfigFromProp(prop: string, key: string) {
        const col = this.config.columns.find((c) => c.prop === prop);

        return col ? col[key] : null;
    }

    getRowClass = (row: AwareModel | any) => {
        return {
            Table__row: true,
            'Table__row--deleted': row.deleted_at?.length > 0,
            'Table__row--upstream':
                this.config?.stream && this.config.stream.direction !== 'down' ? row[this.config.stream.column] !== this.unit?.id : false,
        };
    };

    async onActivate($event) {
        if ($event.type === 'click') {
            const row = $event.row;

            const config = this.config$.value;

            if (config.stream && config.stream.direction !== 'down' && row[config.stream.column] !== this.unit.id) {
                this.#snackbar.warning(
                    'Posten kommer från en enhet ovanför i strukturen. Kontakta en administratör om du vill göra ändringar',
                );
            } else {
                this.rowClick.emit($event.row);
            }
        }

        $event.event.stopPropagation();
    }

    refresh(): void {
        this.request$.next(this.request);
    }

    setInitialRequest(_: AwareHttpRequest): void {}

    async #setConfig(config: AwareTableConfig) {
        await firstValueFrom(this.viewInitted$);

        if (!this.#initialConfig || this.RESET_INITIAL_CONFIG) {
            this.#initialConfig = { ...config };
            this.RESET_INITIAL_CONFIG = false;
        }

        const merged = { ...awareTableConfig, ...config };

        merged.columns = config.columns.map((column) => {
            const col = {
                ...awareTableConfig.column,
                ...column,
            };

            if (column.isAttribute) {
                col.disableSorting = true;
                col.cellTemplateRef = column.attrType === 'text' ? this.cellTemplates.attrText : this.cellTemplates.attrSimple;
            }

            if (column.cellTemplate && this.cellTemplates[column.cellTemplate]) {
                col.cellTemplateRef = this.cellTemplates[column.cellTemplate] as TemplateRef<any>;
            }

            const name = this.#translateService.instant(column.name);
            col.name = name;

            return col;
        });
        this.config$.next(merged);
    }

    #initListeners() {
        const unit$ = this.#store.select(UnitState.activeUnit).pipe(
            tap((unit) => {
                this.unit = unit;
            }),
        );

        const sortMerged$ = this.sort$.pipe(
            switchMap((sort) => {
                if (!sort) {
                    return this.config$.pipe(map((config) => config.sort));
                }

                const column =
                    this.#initialConfig.columns.find((column) => column.prop === sort.prop || column.sortcol === sort.prop) ?? sort;
                return of({ sortcol: column.sortcol, prop: column.prop, dir: sort.dir });
            }),
        );

        const request$ = this.request$.pipe(
            delay(10), // Delay to wait for the storeKey input to be set
        );

        this.result$ = combineLatest([this.config$, request$, this.query$, this.page$, sortMerged$, unit$]).pipe(
            takeWhile(() => this.ALIVE),
            tap(() => (this.IS_LOADING = true)),
            debounceTime(50),
            tap(([_, request, query, page, sort]) => {
                if (this.storeKey) {
                    this.#awareTableService.updateStore(this.storeKey, {
                        page,
                        sort,
                        query,
                        request,
                    });
                }
            }),
            switchMap(([config, request, query, page, sort, unit]) => {
                let hasConditionSearch = false;

                if (query?.length) {
                    if (config.searchConditions?.length) {
                        hasConditionSearch = true;
                        const conditions = [];

                        request = request = request.endpoint(config.model.searchRoute ?? config.model.route).parameter('sort', undefined);

                        for (const condition of this.config.searchConditions) {
                            const con = {
                                ...condition,
                            };

                            if ('value' in con === false) {
                                if (con.operator === 'like') {
                                    con.value = `%${query}%`;
                                } else {
                                    con.value = query;
                                }
                            }

                            conditions.push(con);
                        }

                        request = request.parameters({
                            having_relevance: true,
                            scope: 'unit',
                            conditions: JSON.stringify(conditions),
                        });
                    } else {
                        request = request.endpoint(config.model.searchRoute ?? config.model.route).parameter('query', query);
                    }
                } else {
                    request = request.endpoint(this.#initialRequest.getEndpoint()).parameter('query', null);
                }

                if (!hasConditionSearch) {
                    request = request
                        .sort(sort.sortcol ?? sort.prop, sort.dir)
                        .parameter('having_relevance', null)
                        .parameter('conditions', null);
                }

                request = request.toCollection(config.model).limit(config.limit).page(page).unit(unit.uuid);

                return request.execute();
            }),
            tap((result) => {
                if (result.data.length === 0 && result.page > 1) {
                    this.page$.next(1);
                }
            }),
            tap(() => (this.IS_LOADING = false)),
            startWith({ data: [], page: 1, next: false }),
            shareReplay(1),
        );

        fromEvent(window, 'resize')
            .pipe(
                takeWhile(() => this.ALIVE),
                debounceTime(300),
                tap(() => {
                    this.datatable?.recalculateColumns(this.config$.value.columns);
                }),
            )
            .subscribe();
    }
}
