<div class="flex gap-2">
  <mat-form-field appearance="fill" class="flex-1" [class.w-full]="!includeTime">
    <mat-label>{{ label }}</mat-label>
    <input matInput [matDatepicker]="picker" [min]="min" [max]="max" [formControl]="dateControl" />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  @if (includeTime) {
    <mat-form-field appearance="fill" class="w-[100px]">
      <mat-label>{{ 'PAGE_PAGES.TIME' | translate }}</mat-label>
      <input matInput type="time" [formControl]="timeControl" />
    </mat-form-field>
  }
</div>
