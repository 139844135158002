import { Component, OnInit, Input, inject } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Config } from '@services';
import { UnitService } from '../../pages/units/units.service';
import { SupportDialogComponent } from '../support-dialog';
import { AwareSecurityIsGodDirective, AwareSecurityService } from '@appbolaget/aware-security';
import { arrayFilterAsync } from '@appbolaget/helpers';
import { debounceTime, delay, filter, map, share, startWith, switchMap, take, tap } from 'rxjs/operators';
import { Unit } from '@appbolaget/aware-model';
import { Observable, combineLatest, firstValueFrom } from 'rxjs';
import { AwareExtensionService, AwareExtensionState } from '@appbolaget/aware-extension';
import { Store } from '@ngxs/store';
import { UnitState } from 'app/state/unit.state';
import { AwareAuthService } from '@appbolaget/aware-auth';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavItem } from './symbols';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { IsActiveRoutePipe } from '@helpers/pipes';
import { MediaService } from '@modules/media/services/media.service';

@Component({
    selector: 'aw-sidenav',
    templateUrl: 'sidenav.component.html',
    styleUrls: ['./sidenav.scss'],
    providers: [UnitService, IsActiveRoutePipe],
    imports: [
        MatToolbarModule,
        MatListModule,
        MatExpansionModule,
        MatIconModule,
        AwareSecurityIsGodDirective,
        IsActiveRoutePipe,
        TranslateModule,
        RouterModule,
        CommonModule,
    ],
})
export class SidenavComponent implements OnInit {
    items$: Observable<SidenavItem[]>;
    org: Unit;
    unit: Unit;

    opened = true;
    currentOpenItems: Set<SidenavItem> = new Set();

    @Input() nav: MatSidenav;

    #store = inject(Store);
    #awareAuth = inject(AwareAuthService);
    #isActiveRoute = inject(IsActiveRoutePipe);
    #mediaService = inject(MediaService);

    org$: Observable<Unit> = this.#store.select(UnitState.hierarchy).pipe(
        switchMap((units) =>
            this.#awareAuth.clientFiltered$.pipe(
                map((client) => client.units),
                map((clientUnits) => {
                    const hierarchy = [...units];
                    const reversed = [...hierarchy].reverse();
                    return reversed.find((unit) => clientUnits.some((clientUnit) => clientUnit.uuid === unit.uuid));
                }),
            ),
        ),
    );
    constructor(
        public config: Config,
        public router: Router,
        public matDialog: MatDialog,
        public unitService: UnitService,
        private awareSecurityService: AwareSecurityService,
        private awareExtensionService: AwareExtensionService,
    ) {}

    async ngOnInit() {
        this._initListeners();
    }

    getSidenavConfig(): Promise<SidenavItem[]> {
        const self = this;

        return new Promise((resolve, _) => {
            const configNavigation = this.config.get<SidenavItem[]>('unitConfig.panel.sidenav.items');

            if (!configNavigation) {
                resolve(self.config.get<SidenavItem[]>('sidenav.items'));
            } else {
                resolve(configNavigation);
            }
        });
    }

    navigationItemWithMethodClicked(item: SidenavItem) {
        if (this[item.method]) {
            this[item.method]();
        }
    }

    openSupportModal() {
        this.matDialog.open(SupportDialogComponent);
    }

    openMediaDialog() {
        this.#mediaService.openDialog();
    }

    toggleNav() {
        this.nav.toggle();
        this.opened = !this.opened;
    }

    private async _filterItems(items: SidenavItem[]) {
        const disabledFeatures: string[] = this.config.get('unitConfig.shared.disabledFeatures') ?? [];

        const filterItem = (item: SidenavItem) => {
            if (item.unitType) {
                if (Array.isArray(item.unitType) && !item.unitType.includes(this.unit.type)) {
                    return false;
                } else if (!Array.isArray(item.unitType) && item.unitType !== this.unit.type) {
                    return false;
                }
            }

            if (disabledFeatures.includes(`route:${item.path}`)) {
                return false;
            }

            if (item.children) {
                item.children = item.children.filter(filterItem);

                if (item.children.length === 0) {
                    return false;
                }

                if (item.children.length === 1) {
                    item.path = item.children[0].path;
                    item.queryParams = item.children[0].queryParams;
                    item.required_permissions = item.children[0].required_permissions;
                    item.children = null;
                }
            }

            return true;
        };

        items = items.filter(filterItem);

        return arrayFilterAsync(items, async (item: SidenavItem) => {
            const isGranted = await firstValueFrom(this.awareSecurityService.isGranted(item.required_permissions).pipe(take(1)));
            if (!isGranted) {
                return false;
            }

            if (item.required_extension) {
                const hasExtension = await firstValueFrom(
                    this.awareExtensionService.hasExtensionWithType(item.required_extension).pipe(take(1)),
                );

                if (!hasExtension) {
                    return false;
                }
            }

            if (item.children) {
                item.children = await this._filterItems(item.children);

                if (item.children.length === 0) {
                    return false;
                }

                if (item.children.length === 1) {
                    item.title = item.children[0].title;
                    item.path = item.children[0].path;
                    item.queryParams = item.children[0].queryParams;
                    item.required_permissions = item.children[0].required_permissions;
                    item.children = null;
                }
            }

            return true;
        });
    }

    private _initListeners() {
        const unit$ = this.#store.select(UnitState.activeUnit).pipe(
            filter((unit) => unit instanceof Unit),
            delay(10), // To avoid ExpressionChangedAfterItHasBeenCheckedError
            tap((unit) => {
                this.unit = unit;
                this.org = this.unitService.getHighestParentFromUnit(unit);
            }),
        );

        const extensions$ = this.#store.select(AwareExtensionState.extensions);

        const token$ = this.#awareAuth.token$.pipe(filter((token) => !!token));

        this.items$ = combineLatest([unit$, token$, extensions$]).pipe(
            debounceTime(300),
            switchMap((_) => this.getSidenavConfig()),
            switchMap((config) => this._filterItems(config)),
            share(),
        );

        const routerNavigation$ = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            startWith(null),
        );

        combineLatest([routerNavigation$, this.items$])
            .pipe(tap(([_, items]) => this.#determineCurrentOpenItems(items, this.router.url)))
            .subscribe();
    }

    #determineCurrentOpenItems(items: SidenavItem[], currentUrl: string): void {
        const itemReducer = (acc: SidenavItem[], item: SidenavItem) => {
            if (item.children) {
                const children = item.children.reduce(itemReducer, []);
                if (children.length > 0) {
                    acc.push(item, ...children);
                }
            } else if (item.path) {
                if (this.#isActiveRoute.transform(item.path, item.queryParams, currentUrl)) {
                    acc.push(item);
                }
            }
            return acc;
        };

        const currentOpenItems = items.reduce(itemReducer, []);
        this.currentOpenItems = new Set(currentOpenItems);
    }
}
