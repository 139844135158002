import { Component, ContentChild, Input } from '@angular/core';
import { CardHeaderDirective } from './card-header.directive';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'aw-card',
    templateUrl: 'card.component.html',
    styles: [
        `
            :host {
                display: flex;
                flex-direction: column;
            }
        `,
    ],
    imports: [MatCardModule, NgClass, NgTemplateOutlet]
})
export class CardComponent {
    @Input() title: string;
    @Input() contentClasses: string;
    @Input() cardClasses: string;

    @ContentChild(CardHeaderDirective) cardHeader: CardHeaderDirective;

    constructor() {}
}
