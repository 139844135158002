<mat-card appearance="outlined" class="Card p-0 flex flex-col overflow-hidden" [ngClass]="cardClasses">
  @if (!cardHeader?.template && title) {
    <h3 class="mat-headline-6 p-3 mb-0">{{ title }}</h3>
  }
  @if (cardHeader?.template) {
    <ng-container [ngTemplateOutlet]="cardHeader.template"></ng-container>
  }
  <div [ngClass]="contentClasses" class="flex flex-col">
    <ng-content></ng-content>
  </div>
</mat-card>
