<mat-form-field appearance="fill">
  <mat-label>Alias</mat-label>
  <input matInput [formControl]="aliasControl" />
  @if (IS_LOADING) {
    <mat-spinner matSuffix color="primary" diameter="25"></mat-spinner>
  }
  @if (aliasControl.hasError('taken')) {
    <mat-error>Aliaset är upptaget</mat-error>
  }
</mat-form-field>
