import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DialogService } from '../../services';
import { FinderDialog } from '../dialogs';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';

import { MatCardModule } from '@angular/material/card';
import { CardHeaderDirective } from '../card/card-header.directive';
import { CardComponent } from '../card/card.component';
import { AwareSecurityIsGrantedDirective } from '@appbolaget/aware-security';

@Component({
    selector: 'widget',
    templateUrl: 'widget.html',
    styleUrls: ['./widget.scss'],
    imports: [
        CardComponent,
        CardHeaderDirective,
        MatCardModule,
        AwareSecurityIsGrantedDirective,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        TranslateModule,
    ]
})
export class WidgetComponent implements OnInit {
    selected: any;

    @Input('label') title: string = '';
    @Input() removeButtonTooltip: string = 'COMMON.DELETE';

    @Input() subtitle: string;
    @Input() config: any;

    @Output() onAdd = new EventEmitter<any>();
    @Output() onAddHandler = new EventEmitter<any>();
    @Output() onRemove = new EventEmitter<Array<any>>();

    constructor(
        private mdDialog: MatDialog,
        public dialog: DialogService,
        private translate: TranslateService,
    ) {}

    get canAdd() {
        if (!this.onAdd.observers.length) {
            return false;
        }

        if (!this.config.add) {
            return false;
        }

        // if (!this.auth.hasPermission(this.awConfig.get(`permissions.${this.config.add.required_permissions}`))) {
        //     return false;
        // }

        return true;
    }

    get canRemove() {
        if (!this.onRemove.observers.length) {
            return false;
        }

        if (!this.selected) {
            return false;
        }

        // if (!this.auth.hasPermission(this.awConfig.get(`permissions.${this.config.remove.required_permissions}`))) {
        //     return false;
        // }

        return this.selected.length;
    }

    add() {
        this.translate.get(this.config.add.title).subscribe((title: string) => {
            if (this.canAdd) {
                if (this.onAddHandler.observers.length) {
                    this.onAddHandler.emit(true);
                } else {
                    const dialogData: any = {
                        service: this.config.add.service,
                        title: title,
                        displayProperty: this.config.add.displayProperty ?? 'title',
                        excludes: this.config.add.excludes,
                        filter: this.config.add.filter,
                        options: this.config.add.options,
                        expand: this.config.add.expand,
                    };

                    if (this.config.add.method) {
                        dialogData.method = this.config.add.method;
                    }

                    const dialog = this.mdDialog.open(FinderDialog, {
                        data: dialogData,
                    });

                    dialog.afterClosed().subscribe((result) => {
                        if (result) {
                            this.onAdd.emit(result);
                        }
                    });
                }
            }
        });
    }

    ngOnInit() {}

    remove() {
        if (this.config.remove.confirm) {
            this.dialog.confirm('COMMON.ARE_YOU_SURE', 'COMMON.CONFIRM_CHOICE', true).then((remove) => {
                if (remove) {
                    this.onRemove.emit(this.selected);
                }
            });
        } else {
            this.onRemove.emit(this.selected);
        }
    }
}
