import { NotificationType } from './symbols';

export class GetMyNotifications {
    static readonly type = '[Notification state] Get my notifications';
    constructor() {}
}

export class ClearNotificationsByType {
    static readonly type = '[Notification service] Clear notifications by type';
    constructor(public type: NotificationType) {}
}

export class ClearNotificationsByUnit {
    static readonly type = '[Notification service] Clear notifications by unit id';
    constructor(public unit_id: string) {}
}

export class ClearNotificationsByValue {
    static readonly type = '[Notification service] Clear notifications by value';
    constructor(public value: string) {}
}

export class ClearLocalNotifications {
    static readonly type = '[Notification state] Clear local notifications';
}
