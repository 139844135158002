import { inject, Injectable } from '@angular/core';
import { AwareAuthService } from '@appbolaget/aware-auth';
import { environment } from '@env';
import { MatomoTracker } from 'ngx-matomo-client';
import { tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MatomoService {
    #tracker = inject(MatomoTracker);
    #awareAuth = inject(AwareAuthService);

    constructor() {
        if (environment.matomo.enabled) {
            this.#initListeners();
        }
    }

    #initListeners() {
        this.#awareAuth.client$
            .pipe(
                tap((client) => {
                    if (client) {
                        this.#tracker.setUserId(client.uuid);
                    } else {
                        this.#tracker.resetUserId();
                    }
                }),
            )
            .subscribe();

        // this.#store
        //     .select(UnitState.hierarchy)
        //     .pipe(
        //         tap((hierarchy) => {
        //             const municipalityUnit = hierarchy.find((unit) => unit.type === 'municipality');
        //             if (municipalityUnit) {
        //                 this.#tracker.setCustomDimension(1, municipalityUnit.title);
        //             }
        //         }),
        //     )
        //     .subscribe();
    }
}
