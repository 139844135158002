import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps';
import { ScriptService } from '@services/script.service';
import { map, Observable } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'aw-coord-picker',
    templateUrl: 'coord-picker.component.html',
    styleUrls: ['./coord-picker.component.scss'],
    imports: [GoogleMapsModule, MatButtonModule, MatIconModule, AsyncPipe]
})
export class CoordinatePickerComponent implements OnInit {
    @ViewChild(GoogleMap) mapInstance: GoogleMap;
    mapPosition = {
        lat: 56.6874856,
        lng: 16.2927703,
    };

    @Input() height = '300px';
    @Input() latitude: AbstractControl;
    @Input() longitude: AbstractControl;
    @Input() hasMarker = false;

    mapLoaded$: Observable<boolean>;

    zoom = 7;

    constructor(private scriptService: ScriptService) {}

    ngOnInit() {
        this.mapLoaded$ = this.scriptService.load(this.scriptService.availableScripts.googlemaps).pipe(map(() => true));

        if (this.latitude.value && this.longitude.value) {
            this.mapPosition = {
                lat: this.latitude.value,
                lng: this.longitude.value,
            };

            this.hasMarker = true;
            this.zoom = 13;
        }
    }

    mapReady(e) {
        this.mapInstance = e;
    }

    markerDragged(e: google.maps.MapMouseEvent) {
        this.latitude.setValue(e.latLng.lat());
        this.longitude.setValue(e.latLng.lng());
    }

    toggleMarker() {
        if (!this.hasMarker) {
            this.latitude.setValue(this.mapInstance.getCenter().lat());
            this.longitude.setValue(this.mapInstance.getCenter().lng());
        } else {
            this.latitude.setValue(null);
            this.longitude.setValue(null);
        }

        this.hasMarker = !this.hasMarker;
    }
}
