import { Component, Input, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Config, VersionService } from '@services';
import { UnitService } from '../../pages/units/units.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { LanguageService } from '../../services/language.service';
import { filter, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AwareSecurityIsGodDirective, AwareSecurityIsGrantedDirective, AwareSecurityService } from '@appbolaget/aware-security';
import { HelpMainDialogComponent } from '@modules/help/main-dialog/main-dialog.component';
import { DialogService } from '@viewservices';
import { Select, Store } from '@ngxs/store';
import { UnitState } from 'app/state/unit.state';
import { Unit, Version } from '@appbolaget/aware-model';
import { Client } from '@appbolaget/aware-model';
import { SetActiveUnit } from 'app/state/unit.actions';
import { Attribute } from '@appbolaget/aware-model';
import { ApiEnv, AwareHttpService } from '@appbolaget/aware-http';
import { SetApiEnv } from 'app/state/app.actions';
import { AppState } from 'app/state/app.state';
import { NotificationState } from '@modules/notification/notification.state';
import { Notification } from '@modules/notification/Notification';
import { PostFeedbackDialogComponent } from '@modules/feedback/components/post-feedback-dialog/post-feedback-dialog.component';
import { AwareAuth, AwareAuthService, AwareAuthState } from '@appbolaget/aware-auth';
import { MessagesService } from '@pages/messages/messages.service';
import { MESSAGES_ROUTE } from '@pages/messages/symbols';
import { IMessagesConfig } from '@pages/messages/messages.config';
import { TranslateModule } from '@ngx-translate/core';
import { NgArrayPipesModule, NgStringPipesModule } from 'ngx-pipes';
import { MatDividerModule } from '@angular/material/divider';
import { MatBadgeModule } from '@angular/material/badge';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AsyncPipe } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TopbarUnitTreeComponent } from './topbar-unit-tree.component';
import { MediaService } from '@modules/media/services/media.service';

@Component({
    selector: 'aw-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.scss'],
    providers: [UnitService],
    imports: [
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        AwareSecurityIsGrantedDirective,
        AwareSecurityIsGodDirective,
        MatTooltipModule,
        MatMenuModule,
        MatBadgeModule,
        MatDividerModule,
        AsyncPipe,
        NgArrayPipesModule,
        NgStringPipesModule,
        TranslateModule,
        TopbarUnitTreeComponent,
    ],
})
export class TopbarComponent implements OnInit {
    @Select(AppState.availableEnvs) availableEnvs$: Observable<ApiEnv[]>;
    @Select(UnitState.activeUnit) activeUnit$: Observable<Unit>;
    @AwareAuth((state: AwareAuthState) => state.hijacker) hijacker$: Observable<AwareAuthState>;

    @Select(NotificationState.notificationsByType('systemnotice')) systemNotices$: Observable<Notification[]>;

    @AwareAuth(AwareAuthState.client) client$: Observable<Client>;

    version: Version;
    unit: Unit;
    units: Array<Unit>;
    hasSidebar$: Observable<boolean>;

    messagesConfig$: Observable<IMessagesConfig['topbar']>;

    @Input()
    nav: MatSidenav;

    HAS_LOADED: boolean;
    HAS_NEW_POSTS: number;
    NEWS_BOX_VISIBLE: boolean;
    SIDENAV_CLOSED: boolean;

    #messagesService = inject(MessagesService);
    #mediaService = inject(MediaService);
    messagesRoute = MESSAGES_ROUTE;
    unreadThreads$ = this.#messagesService.unreadThreads$;

    constructor(
        public unitService: UnitService,
        public versionService: VersionService,
        public router: Router,
        public languageService: LanguageService,
        public matDialog: MatDialog,
        public config: Config,
        private awareSecurityService: AwareSecurityService,
        private dialogService: DialogService,
        private store: Store,
        public api: AwareHttpService,
        private awareAuth: AwareAuthService,
    ) {
        this.messagesConfig$ = this.activeUnit$.pipe(map(() => this.config.get<IMessagesConfig['topbar']>('messages.topbar')));
    }

    ngOnInit() {
        this.hasSidebar$ = this.awareSecurityService.isGranted('sidemenu.read');

        this._initListeners();
    }

    setApiEnv(env: ApiEnv) {
        this.store.dispatch(new SetApiEnv(env));
    }

    setApiLanguage(language) {
        this.languageService.setApiLanguage(language.code);
    }

    setLanguage(language: any) {
        this.languageService.changeLanguage(language.code);
    }

    goTo(route: string) {
        this.router.navigateByUrl(route);
    }

    goToSettings() {
        this.router.navigateByUrl('app/me/settings');
    }

    async cancelHijack() {
        await this.awareAuth.cancelHijack();
        this.router.navigate(['/app', 'dashboard']);
    }

    goToUnit(unit: Unit) {
        this.router.navigate([`/app/units/${unit.uuid}`]);
    }

    async messagesButtonClicked(e: MouseEvent) {
        const { MessagesThreadListDialogComponent } = await import(
            '@pages/messages/components/thread-list-dialog/thread-list-dialog.component'
        );
        const messagesTopbarConfig = this.config.get<IMessagesConfig['topbar']>('messages.topbar');
        if (messagesTopbarConfig.dialog) {
            const right = window.innerWidth - e.pageX;

            this.dialogService.open(MessagesThreadListDialogComponent, {
                width: '90%',
                height: '90%',
                maxWidth: '350px',
                maxHeight: '500px',
                position: {
                    top: '65px',
                    right: `${right}px`,
                },
            });
        } else {
            this.router.navigate([MESSAGES_ROUTE]);
        }
    }

    onNewPosts(posts: number) {
        this.HAS_NEW_POSTS = posts;
    }

    onNewsBoxHide() {
        this.NEWS_BOX_VISIBLE = false;
    }

    openMediaDialog() {
        this.#mediaService.openDialog();
    }

    openGdprDialog() {
        // this.matDialog.open(GdprDialogComponent);
    }

    refreshToken() {
        this.awareAuth.refreshToken();
    }

    async setActiveUnit(unit: Unit) {
        this.store.dispatch(new SetActiveUnit(unit));

        const nonRedirectRoutes = ['app/posts', 'app/calendar', 'app/units', 'app/clients', 'app/dashboard'];

        const shouldStay = nonRedirectRoutes.some((route) => this.router.url.includes(route));

        if (!shouldStay) {
            this.router.navigateByUrl(`app/units/${unit.uuid}/information`);
        }
    }

    signout() {
        this.awareAuth.logout();

        // this.router.navigate(['/auth']);
    }

    toggleFeedbackDialog() {
        this.dialogService.open(PostFeedbackDialogComponent, {
            width: '90%',
            maxWidth: '800px',
            minWidth: '400px',
        });
    }

    toggleHelpDialog(e) {
        const right = window.innerWidth - e.pageX;

        this.dialogService.open(HelpMainDialogComponent, {
            height: '500px',
            position: {
                top: '65px',
                right: `${right}px`,
            },
        });
    }

    toggleNewsDialog() {
        this.NEWS_BOX_VISIBLE = !this.NEWS_BOX_VISIBLE;
        this.HAS_NEW_POSTS = 0;
    }

    toggleSystemNoticesDialog(e) {
        const right = window.innerWidth - e.pageX;

        this.dialogService.open(HelpMainDialogComponent, {
            height: '500px',
            position: {
                top: '65px',
                right: `${right}px`,
            },
            data: {
                type: 'systemnotice',
                hideSearch: true,
                showDate: true,
                showNotificationBadge: true,
                title: 'Systemmeddelanden',
                params: {
                    sort: JSON.stringify({ publish_at: 'desc' }),
                },
            },
        });
    }

    toggleSideNav() {
        this.nav.toggle();
    }

    private _getParentArray(unit: Unit, parentArray = []) {
        if (!unit?.parent) {
            return parentArray;
        }

        parentArray.push(new Unit(unit.parent));

        return this._getParentArray(unit.parent, parentArray);
    }

    private _initListeners() {
        this.awareAuth.client$
            .pipe(
                filter((client) => client instanceof Client),
                filter((client) => client.units?.length > 0),
                tap((client) => {
                    this.units = this._parseUnits([...client.units]);
                }),
            )
            .subscribe();
    }

    private _parseUnits(units: Unit[]) {
        const data: any = [];

        for (const unit of units) {
            this.unitService._pushUnits(unit.children, data);

            const parentArray = this._getParentArray(unit).reverse();

            if (!unit.attributes?.length) {
                unit.attributes = [];
            }

            unit.attributes.push(new Attribute({ key: 'parents', json: parentArray }));
            // unit.parents = parentArray;
        }

        /*
            Code for also including children to the units.
         */
        // for (const unit of data) {
        //     const exists = units.filter(obj => obj.uuid === unit.uuid).length > 0;

        //     if (!exists) {
        //         const parentArray = this._getParentArray(unit).reverse();

        //         unit.parents = parentArray;

        //         units.push(unit);
        //     }
        // }

        return units;
    }
}
