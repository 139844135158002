import { Injectable } from '@angular/core';
import moment from 'moment';
import { Post } from '@appbolaget/aware-model';

@Injectable()
export class RepeatDialogService {
    frequencies = [
        {
            value: 'weekday',
            label: 'Veckodagar',
            labelMultiple: 'veckodagar',
        },
        {
            value: 'daily',
            label: 'Varje dag',
            labelSingle: 'dag',
            labelMultiple: 'dagar',
        },
        {
            value: 'weekly',
            label: 'Varje vecka',
            labelSingle: 'vecka',
            labelMultiple: 'veckor',
        },
        {
            value: 'monthly',
            label: 'Varje månad',
            labelSingle: 'månad',
            labelMultiple: 'månader',
        },
        {
            value: 'yearly',
            label: 'Varje år',
            labelSingle: 'år',
            labelMultiple: 'år',
        },
    ];

    weekdays = [
        {
            value: 1,
            label: 'måndag',
        },
        {
            value: 2,
            label: 'tisdag',
        },
        {
            value: 3,
            label: 'onsdag',
        },
        {
            value: 4,
            label: 'torsdag',
        },
        {
            value: 5,
            label: 'fredag',
        },
        {
            value: 6,
            label: 'lördag',
        },
        {
            value: 7,
            label: 'söndag',
        },
    ];

    constructor() {}

    getRepeatLabel(repeater) {
        let value = `${repeater.interval > 1 ? 'var ' + repeater.interval + '' : 'varje'} ${repeater.frequency.labelSingle}`;
        value = Array.isArray(repeater.interval) ? `varje ${repeater.interval.map((d) => ` ${this.getWeekdayFromValue(d).label}`)}` : value;

        let label = `Aktiviteten kommer att upprepas ${value}`;

        if (repeater.end_activity === 'date') {
            const date = moment(repeater.end_date);

            if (date.isValid()) {
                label += ` fram till och med ${date.format('DD MMM, YYYY')}`;
            }
        }

        return label;
    }

    getRepeaterFromEvent(event: Post) {
        const schedule = event.schedule.split(':');

        if (schedule[0].charAt(0) === '@') {
            schedule[0] = schedule[0].slice(1);
        }

        const frequency = this.frequencies.filter((obj) => obj.value === schedule[0])[0];

        let interval: any = parseInt(schedule[1], 10);

        if (frequency.value === 'weekday') {
            interval = schedule[1].split(',').map((i) => parseInt(i));
        }

        const repeater: any = {
            frequency,
            interval,
        };

        const expire = moment(event.break_at);

        if (expire.isValid()) {
            repeater.end_activity = 'date';
            repeater.end_date = expire.format();
        } else {
            repeater.end_activity = 'never';
        }

        repeater.repeatLabel = this.getRepeatLabel(repeater);

        return repeater;
    }

    getWeekdayFromValue(value: number) {
        return this.weekdays.find((v) => v.value === value);
    }
}
