@if (clientsComputed?.length) {
  <ng-container
    [ngTemplateOutlet]="showAsGroup ? multiClientTemplate : singleClientTemplate"
    [ngTemplateOutletContext]="{ $implicit: showAsGroup ? clientsSpliced : clientsComputed[0] }"
  ></ng-container>
}

<ng-template #singleClientTemplate let-client>
  <ng-container
    [ngTemplateOutlet]="client.image ? imageTemplate : noImageTemplate"
    [ngTemplateOutletContext]="{ $implicit: client }"
  ></ng-container>
</ng-template>

<ng-template #multiClientTemplate let-clients>
  <div class="w-full h-full grid grid-cols-2 gap-1 justify-center items-center">
    @for (client of clients; track client) {
      <div class="w-full aspect-square">
        <app-client-avatar [client]="client"></app-client-avatar>
      </div>
    }
  </div>
</ng-template>

<ng-template #imageTemplate let-client>
  <img
    class="w-full h-full object-cover rounded-full relative"
    [ngClass]="dark ? 'border border-white' : null"
    [src]="client.image | secureImg"
    alt="avatar"
  />
</ng-template>

<ng-template #noImageTemplate let-client>
  <div
    class="w-full h-full flex justify-center items-center rounded-full"
    [ngClass]="dark ? 'bg-secondary border border-white' : 'bg-gray-100'"
    style="container-type: inline-size"
  >
    <span class="text-xl" [ngClass]="dark ? 'text-white' : 'text-gray-400'" style="font-size: 45cqw">{{ client.name | initials }}</span>
  </div>
</ng-template>
