import { Injectable, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { Config } from '../config';
import { AwareAuthService } from '@appbolaget/aware-auth';

@Injectable()
export class FileUploaderService {
    baseUrl: string;

    private progress = 0;

    progressObserver: Subject<number> = new Subject();

    private awareAuth = inject(AwareAuthService);
    constructor(public config: Config) {
        this.baseUrl = this.config.get('app.api_url');
    }

    /**
     * Upload files through XMLHttpRequest
     *
     * @param url
     * @param files
     * @returns {Promise<T>}
     */
    upload(url: string, data, files: File[]): Promise<any> {
        url = [this.baseUrl, url].join('/');

        return new Promise((resolve, reject) => {
            const formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();

            for (let i = 0; i < files.length; i++) {
                formData.append('attachments[]', files[i], files[i].name);
            }

            for (const key in data) {
                if (data[key]) {
                    formData.append(key, data[key]);
                }
            }

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        resolve(JSON.parse(xhr.response));
                    } else {
                        reject(xhr.response);
                    }
                }
            };

            xhr.upload.onprogress = (event) => {
                this.progress = Math.round((event.loaded / event.total) * 100);

                this.progressObserver.next(this.progress);
            };

            xhr.open('POST', url, true);

            xhr.setRequestHeader('Module', 'broadcast');
            xhr.setRequestHeader('Token', this.awareAuth.token);
            xhr.setRequestHeader('Unit', this.config.get('app.unit'));

            xhr.send(formData);
        });
    }

    openFileSelector(multiple?: false): Promise<File>;
    openFileSelector(multiple?: true): Promise<File[]>;
    openFileSelector(multiple: boolean = false): Promise<File | File[]> {
        return new Promise((resolve, reject) => {
            const fileSelector = (document.getElementById('aware-file-selector') as HTMLInputElement) ?? this.#createFileSelector(multiple);

            fileSelector.onchange = () => {
                const files = Array.from(fileSelector.files);
                if (multiple) {
                    resolve(files);
                } else {
                    resolve(files[0]);
                }
                fileSelector.remove();
            };

            fileSelector.onabort = () => {
                reject();
                fileSelector.remove();
            };

            fileSelector.click();
        });
    }

    #createFileSelector(multiple: boolean = false): HTMLInputElement {
        const fileSelector = document.createElement('input');
        fileSelector.setAttribute('type', 'file');
        fileSelector.setAttribute('id', 'aware-file-selector');
        if (multiple) {
            fileSelector.setAttribute('multiple', 'multiple');
        }
        fileSelector.style.display = 'none';

        return fileSelector;
    }
}
