<div class="Dialog Help">
  <mat-toolbar class="Dialog__header" color="primary">
    <h2 class="Dialog__title" mat-dialog-title><mat-icon>rate_review</mat-icon> Skicka feedback</h2>
    <span class="flex-1"></span>
    <button mat-icon-button type="button" (click)="dismiss()"><mat-icon>close</mat-icon></button>
  </mat-toolbar>

  <div mat-dialog-content class="Dialog__content">
    <div class="flex flex-col p-3">
      <p class="mb-3">
        Här kan du skicka feedback angående systemet till administratörer. Det kan vara allt från buggrapporter till önskemål om nya
        funktioner.
      </p>

      <form [formGroup]="form" (ngSubmit)="submit()" class="flex flex-col">
        <div class="flex gap-2 items-center">
          <mat-form-field appearance="fill" class="flex-1">
            <mat-label>Titel</mat-label>
            <input matInput formControlName="title" />
          </mat-form-field>

          <mat-form-field appearance="fill" class="flex-1">
            <mat-label>Kategori</mat-label>
            <mat-select formControlName="category">
              <mat-option value="Önskemål">Önskemål</mat-option>
              <mat-option value="Buggrapport">Buggrapport</mat-option>
              <mat-option value="Övrigt">Övrigt</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Feedback</mat-label>
          <textarea matInput cdkTextareaAutosize formControlName="content"></textarea>
        </mat-form-field>

        <h4 class="font-semibold mb-2">Bifoga filer</h4>
        <app-media-bridge formControlName="media" [allowDirectUpload]="false"></app-media-bridge>

        <div class="flex justify-end">
          <button type="submit" mat-raised-button color="primary" [disabled]="form.invalid || IS_LOADING">Skicka</button>
        </div>
      </form>
    </div>
  </div>
</div>
