import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config';

@Injectable()
export class FileDownloaderService {
    baseUrl: string;

    constructor(
        public http: HttpClient,
        public config: Config,
    ) {
        this.baseUrl = this.config.get('app.api_url');
    }

    download(url, filter?: any) {
        url = [this.baseUrl, url].join('/');

        let params: any = {
            export: 'spreadsheet',
        };

        if (filter) {
            params.filter = filter;
        }

        let options: any = {};

        options.params = params;
        options.responseType = 'blob';

        return this.http.get(url, options);
    }
}
