import { Component, Input, OnChanges, Output, EventEmitter, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, mergeMap, takeWhile, tap } from 'rxjs/operators';
import { Model } from '@models';
import { AwareHttpRequest } from '@appbolaget/aware-http';
import { Subject } from 'rxjs';
import { MatFormFieldAppearance, MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { NgClass } from '@angular/common';
import { MatInputModule } from '@angular/material/input';

@Component({
    selector: 'aw-multiselect-2',
    templateUrl: 'multiselect-2.component.html',
    styleUrls: ['./multiselect-2.component.scss'],
    imports: [
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        NgClass,
        MatButtonModule,
        MatIconModule,
        MatProgressBarModule,
        MatOptionModule,
    ]
})
export class AwareMultiSelectComponent implements OnInit, OnDestroy, OnChanges {
    _request: AwareHttpRequest;

    ALIVE: boolean;
    HAS_OPENED: boolean;
    IS_LOADING: boolean;

    @Input() selectPlaceholder: string = 'Sök...';
    @Input() placeholder: string;
    @Input() options = [{ title: 'Vänta...', disabled: true }];
    @Input() disabled = false;
    @Input() display = 'title';
    @Input() valueProperty = 'uuid';
    @Input() control: AbstractControl = new UntypedFormControl();
    @Input() errorMsg: string = 'Field is required';
    @Input() showErrorMsg = false;
    @Input() selectedOptions;
    @Input() multiple = true;
    @Input() label: string;
    @Input() enableSearch = true;

    @Input() labelCount: number = 2;
    @Input() appearance: MatFormFieldAppearance = 'fill';

    @Input() set request(request: AwareHttpRequest) {
        this._request = request;
    }

    get request() {
        return this._request;
    }

    get formControl(): UntypedFormControl {
        return this.control as UntypedFormControl;
    }

    @Output()
    selectionChange: EventEmitter<any> = new EventEmitter();

    @ViewChild('selectElem') selectElem;

    filteredOptions: Array<any> = [];
    selectedValue: Array<any> = [];
    selectAllChecked = false;
    displayString = '';

    refresher$: Subject<any> = new Subject();
    constructor() {}

    ngOnInit() {
        this.ALIVE = true;

        this._initListeners();
    }

    ngOnDestroy() {
        this.ALIVE = false;
    }

    ngOnChanges() {
        if (this.disabled) {
            this.control.disable();
        } else {
            this.control.enable();
        }

        this.filteredOptions = this.options;
        if (this.selectedOptions) {
            this.selectedValue = this.selectedOptions;
        } else if (this.control.value && this.control.value.length) {
            this.selectedValue = this.control.value;
            this.options = this.control.value;
        }
    }

    toggleDropdown() {
        this.selectElem.toggle();
    }

    toggleSelectAll(val) {
        if (val.checked) {
            this.control.setValue(this.options);
        } else {
            this.control.setValue([]);
        }

        this.selectedValue = this.control.value;
        this.selectionChange.emit(this.selectedValue);
    }

    filterItem(value) {
        this.refresher$.next(value);
    }

    hideOption(option) {
        return !(this.filteredOptions.indexOf(option) > -1);
    }

    // Returns plain strings array of filtered values
    getFilteredOptionsValues() {
        const filteredValues = [];
        this.options.forEach((option) => {
            filteredValues.push(option[this.valueProperty]);
        });
        return filteredValues;
    }

    get onDisplayString() {
        this.displayString = '';

        if (this.selectedValue && this.selectedValue.length) {
            let displayOption = [];
            if (this.multiple) {
                // Multi select display
                for (let i = 0; i < this.labelCount; i++) {
                    displayOption[i] = this.selectedValue[i];
                }

                if (displayOption.length) {
                    for (let i = 0; i < displayOption.length; i++) {
                        if (displayOption[i] && displayOption[i][this.display]) {
                            this.displayString += displayOption[i][this.display] + ', ';
                        }
                    }
                    this.displayString = this.displayString.slice(0, -2);
                    if (this.selectedValue.length > 1 && this.selectedValue.length > this.labelCount) {
                        this.displayString += ` (+${this.selectedValue.length - this.labelCount} till)`;
                    }
                }
            } else {
                // Single select display
                displayOption = this.selectedValue;

                if (displayOption.length) {
                    this.displayString = displayOption[0][this.display];
                }
            }
        }

        return this.displayString;
    }

    onSelectionChange(val) {
        this.selectedValue = Array.isArray(val.value) ? val.value : [val.value];

        const filteredValues = this.getFilteredOptionsValues();
        let count = 0;

        if (this.multiple) {
            this.selectedValue.filter((item) => {
                if (filteredValues.includes(item[this.valueProperty])) {
                    count++;
                }
            });
            this.selectAllChecked = count === this.options.length;
        }

        this.selectionChange.emit(this.selectedValue);
    }

    selectOpened(_: boolean) {
        if (!this.HAS_OPENED) {
            this.refresher$.next(null);
            this.HAS_OPENED = true;
        }
    }

    trackByFn(_, item) {
        return item.uuid;
    }

    compareFn(m1: Model, m2: Model): boolean {
        return m1 && m2 ? m1.uuid === m2.uuid : m1 === m2;
    }

    private _initListeners() {
        this.refresher$
            .pipe(
                tap(() => (this.IS_LOADING = true)),
                debounceTime(300),
                takeWhile(() => this.ALIVE),
                mergeMap((query) => this.request.parameter('query', query).execute()),
                tap((result) => {
                    this.IS_LOADING = false;

                    const filteredData = result.data.filter((obj) => !this.control.value.find((obj2) => obj2.uuid === obj.uuid));

                    this.options = [...filteredData, ...this.control.value];
                    this.selectAllChecked = true;

                    for (const option of this.options) {
                        if (!this.selectedValue.find((obj) => obj[this.valueProperty] === option[this.valueProperty])) {
                            this.selectAllChecked = false;
                        }
                    }

                    if (!this.options.length) {
                        this.selectAllChecked = false;
                    }
                }),
            )
            .subscribe();
    }
}
