import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { WidgetComponent } from '../widget/widget.component';
import { takeWhile, tap } from 'rxjs/operators';
import { AwareTable2Component } from '../aw-table-2/aw-table-2.component';
import { AwareTableComponent } from '../aw-table/aw-table.component';

@Component({
    selector: 'widget-list',
    templateUrl: 'widget-list.html',
    imports: [AwareTableComponent, AwareTable2Component]
})
export class WidgetListComponent implements OnInit, OnDestroy {
    ALIVE: boolean;
    showTable: boolean = false;
    _data: any;

    @Input() config: any;
    @Input() parent: WidgetComponent;

    @Output() onClick = new EventEmitter();

    get data() {
        return this._data;
    }

    @Input('data')
    set data(data) {
        this.parent.selected = false;

        this._data = data;
    }

    constructor() {}

    ngOnInit() {
        this.ALIVE = true;

        setTimeout(() => {
            this.showTable = true;
        });

        this.parent.onRemove
            .pipe(
                takeWhile(() => this.ALIVE),
                tap(() => {
                    this.parent.selected = false;
                }),
            )
            .subscribe();
    }

    ngOnDestroy() {
        this.ALIVE = false;
    }

    click(row) {
        if (this.onClick.observers.length > 0) {
            this.onClick.next(row);
        }
    }

    onSelect(selected) {
        if (selected.length) {
            this.parent.selected = selected;
        } else {
            this.parent.selected = false;
        }
    }
}
