<mat-form-field [appearance]="appearance" class="w-full">
  @if (label) {
    <mat-label>{{ label }}</mat-label>
  }
  <mat-select
    #selectElem
    [placeholder]="placeholder"
    [formControl]="formControl"
    [multiple]="multiple"
    [compareWith]="compareFn"
    (selectionChange)="onSelectionChange($event)"
    (openedChange)="selectOpened($event)"
  >
    <div class="box-search flex flex-col">
      @if (enableSearch) {
        <div class="flex">
          @if (multiple) {
            <mat-checkbox
              color="primary"
              class="box-select-all"
              [(ngModel)]="selectAllChecked"
              (change)="toggleSelectAll($event)"
            ></mat-checkbox>
          }
          <input
            #searchInput
            type="text"
            [ngClass]="{ 'pl-1': !multiple }"
            (input)="filterItem(searchInput.value)"
            [placeholder]="selectPlaceholder"
          />
          <div class="box-search-icon" (click)="filterItem(''); searchInput.value = ''">
            <button mat-icon-button class="search-button">
              <mat-icon class="mat-24" aria-label="Search icon">clear</mat-icon>
            </button>
          </div>
        </div>
      }
      <mat-progress-bar mode="indeterminate" color="primary" [hidden]="!IS_LOADING"></mat-progress-bar>
    </div>
    <mat-select-trigger>
      {{ onDisplayString }}
    </mat-select-trigger>
    @for (option of options; track trackByFn($index, option)) {
      <mat-option [disabled]="option.disabled" [value]="option">{{ option[display] }} </mat-option>
    }
  </mat-select>
  @if (showErrorMsg) {
    <mat-hint style="color: red">{{ errorMsg }}</mat-hint>
  }
</mat-form-field>
