<div [formGroup]="form">
  @if (INITTED) {
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2" [formArrayName]="property">
      @for (attr of attributeArray.controls; track attr; let i = $index) {
        <div [formGroupName]="i">
          <ng-container
            [ngTemplateOutlet]="templateTypes[attr.get('config').value.inputType]"
            [ngTemplateOutletContext]="{ attr: attr, index: i }"
          ></ng-container>
        </div>
      }
    </div>
  }
</div>

@if (keywords && INITTED) {
  <div class="flex flex-col" [formGroup]="form">
    <mat-form-field appearance="fill">
      <mat-label>Taggar</mat-label>
      <mat-chip-grid #chipList aria-label="Taggar" [formArrayName]="keywordsProperty" class="Tag-input">
        @for (keyword of keywordArray.controls; track keyword; let i = $index) {
          <mat-chip-row
            [removable]="true"
            (removed)="toggleDeleteCustomAttribute(keywordArray, i)"
            class="Tag-input__tag"
            [class.Tag-input__tag--deleted]="keyword.value.deleted"
          >
            {{ keyword.value.value }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip-row>
        }
        <input
          placeholder="Ny tagg..."
          [matChipInputFor]="chipList"
          [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="addKeyword($event)"
        />
      </mat-chip-grid>
    </mat-form-field>
  </div>
}

@if (customAttributes && INITTED) {
  <div class="flex flex-col">
    @if (customAttributeArray.controls.length) {
      <div class="flex flex-col" [formGroup]="form">
        <h3 class="mat-headline-6">Egna attribut</h3>
        <div [formArrayName]="customAttributesProperty">
          @for (attr of customAttributeArray.controls; track attr; let i = $index) {
            <div class="flex flex-wrap items-center gap-2" [formGroupName]="i" [class.opacity-50]="attr.get('deleted').value">
              <div class="flex-1">
                <mat-form-field appearance="fill" class="w-full">
                  <mat-label>Nyckel</mat-label>
                  <input matInput formControlName="key" />
                </mat-form-field>
              </div>
              <div class="flex-1">
                <mat-form-field appearance="fill" class="w-full">
                  <mat-label>Värde</mat-label>
                  <input matInput formControlName="value" />
                </mat-form-field>
              </div>
              <div class="flex-1">
                <mat-form-field appearance="fill" class="w-full">
                  <mat-label>Typ</mat-label>
                  <mat-select formControlName="type">
                    <mat-option value="simple">Enkel</mat-option>
                    <mat-option value="text">Lång</mat-option>
                    <mat-option value="json">Json</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="flex-none pb-4">
                <button mat-icon-button color="warn" (click)="toggleDeleteCustomAttribute(customAttributeArray, i)" type="button">
                  <mat-icon>{{ attr.get('deleted').value ? 'restore_from_trash' : 'delete' }}</mat-icon>
                </button>
              </div>
            </div>
          }
        </div>
      </div>
    }
    <h3 class="mat-headline-6">Nytt attribut</h3>
    <div class="flex flex-wrap gap-2" [formGroup]="customForm">
      <div class="flex-1">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Nyckel</mat-label>
          <input matInput formControlName="key" />
        </mat-form-field>
      </div>
      <div class="flex-1">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Värde</mat-label>
          <input matInput formControlName="value" />
        </mat-form-field>
      </div>
      <div class="flex-1">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Typ</mat-label>
          <mat-select formControlName="type">
            <mat-option value="simple">Enkel</mat-option>
            <mat-option value="text">Lång</mat-option>
            <mat-option value="json">Json</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex-none">
        <button mat-fab color="primary" type="button" [disabled]="customForm.invalid" (click)="addCustomAttribute(customForm.value)">
          <mat-icon>add_circle_outline</mat-icon>
        </button>
      </div>
    </div>
  </div>
}

<ng-template #colorPickerTemplate let-attr="attr" let-index="index">
  <div [formGroup]="form">
    <div [formArrayName]="property">
      <div [formGroupName]="index">
        <mat-form-field appearance="fill" formGroupName="attribute" class="w-full">
          <mat-label>{{ attr.get('config').value.label }}</mat-label>
          <input matInput [formControlName]="attr.get('config').value.attributeType" type="color" />
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #textFieldTemplate let-attr="attr" let-index="index">
  <div [formGroup]="form">
    <div [formArrayName]="property">
      <div [formGroupName]="index">
        <mat-form-field appearance="fill" formGroupName="attribute" class="w-full">
          <mat-label>{{ attr.get('config').value.label }}</mat-label>
          <input
            matInput
            [formControlName]="attr.get('config').value.attributeType"
            [type]="attr.get('config').value.textType ? attr.get('config').value.textType : 'text'"
          />
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #textAreaTemplate let-attr="attr" let-index="index">
  <div [formGroup]="form">
    <div [formArrayName]="property">
      <div [formGroupName]="index">
        <mat-form-field appearance="fill" formGroupName="attribute" class="w-full">
          <mat-label>{{ attr.get('config').value.label }}</mat-label>
          <textarea matInput [formControlName]="attr.get('config').value.attributeType" cdkTextareaAutosize></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #selectTemplate let-attr="attr" let-index="index">
  <div [formGroup]="form">
    <div [formArrayName]="property">
      <div [formGroupName]="index">
        <mat-form-field appearance="fill" formGroupName="attribute" class="w-full">
          <mat-label>{{ attr.get('config').value.label }}</mat-label>
          <mat-select
            [formControlName]="attr.get('config').value.attributeType"
            [multiple]="attr.get('config').value.selectOptions.multiple"
          >
            @for (option of attr.get('config').value.selectOptions.options; track option) {
              <mat-option [value]="option.value">{{ option.label }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #checkboxTemplate let-attr="attr" let-index="index">
  <div [formGroup]="form">
    <div [formArrayName]="property">
      <div [formGroupName]="index">
        <div formGroupName="attribute" style="margin-top: 15px">
          <!-- <mat-form-field appearance="fill" formGroupName="attribute" class="w-100"> -->
          <!-- <mat-label>{{ attr.get('config').value.label }}</mat-label> -->
          <mat-checkbox [formControlName]="attr.get('config').value.attributeType" color="primary">
            {{ attr.get('config').value.label }}
          </mat-checkbox>
          <!-- </mat-form-field> -->
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #datepickerTemplate let-attr="attr" let-index="index">
  <div [formGroup]="form">
    <div [formArrayName]="property">
      <div [formGroupName]="index">
        <mat-form-field appearance="fill" formGroupName="attribute" class="w-100">
          <mat-label>{{ attr.get('config').value.label }}</mat-label>

          <input matInput [formControlName]="attr.get('config').value.attributeType" [matDatepicker]="picker" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-template>
