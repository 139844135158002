import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import {
    AbstractControl,
    AsyncValidator,
    ControlValueAccessor,
    FormControl,
    NG_ASYNC_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { AwareHttpService } from '@appbolaget/aware-http';
import { Store } from '@ngxs/store';
import { UnitState } from 'app/state/unit.state';
import { Observable, catchError, delay, map, of, skip, switchMap, take, takeWhile, tap } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'app-alias-field',
    templateUrl: 'alias-field.component.html',
    styles: [
        `
            :host {
                display: flex;
                flex-direction: column;
            }
        `,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: AliasFieldComponent,
        },
        {
            provide: NG_ASYNC_VALIDATORS,
            multi: true,
            useExisting: AliasFieldComponent,
        },
    ],
    imports: [MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, MatProgressSpinnerModule]
})
export class AliasFieldComponent implements ControlValueAccessor, AsyncValidator, OnInit, OnDestroy {
    ALIVE: boolean;
    IS_LOADING: boolean;
    HAS_VALIDATED_ONCE: boolean;
    aliasControl = new FormControl();

    // If supplied, check with this prefix instead of active unit
    @Input() unitId?: string;

    @Input() entity: string = 'posts';
    @Input() module = 'cms';
    @Input() entityId: string;

    get unitUuid(): string {
        return this.unitId ?? this.store.selectSnapshot(UnitState.activeUnit).uuid;
    }

    private store = inject(Store);
    constructor(private api: AwareHttpService) {}

    onChange = (_) => {};

    onTouched = () => {};

    touched = false;

    disabled = false;

    ngOnInit(): void {
        this.ALIVE = true;
        this._initListeners();
    }

    ngOnDestroy(): void {
        this.ALIVE = false;
    }

    writeValue(alias: string): void {
        if (alias) {
            const split = alias.split(':');
            this.aliasControl.setValue(split[1] ?? split[0]);
        } else {
            this.aliasControl.setValue('');
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;

        if (isDisabled) {
            this.aliasControl.disable();
        } else {
            this.aliasControl.enable();
        }
    }

    validate(control: AbstractControl): Promise<ValidationErrors> | Observable<ValidationErrors> {
        if (!control.value || control.value?.length <= 0 || !this.HAS_VALIDATED_ONCE) {
            this.HAS_VALIDATED_ONCE = true;
            return of(null);
        }

        return of(control.value).pipe(
            takeWhile(() => this.ALIVE),
            tap(() => {
                this.IS_LOADING = true;
            }),
            delay(500),
            switchMap((value) => this._validate(value)),
            tap((errors) => {
                this.aliasControl.setErrors(errors);
            }),
        );
    }

    private _validate(value: string): Observable<ValidationErrors> {
        return this.api
            .show(this.entity, value)
            .module(this.module)
            .execute()
            .pipe(
                take(1),
                catchError((_) => of(null)),
                map((result) => {
                    this.IS_LOADING = false;

                    if (result && result.data.uuid !== this.entityId) {
                        return {
                            taken: true,
                        };
                    }

                    return null;
                }),
            );
    }

    private _initListeners() {
        this.aliasControl.valueChanges
            .pipe(
                skip(1),
                takeWhile(() => this.ALIVE),
                tap((value) => {
                    this.onChange(`${this.unitUuid}:${value}`);
                    this.onTouched();
                }),
            )
            .subscribe();
    }
}
