import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-paginator-dumb',
    templateUrl: 'paginator-dumb.component.html',
    imports: [MatIconModule, MatButtonModule]
})
export class PaginatorDumbComponent {
    @Input({ required: true }) collection: { page: number; next: boolean };

    @Output() onGoToPage = new EventEmitter<number>();
}
