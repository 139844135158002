import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'aw-search-bar',
    templateUrl: 'searchbar.component.html',
    styleUrls: ['./searchbar.scss'],
    imports: [MatButtonModule, MatIconModule, FormsModule]
})
export class SearchbarComponent {
    @Input() nav;
    @Input() placeholder = 'Sök';
    @Input() style = 'classic';

    @Output() searchChange = new EventEmitter<string>();

    @Input()
    searchValue = '';

    constructor() {}

    clear() {
        this.searchValue = '';

        this.onSearch('');
    }

    onSearch(_) {
        this.searchChange.emit(this.searchValue);
    }
}
