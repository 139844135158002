import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger, MatMenuModule } from '@angular/material/menu';
import { BatchRequest } from '@helpers/interfaces';
import { AwareHttpService } from '@appbolaget/aware-http';
import { isValidUUID } from '@appbolaget/helpers';
import { Client } from '@appbolaget/aware-model';
import { MatIconModule } from '@angular/material/icon';

import { MatListModule } from '@angular/material/list';

@Component({
    selector: 'contact-list',
    templateUrl: 'contact-list.html',
    styleUrls: ['./contact-list.scss'],
    imports: [MatListModule, MatMenuModule, MatIconModule]
})
export class ContactListComponent implements OnInit {
    activeClient: Client;
    @Input() clients: Array<Client>;

    @Input() uuids: Array<string>;

    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

    constructor(public api: AwareHttpService) {}

    ngOnInit() {
        if (this.uuids && this.uuids.length) {
            const uuids = this.uuids.filter((uuid) => isValidUUID(uuid));

            if (uuids.length > 0) {
                setTimeout(() => {
                    this.getContactsByUuid(this.uuids);
                });
            }
        }
    }

    call() {
        window.open(`tel:${this.activeClient.getCommunicationWithType('phone').value}`);
    }

    email() {
        window.open(`mailto:${this.activeClient.getCommunicationWithType('email').value}`);
    }

    expandContact(client: Client) {
        this.activeClient = client;
        //this.trigger.openMenu($event);
    }

    getContactsByUuid(uuids: Array<string>) {
        let batch: BatchRequest = { requests: [] };

        for (let uuid of uuids) {
            batch.requests.push({
                name: 'clients',
                route: `clients/${uuid}`,
                parameters: {
                    expand: 'meta',
                },
            });
        }

        this.api
            .batch(batch)
            .execute()
            .subscribe((result) => {
                this.clients = this.handleResult(result['data']['clients']);
            });
    }

    handleResult(result) {
        let clients = [];

        for (let obj of result) {
            let client = new Client(obj.data);

            clients.push(client);
        }

        return clients;
    }
}
