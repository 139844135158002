import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { BaseDialogComponent } from '@helpers/base';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';

@Component({
    selector: 'aw-html-editor',
    templateUrl: 'html-editor.component.html',
    imports: [
        MatToolbarModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        TranslateModule,
    ]
})
export class AwareHtmlEditorComponent extends BaseDialogComponent {
    html: string;

    constructor(
        public dialogRef: MatDialogRef<AwareHtmlEditorComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        super();

        this.html = this.data.html;
    }

    ngOnInit() {}

    save() {
        this.dismiss(this.html);
    }
}
