import { Component, Inject, inject, viewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { AwareHttpService } from '@appbolaget/aware-http';
import { Attribute, Post } from '@appbolaget/aware-model';
import { BaseDialogComponent } from '@helpers/base';
import { Store } from '@ngxs/store';
import { SnackbarService } from '@viewservices/snackbar.service';
import { UnitState } from 'app/state/unit.state';
import moment from 'moment';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MediaBridgeComponent } from '@modules/media/ui/bridge/media-bridge.component';

// Batch 2 response from post-feedback-dialog.component.ts
// {
//     "message": "Batch successful",
//     "status": 200,
//     "data": {
//         "post": {
//             "message": "Successfully created item",
//             "status": 201,
//             "data": {
//                 "updated_at": "2024-04-05 12:43:01",
//                 "title": "asdf",
//                 "content": "asdfaf",
//                 "type": "org-feedback",
//                 "client_id": 2,
//                 "unit_id": 4963,
//                 "publish_at": "2024-04-05 12:43:01",
//                 "uuid": "4b5e0391-1130-4f68-9e49-68a3bed58b81",
//                 "created_at": "2024-04-05 12:43:01",
//                 "content_encrypted": "0",
//                 "title_encrypted": "0",
//                 "id": "177241"
//             },
//             "attributes": {
//                 "message": "Successfully created 2, updated 0 and deleted 0 item(s)",
//                 "status": 200,
//                 "data": [
//                     {
//                         "resource": "Aware\\Modules\\Cms\\Models\\Post",
//                         "updated_at": "2024-04-05 12:43:01",
//                         "key": "category",
//                         "simple": "\u00d6nskem\u00e5l",
//                         "resource_id": 177241,
//                         "uuid": "147b882b-4eca-4b4a-8586-6874f988fe47",
//                         "created_at": "2024-04-05 12:43:01",
//                         "id": "276572"
//                     },
//                     {
//                         "resource": "Aware\\Modules\\Cms\\Models\\Post",
//                         "updated_at": "2024-04-05 12:43:01",
//                         "key": "clientInfo",
//                         "json": "{\"timeOpened\":\"2024-04-05 12:43\",\"timezone\":-2,\"pageon\":\"\/app\/units\",\"referrer\":\"http:\/\/localhost:4200\/app\/units\",\"previousSites\":7,\"userAgent\":\"Mozilla\/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/123.0.0.0 Safari\/537.36\",\"browserLanguage\":\"sv-SE\",\"browserOnline\":true,\"browserPlatform\":\"MacIntel\",\"dataCookiesEnabled\":true,\"dataCookies1\":\"\",\"sizeScreenW\":1728,\"sizeScreenH\":1117,\"sizeInW\":1473,\"sizeInH\":568,\"sizeAvailW\":1728,\"sizeAvailH\":994,\"scrColorDepth\":30,\"scrPixelDepth\":30,\"activeUnit\":\"Gustav Reddit\"}",
//                         "resource_id": 177241,
//                         "uuid": "f6c0741c-e40c-4b3a-8862-bd3aa21a78c6",
//                         "created_at": "2024-04-05 12:43:01",
//                         "id": "276573"
//                     }
//                 ]
//             },
//             "files": {
//                 "message": "Successfully attached objects",
//                 "status": 200,
//                 "data": [
//                     {
//                         "anchor_id": "4b5e0391-1130-4f68-9e49-68a3bed58b81",
//                         "foreign_id": "4bc5599b-15b9-48ce-91d3-322f33a9ceae",
//                         "pivot": {
//                             "updated_at": "2024-04-05 12:43:01",
//                             "position": 0,
//                             "post_id": 177241,
//                             "file_id": 32548,
//                             "created_at": "2024-04-05 12:43:01",
//                             "id": "33427"
//                         }
//                     }
//                 ]
//             }
//         }
//     }
// }

// const testOutput = {
//     message: 'Batch successful',
//     status: 200,
//     data: {
//         post: {
//             message: 'Successfully created item',
//             status: 201,
//             data: {
//                 updated_at: '2024-04-05 12:43:01',
//                 title: 'asdf',
//                 content: 'asdfaf',
//                 type: 'org-feedback',
//                 client_id: 2,
//                 unit_id: 4963,
//                 publish_at: '2024-04-05 12:43:01',
//                 uuid: '4b5e0391-1130-4f68-9e49-68a3bed58b81',
//                 created_at: '2024-04-05 12:43:01',
//                 content_encrypted: '0',
//                 title_encrypted: '0',
//                 id: '177241',
//             },
//             attributes: {
//                 message: 'Successfully created 2, updated 0 and deleted 0 item(s)',
//                 status: 200,
//                 data: [
//                     {
//                         resource: 'Aware\\Modules\\Cms\\Models\\Post',
//                         updated_at: '2024-04-05 12:43:01',
//                         key: 'category',
//                         simple: 'Önskemål',
//                         resource_id: 177241,
//                         uuid: '147b882b-4eca-4b4a-8586-6874f988fe47',
//                         created_at: '2024-04-05 12:43:01',
//                         id: '276572',
//                     },
//                     {
//                         resource: 'Aware\\Modules\\Cms\\Models\\Post',
//                         updated_at: '2024-04-05 12:43:01',
//                         key: 'clientInfo',
//                         json: '{"timeOpened":"2024-04-05 12:43","timezone":-2,"pageon":"/app/units","referrer":"http://localhost:4200/app/units","previousSites":7,"userAgent":"Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36","browserLanguage":"sv-SE","browserOnline":true,"browserPlatform":"MacIntel","dataCookiesEnabled":true,"dataCookies1":"","sizeScreenW":1728,"sizeScreenH":1117,"sizeInW":1473,"sizeInH":568,"sizeAvailW":1728,"sizeAvailH":994,"scrColorDepth":30,"scrPixelDepth":30,"activeUnit":"Gustav Reddit"}',
//                         resource_id: 177241,
//                         uuid: 'f6c0741c-e40c-4b3a-8862-bd3aa21a78c6',
//                         created_at: '2024-04-05 12:43:01',
//                         id: '276573',
//                     },
//                 ],
//             },
//             files: {
//                 message: 'Successfully attached objects',
//                 status: 200,
//                 data: [
//                     {
//                         anchor_id: '4b5e0391-1130-4f68-9e49-68a3bed58b81',
//                         foreign_id: '4bc5599b-15b9-48ce-91d3-322f33a9ceae',
//                         pivot: {
//                             updated_at: '2024-04-05 12:43:01',
//                             position: 0,
//                             post_id: 177241,
//                             file_id: 32548,
//                             created_at: '2024-04-05 12:43:01',
//                             id: '33427',
//                         },
//                     },
//                 ],
//             },
//         },
//     },
// };

@Component({
    selector: 'post-feedback-dialog',
    templateUrl: 'post-feedback-dialog.component.html',
    imports: [
        MatToolbarModule,
        MatDialogModule,
        MatIconModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatOptionModule,
        TextFieldModule,
        MediaBridgeComponent,
    ]
})
export class PostFeedbackDialogComponent extends BaseDialogComponent {
    ALIVE: boolean;
    IS_LOADING: boolean;

    form: UntypedFormGroup;

    mediaBridgeComponent = viewChild(MediaBridgeComponent);

    private store = inject(Store);
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<PostFeedbackDialogComponent>,
        private api: AwareHttpService,
        private fb: UntypedFormBuilder,
        private snackbar: SnackbarService,
    ) {
        super();
    }

    ngOnInit() {
        this.ALIVE = true;

        this._buildForm();
    }

    ngOnDestroy() {
        this.ALIVE = false;
    }

    submit() {
        const { title, category, content, media } = this.form.value;

        let request = this.api.post('posts', { title, content, type: 'org-feedback' }).toModel(Post);

        let attributesRequest = this.api
            .post(`posts/{{ post.uuid }}/attributes/batch`, [
                {
                    key: 'category',
                    simple: category,
                },
                {
                    key: 'clientInfo',
                    json: JSON.stringify(this._getClientInfo()),
                },
            ])
            .toCollection(Attribute);

        const [attachMediaRequest] = this.mediaBridgeComponent().getAttachAndDetachRequests('{{ post.uuid }}', 'posts', null, []);
        // const [attachMediaRequest] = this.postService.getAttachAndDetachRequests('{{ post.uuid }}', 'media', [], media, null, 'posts');

        this.IS_LOADING = true;

        // const output = parseBatchOutput(testOutput.data, {
        //     post: {
        //         request: request,
        //         attributes: {
        //             request: attributesRequest,
        //         },
        //         files: {
        //             request: attributesRequest,
        //         },
        //     },
        //     yo: {
        //         request: request,
        //     }
        // });

        this.api
            .batch2({
                post: {
                    request: request,
                    attributes: {
                        request: attributesRequest,
                    },
                    files: {
                        request: attachMediaRequest,
                        discard: !media || media.length === 0,
                    },
                },
            })
            .subscribe({
                next: () => {
                    this.snackbar.success('Tack för din feedback! En administratör kommer att kolla på detta inom kort');
                    this.dismiss();
                },
                error: () => {
                    this.snackbar.error('Ett fel uppstod, kunde inte skicka feedback');
                },
                complete: () => {
                    this.IS_LOADING = false;
                },
            });

        // this.api
        //     .batchRequests({ post: request })
        //     .execute()
        //     .pipe(finalize(() => (this.IS_LOADING = false)))
        //     .subscribe(
        //         () => {
        //             this.snackbar.success('Tack för din feedback! En administratör kommer att kolla på detta inom kort');
        //             this.dismiss();
        //         },
        //         () => {
        //             this.snackbar.error('Ett fel uppstod, kunde inte skicka feedback');
        //         },
        //     );
    }

    private _buildForm() {
        this.form = this.fb.group({
            title: [null, Validators.required],
            category: null,
            content: [null, Validators.required],
            media: [],
        });
    }

    private _getClientInfo() {
        return {
            timeOpened: moment().format('YYYY-MM-DD HH:mm'),
            timezone: new Date().getTimezoneOffset() / 60,

            pageon: window.location.pathname,
            referrer: document.referrer,
            previousSites: history.length,

            userAgent: navigator.userAgent,
            browserLanguage: navigator.language,
            browserOnline: navigator.onLine,
            browserPlatform: navigator.platform,
            dataCookiesEnabled: navigator.cookieEnabled,
            dataCookies1: document.cookie,

            sizeScreenW: screen.width,
            sizeScreenH: screen.height,
            sizeInW: innerWidth,
            sizeInH: innerHeight,
            sizeAvailW: screen.availWidth,
            sizeAvailH: screen.availHeight,
            scrColorDepth: screen.colorDepth,
            scrPixelDepth: screen.pixelDepth,

            activeUnit: this.store.selectSnapshot(UnitState.activeUnit).title,
        };
    }
}
