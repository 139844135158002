<div class="Dialog">
  <mat-toolbar color="primary" class="Dialog__header">
    <h2 class="Dialog__title" mat-dialog-title>HTML</h2>
    <span class="flex-1"></span>
    <button mat-icon-button type="button" (click)="dismiss()"><mat-icon>close</mat-icon></button>
  </mat-toolbar>
  <div class="Dialog__content" mat-dialog-content>
    <div class="Dialog__inner">
      <mat-form-field style="width: 100%; height: 400px">
        <textarea matInput [(ngModel)]="html" style="height: 400px"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="Dialog__actions" mat-dialog-actions>
    <span class="flex-1"></span>
    <button type="button" mat-button (click)="dismiss()">{{ 'COMMON.CANCEL' | translate }}</button>
    <button type="button" mat-raised-button color="primary" (click)="save()">{{ 'COMMON.SAVE' | translate }}</button>
  </div>
</div>
