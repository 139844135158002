import { AsyncPipe } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { AwareAuthIdleService, AwareAuthLogoutReason, AwareAuthService } from '@appbolaget/aware-auth';

@Component({
    selector: 'aware-auth-idle-warning',
    templateUrl: 'idle-warning.component.html',
    imports: [MatDialogModule, AsyncPipe, MatButtonModule]
})
export class AwareAuthIdleWarningComponent implements OnInit {
    #awareAuth = inject(AwareAuthService);
    #awareIdleService = inject(AwareAuthIdleService);

    durationRemaining$ = this.#awareIdleService.timeoutTimerDuration$();
    constructor(public dialogRef: MatDialogRef<AwareAuthIdleWarningComponent>) {}

    ngOnInit() {}

    stayLoggedIn() {
        this.#awareIdleService.resetTimer();
        this.dialogRef.close();
    }

    logout() {
        this.#awareIdleService.resetTimer();
        this.#awareAuth.logout({
            reason: AwareAuthLogoutReason.manual,
        });
        this.dialogRef.close();
    }
}
