import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
    FormsModule,
    ReactiveFormsModule,
    AbstractControl,
} from '@angular/forms';
import { MatMenuTrigger, MatMenuModule } from '@angular/material/menu';
import { Reminder } from 'app/config';
import { TranslateModule } from '@ngx-translate/core';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { MatListModule } from '@angular/material/list';
import { TranslateSelectorPipe } from '@helpers/pipes';

@Component({
    selector: 'aw-reminders',
    templateUrl: 'reminders.component.html',
    styleUrls: ['./reminders.component.scss'],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatListModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        MatOptionModule,
        TranslateModule,
        TranslateSelectorPipe,
    ]
})
export class RemindersComponent implements OnInit {
    @Input() form: UntypedFormGroup;
    @Input() config: Reminder;
    @Input() remindersKey = 'reminders';
    @ViewChild(MatMenuTrigger) popoverTrigger: MatMenuTrigger;
    newReminderForm: UntypedFormGroup;

    frequencies = [
        {
            value: 'minutes',
            label: 'minuter',
        },
        {
            value: 'hours',
            label: 'timmar',
        },
        {
            value: 'days',
            label: 'dagar',
        },
        {
            value: 'weeks',
            label: 'veckor',
        },
        {
            value: 'months',
            label: 'månader',
        },
    ];

    channels = [
        {
            value: 'post',
            label: 'Kopplade användare',
        },
        // {
        //     value: 'unit',
        //     label: 'Alla på enheten',
        // },
    ];

    get reminders(): UntypedFormArray {
        return this.form.get(this.remindersKey) as UntypedFormArray;
    }

    get reminderControls(): AbstractControl[] {
        const dateField = this.config.dateField ?? 'start_at';
        const reminders = [];

        for (const control of this.reminders.controls) {
            if (!control.value.meta?.field && dateField === 'start_at') {
                reminders.push(control);
            } else if (control.value.meta?.field === dateField) {
                reminders.push(control);
            }
        }

        return reminders;
    }

    constructor(private fb: UntypedFormBuilder) {}

    ngOnInit() {
        this._buildForm();
    }

    addReminder() {
        this.reminders.push(this.newReminderForm);

        this.popoverTrigger.closeMenu();
        this._buildForm();
    }

    removeReminder(reminder: AbstractControl) {
        const index = this.reminders.controls.indexOf(reminder);

        if (reminder.get('uuid').value) {
            reminder.get('toDelete').setValue(!reminder.get('toDelete').value);
        } else {
            this.reminders.removeAt(index);
        }
    }

    private _buildForm() {
        if (!this.form.get(this.remindersKey)) {
            this.form.addControl(this.remindersKey, new UntypedFormArray([]));
        }

        this.newReminderForm = this.fb.group({
            uuid: null,
            toDelete: false,
            date: null,
            amount: [10, Validators.required],
            frequency: 'minutes',
            inherit: 'post',
            meta: {
                field: this.config.dateField || 'start_at',
            },
        });
    }
}
