<form class="Dialog" [formGroup]="form" (ngSubmit)="submit()" autocomplete="false" novalidate>
  <mat-toolbar color="primary" class="Dialog__header">
    <h2 class="Dialog__title" mat-dialog-title><mat-icon>feedback</mat-icon> Support & feedback</h2>
    <span class="flex-1"></span>
    <button mat-icon-button type="button" (click)="dismiss()"><mat-icon>close</mat-icon></button>
  </mat-toolbar>
  <div class="Dialog__content" mat-dialog-content>
    <div class="Dialog__form">
      <p class="Dialog__text">Har du frågor eller feedback om systemet är du välkommen att framföra det här.</p>

      <mat-form-field hintLabel="Max 250 tecken">
        <textarea #focusInput matInput placeholder="Meddelande" #input formControlName="message" maxlength="250"></textarea>
        <mat-hint align="end">{{input.value?.length || 0}}/250</mat-hint>
      </mat-form-field>
    </div>
  </div>
  <div class="Dialog__footer" mat-dialog-actions>
    <button type="button" mat-button (click)="dismiss()">Avbryt</button>
    <button type="submit" mat-raised-button color="primary" [disabled]="form.invalid || IS_LOADING">Skicka</button>
  </div>
</form>
