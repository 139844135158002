<mat-toolbar color="primary" class="shadow-none !bg-transparent">
  <div class="Sidenav__top">
    <img class="Sidenav__logo" src="/assets/images/ab_logo--color.png" />
    <div class="flex flex-col overflow-hidden">
      <h3 class="overflow-hidden overflow-ellipsis">{{ (org$ | async)?.title }}</h3>
      <h4 class="overflow-hidden overflow-ellipsis">{{ unit?.title }}</h4>
    </div>
  </div>
</mat-toolbar>
<mat-nav-list>
  <div *awIsGod>
    <a mat-list-item routerLink="/app/olympus" [routerLinkActive]="['active']" class="!pl-4">
      <mat-icon matListItemIcon class="default">landscape</mat-icon>
      <a matListItemTitle translate>Olympus</a>
    </a>
  </div>

  @for (item of items$ | async; track item) {
    <ng-container
      [ngTemplateOutlet]="item.children ? itemWithChildren : itemWithoutChildren"
      [ngTemplateOutletContext]="{ $implicit: item, level: 1 }"
    ></ng-container>
  }
</mat-nav-list>

<div class="flex-1"></div>
<div class="flex items-center justify-between">
  <a class="author" href="https://appbolaget.se" target="_blank"><img src="assets/images/ab_logo--white.png" /> Appbolaget Sverige AB</a>
  <!-- <button mat-icon-button matTooltip="Support" (click)="openSupportModal()">
  <mat-icon>feedback</mat-icon>
</button> -->
</div>

<ng-template #itemWithChildren let-item let-isChild="isChild" let-level="level">
  <mat-expansion-panel
    [class.mat-elevation-z0]="true"
    [expanded]="currentOpenItems.has(item)"
    [class.is-child]="isChild"
    [class.has-current-route]="currentOpenItems.has(item)"
  >
    <mat-expansion-panel-header>
      <div class="flex items-center gap-2" [style.paddingLeft]="level ? level * 16 + 'px' : '16px'">
        @if (item.icon) {
          <mat-icon matListItemIcon class="default mr-2">{{ item.icon }}</mat-icon>
        }
        <span class="select-none font-normal">{{ item.title | translate }}</span>
      </div>
    </mat-expansion-panel-header>
    <mat-nav-list class="py-0">
      @for (item of item.children; track item) {
        <ng-container
          [ngTemplateOutlet]="item.children ? itemWithChildren : itemWithoutChildren"
          [ngTemplateOutletContext]="{ $implicit: item, isChild: true, level: level + 1 }"
        ></ng-container>
      }
    </mat-nav-list>
  </mat-expansion-panel>
</ng-template>

<ng-template #itemWithoutChildren let-item let-isChild="isChild" let-level="level">
  @if (item.path) {
    <a
      mat-list-item
      routerLink="/app/{{ item.path }}"
      [queryParams]="item.queryParams"
      class="mat-mdc-list-item-interactive"
      [class.is-child]="isChild"
      [style.paddingLeft]="level * 16 + 'px'"
      [activated]="item.path | isActiveRoute: item.queryParams : router.url"
    >
      @if (item.icon) {
        <mat-icon matListItemIcon class="default">{{ item.icon }}</mat-icon>
      }
      <a matListItemTitle translate>{{ item.title }}</a>
      @if (item.badge) {
        <div class="nav-badge">{{ item.badge }}</div>
      }
    </a>
  }

  @if (item.method) {
    <mat-list-item
      (click)="navigationItemWithMethodClicked(item)"
      [class.is-child]="isChild"
      [style.paddingLeft]="level * 16 + 'px'"
      class="mat-mdc-list-item-interactive"
    >
      @if (item.icon) {
        <mat-icon matListItemIcon class="default">{{ item.icon }}</mat-icon>
      }
      <a matListItemTitle translate>{{ item.title }}</a>
    </mat-list-item>
  }
</ng-template>
