import { FormArray, FormControl } from '@angular/forms';
import { IAwareCollection } from '@appbolaget/aware-http';
import { File as AwareFile } from '@appbolaget/aware-model';
export const MESSAGES_ROUTE = ['/app', 'messages'];

export const KEYCODES = {
    Escape: 'Escape',
    ArrowUp: 'ArrowUp',
} as const;

export const THREAD_ACTIONS = {
    CreateNew: 'CreateNew',
    AddToExisting: 'AddToExisting',
    OpenThread: 'OpenThread',
    NewThreadCreated: 'NewThreadCreated',
    ChangedThreadParticipants: 'ChangedThreadParticipants',
    UpdatedThreadTitle: 'UpdatedThreadTitle',
    MarkThreadAsSeen: 'MarkThreadAsSeen',
    NewMessageSent: 'NewMessageSent',
    NewMessagesRecieved: 'NewMessagesRecieved',
    LeftThread: 'LeftThread',
} as const;
export type ThreadAction = (typeof THREAD_ACTIONS)[keyof typeof THREAD_ACTIONS];

export interface MessageFormGroup {
    message: FormControl<string>;
    files: FormArray<FormControl<AwareFile>>;
    localFiles: FormArray<FormControl<File>>;
    editingMessageId?: FormControl<string>;
}

export function isAwareCollection<T>(value: any): value is IAwareCollection<T> {
    return 'data' in value && 'page' in value && 'next' in value;
}
