import { Pipe, PipeTransform, inject } from '@angular/core';
import { AwareAuthService } from '@appbolaget/aware-auth';
import { Client } from '@appbolaget/aware-model';

@Pipe({
    name: 'threadTitle',
    standalone: true,
})
export class ThreadTitlePipe implements PipeTransform {
    #awareAuth = inject(AwareAuthService);

    transform(clients: Client[], title: string, forceClients: boolean = false): string {
        if (title && title !== 'Ny konversation' && !forceClients) {
            return title;
        }

        const me = this.#awareAuth.client;
        const other = clients.filter((client) => client.uuid !== me.uuid);

        if (other.length === 0) {
            return 'Okänd användare';
        }

        return other.map((client) => client.name).join(', ');
    }
}
