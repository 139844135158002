import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AwareHttpRequest, IAwareCollection } from '@appbolaget/aware-http';
import { Observable } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { PaginatorDumbComponent } from './paginator-dumb.component';

@Component({
    selector: 'aw-collection-paginator',
    template: '@if (collection) {<app-paginator-dumb [collection]="collection" (onGoToPage)="goTo($event)"></app-paginator-dumb>}',
    imports: [MatButtonModule, MatIconModule, PaginatorDumbComponent]
})
export class AwareCollectionPaginatorComponent {
    @Input({ required: true }) request: AwareHttpRequest;
    @Input({ required: true }) collection: IAwareCollection<any>;
    @Input() observable: Observable<any>;

    @Output() change = new EventEmitter<AwareHttpRequest>();

    constructor() {}

    goTo(page: number) {
        this.request = this.request.page(page);

        this.change.emit(this.request);
    }
}
