import { Component, EventEmitter, Output, inject } from '@angular/core';
import { AwareAuthService } from '@appbolaget/aware-auth';
import { Unit } from '@appbolaget/aware-model';
import { AwareSecurityIsGodDirective, AwareSecurityService } from '@appbolaget/aware-security';
import { Select, Store } from '@ngxs/store';
import { UnitState } from 'app/state/unit.state';
import { BehaviorSubject, Observable, combineLatest, delay, map, switchMap } from 'rxjs';
import { TopBarUnitTreeDirective } from './topbar-unit-tree.directive';
import { AsyncPipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { NgArrayPipesModule } from 'ngx-pipes';
import { MatIconModule } from '@angular/material/icon';
import { ShowUnitSearch } from 'app/state/app.actions';

@Component({
    selector: 'app-topbar-unit-tree',
    templateUrl: 'topbar-unit-tree.component.html',
    // hostDirectives: [TopBarUnitTreeDirective], // Doesnt work because directive doesnt have access to ContentChildren
    imports: [
        TopBarUnitTreeDirective,
        AsyncPipe,
        RouterLink,
        MatButtonModule,
        MatMenuModule,
        NgArrayPipesModule,
        MatIconModule,
        AwareSecurityIsGodDirective,
    ]
})
export class TopbarUnitTreeComponent {
    #awareSecurityService = inject(AwareSecurityService);
    #awareAuth = inject(AwareAuthService);
    #store = inject(Store);

    @Select(UnitState.activeUnit) activeUnit$: Observable<Unit>;
    @Select(UnitState.hierarchy) unitHierarchy$: Observable<Unit[]>;

    @Output() onSelectUnit = new EventEmitter<Unit>();

    smartHierarchy$: Observable<Unit[]>;
    hiddenUnitUuids$ = new BehaviorSubject<string[]>([]);
    hiddenUnits$ = this.hiddenUnitUuids$.pipe(
        delay(1),
        switchMap((uuids) => this.unitHierarchy$.pipe(map((units) => units.filter((unit) => uuids.includes(unit.uuid))))),
    );

    constructor() {
        this.smartHierarchy$ = this.unitHierarchy$.pipe(
            switchMap((units) =>
                combineLatest([
                    this.#awareSecurityService.isGod(),
                    this.#awareAuth.clientFiltered$.pipe(map((client) => client.units)),
                ]).pipe(
                    map(([isGod, clientUnits]) => {
                        const hierarchy = [...units];
                        hierarchy.shift();
                        const reversed = [...hierarchy].reverse();
                        let foundFirstUnit = false;
                        return reversed.reduce((acc, unit) => {
                            if (isGod || foundFirstUnit || clientUnits.some((clientUnit) => clientUnit.uuid === unit.uuid)) {
                                acc.push(unit);
                                foundFirstUnit = true;
                            }

                            return acc;
                        }, []);
                    }),
                ),
            ),
        );
    }

    showUnitSearch() {
        this.#store.dispatch(new ShowUnitSearch());
    }
}
