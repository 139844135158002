<div class="Table" [class.Table--search]="DID_SEARCH">
  @if (DID_SEARCH) {
    <div class="Table__searchresults">
      Din sökning efter&nbsp;<strong>{{ filter.search }}</strong
      >&nbsp;gav&nbsp;
      @if (rows.length >= limit) {
        <span>fler än &nbsp;</span>
      }
      <strong>{{ rows.length }}</strong
      >&nbsp;resultat
    </div>
  }
  <ngx-datatable
    class="material fullscreen Table__table"
    columnMode="flex"
    [headerHeight]="40"
    [footerHeight]="40"
    [rowHeight]="40"
    [class.Table__table--viewonly]="!IS_CLICKABLE"
    [externalSorting]="externalSorting"
    [loadingIndicator]="IS_LOADING"
    [reorderable]="false"
    [messages]="messages"
    [rows]="rows"
    [columns]="columns"
    [count]="rows.length"
    [limit]="conf.limit"
    [rowClass]="getRowClass"
    [selected]="selected"
    [selectionType]="selectionTypes.checkbox"
    (sort)="onSort($event)"
    (select)="onRowSelect($event)"
    (activate)="onActivate($event)"
  >
    @if (config.selectable) {
      <ngx-datatable-column
        [width]="30"
        [sortable]="false"
        [canAutoResize]="false"
        [draggable]="false"
        [resizeable]="false"
        [headerCheckboxable]="true"
        [checkboxable]="true"
      >
      </ngx-datatable-column>
    }

    @for (column of columns; track column) {
      <ngx-datatable-column
        [name]="column.name | translate"
        [prop]="column.prop"
        [sortable]="column.sortable ? column.sortable : true"
        [flexGrow]="column.flexGrow ? column.flexGrow : 1"
        [width]="column.width ? column.width : null"
        [pipe]="column.pipe ? column.pipe : null"
        [canAutoResize]="false"
        [draggable]="false"
        [resizeable]="false"
      >
      </ngx-datatable-column>
    }

    <ngx-datatable-footer>
      <ng-template ngx-datatable-footer-template let-rowCount="rowCount">
        <div class="Table__footer flex-1 flex items-center justify-between">
          <div>{{ 'PAGE.PAGE' | translate }} {{ page }}: {{ rowCount }} {{ 'PAGE.RESULTS' | translate }}</div>
          <div>
            <button mat-icon-button (click)="goToPage(page - 1)" [disabled]="page === 1 || IS_LOADING">
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <button mat-icon-button (click)="goToPage(page + 1)" [disabled]="rowCount < limit || IS_LOADING">
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
          </div>
        </div>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>
</div>
