import { AwareModel, Integer } from '@appbolaget/aware-model';

export class Notification extends AwareModel {
    static endpoint = 'notifications';
    static model = 'Notification';

    static fields = ['alias', 'client_id', 'created_at', 'id', 'publish_at', 'type', 'unit_id', 'updated_at', 'uuid', 'value'];

    @Integer()
    client_id: number;

    @Integer()
    unit_id: number;

    alias: string;
    type: string;
    value: string;

    constructor(data?: any) {
        super(data, Notification);
    }
}
