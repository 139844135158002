import { Pipe, PipeTransform, inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'trustHtml',
    standalone: true,
})
export class TrustHtmlPipe implements PipeTransform {
    #domSanitizer = inject(DomSanitizer);
    transform(value: string): SafeHtml {
        return this.#domSanitizer.bypassSecurityTrustHtml(value);
    }
}
