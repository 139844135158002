<div class="Dialog">
  <mat-toolbar class="Dialog__header" color="primary">
    <h2 mat-dialog-title class="Dialog__title"><mat-icon>help_outline</mat-icon> {{ post?.title }}</h2>
    <span class="flex-1"></span>
    <button mat-icon-button type="button" (click)="dismiss()"><mat-icon>close</mat-icon></button>
  </mat-toolbar>

  @if (post) {
    <div mat-dialog-content class="Dialog__content">
      <div class="p-3">
        <app-content-view [content]="post.content | linkImages"></app-content-view>
      </div>
      @if (post.media?.length) {
        <div class="flex flex-col p-3">
          <h3 class="Content-label">Bifogade filer</h3>
          <mat-action-list dense>
            @for (media of post.media; track media) {
              <button mat-list-item (click)="viewFile(media)">
                <mat-icon matListItemIcon>file_present</mat-icon>
                <p matListItemTitle>{{ media.title }}</p>
              </button>
            }
          </mat-action-list>
        </div>
      }
      <div class="p-3">
        <mat-chip-set>
          @for (tag of post.attrs('keyword'); track tag) {
            <mat-chip color="primary">{{ tag }}</mat-chip>
          }
        </mat-chip-set>
      </div>
    </div>
  }
</div>
