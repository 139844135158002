import { Injectable, inject } from '@angular/core';
import { AwareHttpRequestModule, AwareHttpService, IAwareCollection } from '@appbolaget/aware-http';
import { Client, Message, Thread, File as AwareFile } from '@appbolaget/aware-model';
import { Observable, map } from 'rxjs';
import { MessageFormValue, MessageId, ThreadId } from './components/chat/chat.service';

@Injectable({ providedIn: 'root' })
export class MessagesRepositoryService {
    #api = inject(AwareHttpService);

    addClientsToThread(thread: Thread, clients: Client[]): Observable<void> {
        return this.#api
            .put(
                `threads/${thread.uuid}/attach/clients`,
                clients.map((c) => ({ id: c.uuid })),
            )
            .stream('*')
            .module(AwareHttpRequestModule.Broadcast)
            .execute();
    }

    removeClientsFromThread(thread: Thread, clients: Client[]): Observable<void> {
        return this.#api
            .put(
                `threads/${thread.uuid}/detach/clients`,
                clients.map((c) => ({ id: c.uuid })),
            )
            .stream('*')
            .module(AwareHttpRequestModule.Broadcast)
            .execute();
    }

    createThreadWithClients(clients: Client[]): Observable<Thread> {
        return this.#api
            .post<Thread>('threads', { type: clients.length > 2 ? 'group' : 'private', clients: clients.map((c) => c.uuid) })
            .toModel(Thread)
            .module(AwareHttpRequestModule.Broadcast)
            .execute();
    }

    deleteMessage(thread: Thread, message: Message): Observable<void> {
        return this.#api.delete(`threads/${thread.uuid}/messages`, null, message.uuid).module(AwareHttpRequestModule.Broadcast).execute();
    }

    deleteThread(thread: Thread): Observable<void> {
        return this.#api.delete('threads', null, thread.uuid).module(AwareHttpRequestModule.Broadcast).execute();
    }

    getFilesByThread(uuid: string): Observable<IAwareCollection<AwareFile>> {
        return this.#api
            .get<IAwareCollection<AwareFile>>('files')
            .parameter('thread', uuid)
            .with('attributes')
            .sort('message_created_at', 'desc')
            .module(AwareHttpRequestModule.Media)
            .toCollection(AwareFile)
            .execute();
    }

    getMessagesByThread(uuid: string, page = 1, after?: string): Observable<IAwareCollection<Message>> {
        return this.#api
            .get<IAwareCollection<Message>>(`threads/${uuid}/messages`)
            .with('client')
            .sort('id', 'desc')
            .parameter('after', after)
            .header('deleted', '*')
            .page(page)
            .limit(25)
            .module(AwareHttpRequestModule.Broadcast)
            .toCollection(Message)
            .execute();
    }

    getInitialThreads(): Observable<IAwareCollection<Thread>> {
        return this.#api
            .get<IAwareCollection<Thread>>('threads')
            .limit(100)
            .module(AwareHttpRequestModule.Broadcast)
            .toCollection(Thread)
            .execute();
    }

    getThreadsAfterTimestamp(timestamp: string): Observable<IAwareCollection<Thread>> {
        return this.#api
            .get<IAwareCollection<Thread>>('threads')
            .parameter('after', timestamp)
            .module(AwareHttpRequestModule.Broadcast)
            .toCollection(Thread)
            .execute();
    }

    getThreadByUuid(uuid: string): Observable<Thread> {
        return this.#api
            .show<Thread>('threads', uuid)
            .with('clients', 'meetings')
            .module(AwareHttpRequestModule.Broadcast)
            .toModel(Thread)
            .execute();
    }

    getThreadsWithClients(clients: Client[]): Observable<IAwareCollection<Thread>> {
        return this.#api
            .get<IAwareCollection<Thread>>('threads/search')
            .parameter('clients', JSON.stringify(clients.map((c) => c.uuid)))
            .parameter('strict', true)
            .module(AwareHttpRequestModule.Broadcast)
            .toCollection(Thread)
            .execute();
    }

    markThreadAsSeen(threadId: ThreadId): Observable<void> {
        return this.#api.put(`threads/${threadId}/seen`, null, null).module(AwareHttpRequestModule.Broadcast).execute();
    }

    sendMessageToThread(threadId: ThreadId, message: MessageFormValue, sendPush: boolean): Observable<Message> {
        return this.#api
            .post<Message>(`threads/${threadId}/messages`, { message: message.message, files: message.files?.map((f) => f.uuid) })
            .with('client')
            .module(AwareHttpRequestModule.Broadcast)
            .header('app', sendPush ? this.#api.headers.get('app') : null)
            .toModel(Message)
            .execute();
    }

    sendPushNotificationToThread(threadId: ThreadId, title: string, message: string): Observable<void> {
        return this.#api
            .post<void>('push', { thread: threadId, title, message })
            .stream('*')
            .header('scope', 'units')
            .module(AwareHttpRequestModule.Broadcast)
            .execute();
    }

    updateMessageInThread(threadId: ThreadId, messageId: MessageId, message: MessageFormValue): Observable<Message> {
        return this.#api
            .put<Message>(`threads/${threadId}/messages`, { message: message.message, files: message.files?.map((f) => f.uuid) }, messageId)
            .with('client')
            .module(AwareHttpRequestModule.Broadcast)
            .toModel(Message)
            .execute();
    }

    updateThread(thread: ThreadId, data: any): Observable<Thread> {
        return this.#api
            .put<Thread>('threads', data, thread)
            .toModel(Thread)
            .module(AwareHttpRequestModule.Broadcast)
            .with('clients,meetings')
            .execute();
    }

    uploadFilesToThread(threadId: ThreadId, files: File[]): Observable<AwareFile[]> {
        const formData = new FormData();

        formData.append('thread', threadId);
        files.forEach((file) => {
            formData.append('files[]', file);
        });

        return this.#api
            .upload(formData)
            .module(AwareHttpRequestModule.Media)
            .execute()
            .pipe(map((result) => (Array.isArray(result.data) ? result.data : [result.data])));
    }
}
