@if (collection) {
  <div class="flex items-center justify-between p-1">
    <span class="mat-subtitle-2 ml-4 !text-secondary">Sida: {{ collection.page }}</span>
    <div class="flex items-center">
      <button mat-icon-button [disabled]="collection.page <= 1" (click)="onGoToPage.emit(1)">
        <mat-icon>first_page</mat-icon>
      </button>
      <button mat-icon-button [disabled]="collection.page <= 1" (click)="onGoToPage.emit(collection.page - 1)">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <button mat-icon-button [disabled]="!collection.next" (click)="onGoToPage.emit(collection.page + 1)">
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </div>
}
