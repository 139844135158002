@if (mapLoaded$ | async) {
  <google-map
    [center]="{ lat: mapPosition.lat, lng: mapPosition.lng }"
    [options]="{ disableDefaultUI: true }"
    [zoom]="zoom"
    width="100%"
    height="300px"
  >
    @if (hasMarker) {
      <map-marker
        [position]="{ lat: latitude.value, lng: longitude.value }"
        [options]="{ draggable: true }"
        (mapDragend)="markerDragged($event)"
      ></map-marker>
    }
  </google-map>
}

<button mat-mini-fab color="primary" (click)="toggleMarker()" class="toggle-marker-button" type="button">
  <mat-icon>{{ hasMarker ? 'location_off' : 'location_on' }}</mat-icon>
</button>
