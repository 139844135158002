import { Component, Input, Output, OnInit, EventEmitter, inject } from '@angular/core';
import { Config } from '@services';
import { ListDatePipe } from '@helpers/pipes';
import { SearchbarComponent } from '../searchbar/searchbar.component';
import { debounceTime, tap, takeWhile } from 'rxjs/operators';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { ChangeApiLanguage } from 'app/state/app.actions';
import { SelectionType, NgxDatatableModule } from '@swimlane/ngx-datatable';
import { UnitState } from 'app/state/unit.state';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'aw-table',
    templateUrl: 'aw-table.component.html',
    styleUrls: ['./aw-table.scss'],
    providers: [ListDatePipe],
    imports: [NgxDatatableModule, MatButtonModule, MatIconModule, TranslateModule]
})
export class AwareTableComponent implements OnInit {
    _columns;
    _config;
    conf;
    limit = 25;
    page = 1;
    DID_SEARCH = false;

    dateColumns = ['created_at', 'updated_at'];

    externalSorting = true;

    filter: any = {
        search: '',
        sort: {
            'model.created_at': 'desc',
        },
    };

    selected: any = [];
    selectionTypes = SelectionType;

    ALIVE: boolean;
    IS_CLICKABLE = false;
    IS_LOADING: boolean;

    private _data: any;

    get columns() {
        return this._columns;
    }

    get config() {
        return this._config;
    }

    get data() {
        return this._data;
    }

    @Input('config')
    set config(config) {
        const data = [];

        for (const col of config.columns) {
            const val = Object.assign({}, this.mainConfig.get('table.column'), col);

            if (this.dateColumns.includes(val.prop)) {
                val.pipe = this.listDatePipe;
                val.width = this.mainConfig.get('table.datefields.minWidth');
                val.sortcol = val.sortcol ? val.sortcol : `model.${val.prop}`;
            }

            data.push(val);
        }

        this._columns = data;
        this._config = Object.assign({}, this.mainConfig.get('table.options'), config.options);

        if (!this._config.params) {
            this._config.params = {};
        }

        if (config.filter) {
            this.filter = Object.assign({}, this.filter, { filter: config.filter });
        }
    }

    @Input('data')
    set data(data) {
        if (data) {
            this._data = data;
            this.setRows();
        }
    }

    @Input() service;
    @Input() search: SearchbarComponent;

    @Output() click = new EventEmitter();
    @Output() select = new EventEmitter();

    rows: any[] = [];

    messages: any = {
        totalMessage: 'resultat',
        emptyMessage: 'Det finns ingen data att visa',
    };

    private store = inject(Store);
    constructor(
        public mainConfig: Config,
        public listDatePipe: ListDatePipe,
        private actions$: Actions,
    ) {}

    ngOnInit() {
        this.ALIVE = true;
        this.conf = this.mainConfig.get('table');

        this.actions$
            .pipe(
                takeWhile(() => this.ALIVE),
                ofActionDispatched(ChangeApiLanguage),
                tap(() => this.getData()),
            )
            .subscribe();

        if (this.data) {
            this.rows = this.data;
        } else if (this.service) {
            this.store
                .select(UnitState.activeUnit)
                .pipe(
                    debounceTime(300),
                    takeWhile(() => this.ALIVE),
                    tap(() => {
                        this.getData();
                    }),
                )
                .subscribe();
        }

        if (this.search) {
            this.search.onSearch = (value) => this.onSearch(value);
        }

        if (this.click.observers.length > 0) {
            this.IS_CLICKABLE = true;
        }
    }

    ngOnDestroy() {
        this.ALIVE = false;
    }

    getData() {
        this.IS_LOADING = true;

        this.service[this.config.method](this.filter, this.config.params).subscribe((result) => {
            this.rows = result;

            this.IS_LOADING = false;
        });
    }

    getRowClass(row) {
        return `Table__row Table__row--level_${row.tableLevel}`;
    }

    goToPage(page) {
        Object.assign(this.config.params, {
            limit: this.limit,
            offset: this.limit * (page - 1),
        });

        this.page = page;

        this.getData();
    }

    onActivate($event) {
        if ($event.type === 'checkbox') {
            $event.event.stopPropagation();
        }

        if ($event.type === 'click') {
            this.click.emit($event.row);

            $event.event.stopPropagation();
        }
    }

    onSearch(value) {
        this.filter.search = value;

        if (!value || !value.length) {
            this.DID_SEARCH = false;
        } else {
            this.DID_SEARCH = true;
        }

        this.config.params.offset = 0;
        this.page = 1;

        this.getData();
    }

    onSort($event) {
        const prop = $event.column.sortcol ? $event.column.sortcol : $event.column.prop;

        this.filter.sort = {};
        this.filter.sort[prop] = $event.newValue;

        this.getData();
    }

    onRowSelect($event) {
        this.select.emit($event.selected);
    }

    setRows() {
        this.rows = this.data;
    }
}
