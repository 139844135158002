import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class DragDropService {
    dragData: any;
    scope: string | Array<string>;
    onDragStart = new Subject<void>();
    onDragEnd = new Subject<void>();

    constructor() {}
}
