import { Pipe, PipeTransform, inject } from '@angular/core';
import { Thread } from '@appbolaget/aware-model';
import { MessagesService } from '../messages.service';

@Pipe({
    name: 'hasUnreadMessages',
    standalone: true,
    pure: false,
})
export class HasUnreadMessagesPipe implements PipeTransform {
    #messagesService = inject(MessagesService);

    transform(thread: Thread): boolean {
        return this.#messagesService.threadHasUnreadMessages(thread);
    }
}
