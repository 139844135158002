import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, FormsModule, ReactiveFormsModule } from '@angular/forms';
import moment from 'moment';
import { Observable, combineLatest, filter, map, startWith, takeWhile, tap } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'app-date-time',
    templateUrl: 'date-time.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: DateTimeComponent,
        },
    ],
    imports: [MatFormFieldModule, MatInputModule, MatDatepickerModule, FormsModule, ReactiveFormsModule, TranslateModule]
})
export class DateTimeComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() includeTime = true;
    @Input() label!: string;
    @Input() min: Date;
    @Input() max: Date;
    @Input() endOfDay = false;

    dateControl = new FormControl();
    timeControl = new FormControl();

    ALIVE: boolean;

    constructor() {}

    onChange = (_) => {};

    onTouched = () => {};

    touched = false;

    disabled = false;

    ngOnInit(): void {
        this.ALIVE = true;

        this._initListeners();
    }

    ngOnDestroy(): void {
        this.ALIVE = false;
    }

    writeValue(date: string): void {
        if (date) {
            this.dateControl.setValue(moment(date).format('YYYY-MM-DD'));
            this.timeControl.setValue(moment(date).format('HH:mm'));
        }
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
        if (isDisabled) {
            this.dateControl.disable({ emitEvent: false });
            this.timeControl.disable({ emitEvent: false });
        } else {
            this.dateControl.enable({ emitEvent: false });
            this.timeControl.enable({ emitEvent: false });
        }
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    private _initListeners() {
        const date$: Observable<moment.Moment> = this.dateControl.valueChanges.pipe(startWith(this.dateControl.value));
        const time$: Observable<string> = this.timeControl.valueChanges.pipe(startWith(this.timeControl.value));

        combineLatest([date$, time$])
            .pipe(
                takeWhile(() => this.ALIVE),
                tap(([date, _]) => {
                    this.markAsTouched();
                    if (!date) {
                        this.onChange(null);
                    }
                }),
                filter(([date, _]) => date != null),
                map(([date, time]) => {
                    let dateAndTime = moment(moment(date).format('YYYY-MM-DD') + (this.includeTime ? ` ${time ?? '00:00'}` : ''));
                    if (!this.includeTime && this.endOfDay) {
                        dateAndTime = dateAndTime.endOf('day');
                    }
                    return dateAndTime;
                }),
                filter((dateTime) => dateTime.isValid()),
                map((dateTime) => dateTime.format(this.includeTime || this.endOfDay ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD')),
                tap((dateTime) => {
                    this.onChange(dateTime);
                }),
            )
            .subscribe();
    }
}
