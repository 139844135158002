import { Component, Input } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    template: `<div class="Spinner">
        <mat-spinner class="Spinner__spinner"></mat-spinner>
        <h3 class="Spinner__label">{{ label }}</h3>
    </div>`,
    selector: 'spinner',
    styleUrls: ['./spinner.scss'],
    imports: [MatProgressSpinnerModule]
})
export class SpinnerComponent {
    @Input() label: string;
}
