import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { File, Post } from '@appbolaget/aware-model';
import { BaseDialogComponent } from '@helpers/base';
import { LinkImagesPipe, SecureImagePipe } from '@helpers/pipes';
import { ClearNotificationsByValue } from '@modules/notification/notification.actions';
import { NotificationState } from '@modules/notification/notification.state';
import { Store } from '@ngxs/store';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ContentViewComponent } from '@components/editor/content-view.component';

@Component({
    selector: 'help-post-dialog',
    templateUrl: 'post-dialog.component.html',
    styleUrls: ['./post-dialog.component.scss'],
    providers: [SecureImagePipe, LinkImagesPipe],
    imports: [
        MatToolbarModule,
        MatDialogModule,
        MatIconModule,
        MatButtonModule,
        MatListModule,
        MatChipsModule,
        ContentViewComponent,
        LinkImagesPipe,
    ]
})
export class HelpPostDialogComponent extends BaseDialogComponent implements OnInit {
    post: Post;
    content: SafeHtml;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<HelpPostDialogComponent>,
        private secureImagePipe: SecureImagePipe,
        private linkImagesPipe: LinkImagesPipe,
        private domSanitizer: DomSanitizer,
        private store: Store,
    ) {
        super();
    }

    ngOnInit() {
        this.post = this.data.post;

        this.content = this.domSanitizer.bypassSecurityTrustHtml(this.linkImagesPipe.transform(this.post.content));

        if (this.store.selectSnapshot(NotificationState.notificationByValue(this.post.uuid))) {
            this.store.dispatch(new ClearNotificationsByValue(this.post.uuid));
        }
    }

    viewFile(file: File) {
        const path = this.secureImagePipe.transform(file.uuid);

        window.open(path, '_blank');
    }
}
