import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Client } from '@appbolaget/aware-model';
import { InitialsPipe, SecureImagePipe } from '@helpers/pipes';

@Component({
    selector: 'app-client-avatar',
    templateUrl: 'client-avatar.component.html',
    imports: [InitialsPipe, NgTemplateOutlet, NgClass, SecureImagePipe]
})
export class ClientAvatarComponent {
    @Input() client: Client;
    @Input() clients: Client[];
    @Input() dark: boolean;
    @Input() group: boolean;

    get clientsComputed(): Client[] {
        return this.client ? [this.client] : this.clients;
    }

    get clientsSpliced(): Client[] {
        return this.clientsComputed.slice(0, 4);
    }

    get showAsGroup(): boolean {
        return this.clientsComputed.length > 1 || this.group;
    }
}
