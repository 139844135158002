import { Injectable, inject } from '@angular/core';
import { Client, Message, Thread, File } from '@appbolaget/aware-model';
import {
    BehaviorSubject,
    EMPTY,
    Observable,
    Subject,
    catchError,
    concatMap,
    filter,
    from,
    iif,
    map,
    mergeMap,
    of,
    switchMap,
    take,
    tap,
} from 'rxjs';
import { ExtractFormControl } from '@helpers/types';
import { MessagesRepositoryService } from '@pages/messages/messages.repository';
import { IAwareCollection } from '@appbolaget/aware-http';
import { Router } from '@angular/router';
import { MESSAGES_ROUTE, MessageFormGroup, THREAD_ACTIONS } from '@pages/messages/symbols';
import { AwareAuthService } from '@appbolaget/aware-auth';
import { DialogService } from '@viewservices/dialog.service';
import {
    ThreadSelectionPromptComponent,
    ThreadSelectionPromptData,
    ThreadSelectionPromptResult,
} from '../thread-selection-prompt/thread-selection-prompt.component';
import { Config } from '@services/config';
import { IMessagesConfig } from '@pages/messages/messages.config';

export type ThreadId = string;
export type MessageId = string;
export type MessageFormValue = ExtractFormControl<MessageFormGroup>;

@Injectable({ providedIn: 'root' })
export class ChatService {
    #messagesRepository = inject(MessagesRepositoryService);
    #awareAuth = inject(AwareAuthService);
    #router = inject(Router);
    #dialogService = inject(DialogService);
    #configService = inject(Config);

    #activeThread$: BehaviorSubject<Thread> = new BehaviorSubject<Thread>(null);
    activeThread$ = this.#activeThread$.asObservable();
    #activeNewThread$: BehaviorSubject<Thread> = new BehaviorSubject<Thread>(null);
    activeNewThread$ = this.#activeNewThread$.asObservable();

    #notSentMessages: Map<ThreadId, MessageFormValue> = new Map<ThreadId, MessageFormValue>();
    newThreadCreated$: Subject<Thread> = new Subject<Thread>();
    newMessageSent$: Subject<[Message, Thread]> = new Subject<[Message, Thread]>();
    newMessagesRecieved$: Subject<Message[]> = new Subject<Message[]>();
    markThreadAsSeen$: Subject<ThreadId> = new Subject<ThreadId>();
    changedThreadParticipants$: Subject<Client[]> = new Subject<Client[]>();
    deleteMessage$: Subject<Message> = new Subject<Message>();
    editMessage$: Subject<Message> = new Subject<Message>();
    editMessageSuccess$: Subject<Message> = new Subject<Message>();
    updatedThreadTitle$: Subject<Thread> = new Subject<Thread>();
    leaveThread$: Subject<Thread> = new Subject<Thread>();

    constructor() {
        this.#initListeners();
    }

    addClientToActiveThread(client: Client): Observable<void> {
        return this.checkThreadParticipants(
            [...this.#activeThread$.getValue().clients, client],
            client,
            !this.#activeThread$.getValue().isGroupConversation,
        ).pipe(
            switchMap((action) =>
                iif(
                    () => action === false,
                    of(null),
                    of(action).pipe(
                        switchMap((action) => {
                            switch (action) {
                                case THREAD_ACTIONS.AddToExisting:
                                    return this.#activeThread$.pipe(
                                        take(1),
                                        switchMap((thread) => {
                                            if (!thread.isGroupConversation) {
                                                return this.toggleMakeGroupConversation(thread.uuid, true).pipe(map(() => thread));
                                            }

                                            return of(thread);
                                        }),
                                        switchMap((thread) =>
                                            this.#messagesRepository.addClientsToThread(thread, [client]).pipe(
                                                tap(() => {
                                                    this.changedThreadParticipants$.next([...thread.clients, client]);
                                                }),
                                            ),
                                        ),
                                    );

                                case THREAD_ACTIONS.CreateNew:
                                    return from(this.openNewThreadWithClients([...this.#activeThread$.getValue().clients, client])).pipe(
                                        map(() => null),
                                    );

                                default:
                                    return of(null);
                            }
                        }),
                    ),
                ),
            ),
        );
    }

    removeClientFromActiveThread(client: Client): Observable<void> {
        return this.#messagesRepository.removeClientsFromThread(this.#activeThread$.getValue(), [client]).pipe(
            tap(() => {
                this.changedThreadParticipants$.next(this.#activeThread$.getValue().clients.filter((c) => c.uuid !== client.uuid));
            }),
        );
    }

    /**
     * Rules:
     *  1. 1on1 created conversations are always unique. No more than one thread can exist with the same two clients.
     *     1a. This hasn't always been the case, so maybe have to do some cleanup/merging in the backend for this in the future.
     *  2. Group conversations are not unique. Multiple threads can exist with the same clients.
     *     2a. If a group conversation has the same clients as a 1on1 conversation, for example if someone leaves a group
     *         conversation and there is only two people left, it is still treated as a group conversation. So if I try
     *         to start a new conversation with that person that is left in the group, we will start a new 1on1 conversation.
     * Cases:
     *  1. We have a 1on1 thread and we want to add another client to it.
     *     1. We check if there is already a thread with the clients.
     *     2. If there is a thread, we ask the user if they want to open it or create a new one.
     *     3. If there is no thread, we open a new thread with the clients.
     *
     *  2. We have a group conversation and we want to add another client to it.
     *     1. We check if there is already a thread with the clients.
     *     2. If there is a thread, we ask the user if they want to open it or add the client to the existing one.
     *     3. If there is no thread, we add the client to the existing one.
     *       3a. Or maybe ask the user if they want to add the client to the existing one or create a new one.
     *
     * @param clients Array of clients to check if there is a thread with.
     * @param newClient The new client to add to the thread. Mainly for display purposes.
     * @param isPrivateConversation If the conversation is private or not.
     * @returns Observable<ThreadSelectionPromptResult> false if we should do nothing, ie the dialog window was closed. ThreadAction if we should do something.
     */
    checkThreadParticipants(clients: Client[], newClient: Client, _: boolean = false): Observable<ThreadSelectionPromptResult> {
        return this.#messagesRepository.getThreadsWithClients(clients).pipe(
            map((threads) =>
                threads.data.map((thread) => {
                    thread.clients = clients;
                    return thread;
                }),
            ),
            switchMap((threads) => this.showThreadSelectionPrompt(threads, newClient).pipe(map((action) => ({ threads, action })))),
            switchMap(({ threads, action }) => {
                if (action instanceof Thread) {
                    this.#router.navigate([...MESSAGES_ROUTE, action.uuid]);
                    return of(false);
                } else if (action === THREAD_ACTIONS.OpenThread) {
                    this.#router.navigate([...MESSAGES_ROUTE, threads[0].uuid]);
                    return of(false);
                }

                return of(action);
            }),
        );
    }

    createThreadWithClients(clients: Client[]): Observable<Thread> {
        return this.#messagesRepository.createThreadWithClients(clients).pipe(
            tap((thread) => {
                this.newThreadCreated$.next(thread);
            }),
        );
    }

    getFilesFromThread(uuid: string): Observable<File[]> {
        return this.#messagesRepository.getFilesByThread(uuid).pipe(map((files) => files.data));
    }

    getMessagesByThread(uuid: string, page = 1): Observable<IAwareCollection<Message>> {
        return this.#messagesRepository.getMessagesByThread(uuid, page).pipe(
            map((messages) => {
                messages.data = messages.data.reverse();
                return messages;
            }),
        );
    }

    getNewMessagesByThread(uuid: string, after: string): Observable<Message[]> {
        return this.#messagesRepository.getMessagesByThread(uuid, 1, after).pipe(
            map((messages) => {
                messages.data = messages.data.reverse();
                if (messages.data.length) {
                    this.newMessagesRecieved$.next(messages.data);
                }
                return messages.data;
            }),
            catchError(() => []),
        );
    }

    leaveActiveThread(): Observable<void> {
        return this.activeThread$.pipe(
            take(1),
            filter((thread) => !!thread),
            switchMap((thread) => this.#messagesRepository.deleteThread(thread)),
            map(() => null),
            tap(() => {
                this.setActiveThread(null);
                this.#router.navigate(MESSAGES_ROUTE);
            }),
        );
    }

    setActiveThread(thread: Thread | null) {
        this.#activeThread$.next(thread);

        if (thread) {
            this.#messagesRepository.getThreadByUuid(thread.uuid).subscribe({
                next: (thread) => {
                    this.#activeThread$.next(thread);
                },
            });
        }
    }

    setActiveNewThread(thread: Thread) {
        this.#activeNewThread$.next(thread);
    }

    getNotSentMessage(threadId: ThreadId): MessageFormValue {
        return this.#notSentMessages.get(threadId);
    }

    makeThreadWithClients(clients: Client[]): Thread {
        const thread = new Thread({ clients: [...clients, this.#awareAuth.client] });

        return thread;
    }

    openNewThreadWithClients(clients: Client[]): Promise<boolean> {
        const newThread = this.makeThreadWithClients(clients);
        this.setActiveNewThread(newThread);
        return this.#router.navigate([...MESSAGES_ROUTE, 'new']);
    }

    showThreadSelectionPrompt(
        threads: Thread[],
        client?: Client,
        canAddToExisting: boolean = true,
        canCreateNew: boolean = true,
    ): Observable<ThreadSelectionPromptResult> {
        return this.#dialogService
            .open<ThreadSelectionPromptComponent, ThreadSelectionPromptData, ThreadSelectionPromptResult>(ThreadSelectionPromptComponent, {
                data: {
                    threads,
                    client,
                    canAddToExisting,
                    canCreateNew,
                },
            })
            .afterClosed();
    }

    sendMessage(thread: Thread, form: MessageFormValue, sendPush: boolean = true): Observable<Message> {
        return of(thread).pipe(
            switchMap((thread) =>
                thread.uuid
                    ? of(thread)
                    : this.createThreadWithClients(thread.clients).pipe(
                          tap((thread) => {
                              this.#router.navigate([...MESSAGES_ROUTE, thread.uuid]);
                          }),
                      ),
            ),
            switchMap((thread) => {
                if (form.localFiles.length) {
                    return this.#messagesRepository.uploadFilesToThread(thread.uuid, form.localFiles).pipe(
                        map((files) => {
                            form.files = [...form.files, ...files];
                            return thread;
                        }),
                    );
                }

                return of(thread);
            }),
            switchMap((thread) =>
                form.editingMessageId
                    ? this.#messagesRepository.updateMessageInThread(thread.uuid, form.editingMessageId, form).pipe(
                          tap((message) => {
                              this.editMessageSuccess$.next(message);
                          }),
                      )
                    : this.#messagesRepository.sendMessageToThread(thread.uuid, form, sendPush).pipe(
                          tap((message) => {
                              this.newMessageSent$.next([message, thread]);
                          }),
                      ),
            ),
            tap(() => {
                this.#notSentMessages.delete(thread.uuid);
            }),
        );
    }

    sendPushNotificationToThreadRecipients(thread: Thread, message: Message): Observable<void> {
        const pushMessage = this.#configService.get<IMessagesConfig>('messages').notifications.push.body ?? message.message;

        return this.#messagesRepository
            .sendPushNotificationToThread(thread.uuid, this.#awareAuth.client.name, pushMessage)
            .pipe(catchError(() => EMPTY));
    }

    setReadByClients(thread: Thread, messages: Message[]): Message[] {
        const placedClients = new Set<string>();
        messages = messages.reverse().map((message) => {
            const readByClients = thread.clients.filter((c: Client) => {
                // Filter out myself from the list
                if (!c.uuid || c.uuid === this.#awareAuth.client.uuid) {
                    return false;
                }

                let seenAt = c.seen_at;

                // Return false if client has no seen_at
                if (!seenAt) {
                    return false;
                }

                // If a new message has come in, set seen_at to that message's created_at
                if (message.client?.uuid === c.uuid && message.created_at > seenAt) {
                    seenAt = message.created_at;
                }

                // Return false if seenAt is less than message created_at
                if (seenAt < message.created_at) {
                    return false;
                }

                // Return false if client already has been placed in a list
                if (placedClients.has(c.uuid)) {
                    return false;
                }

                // Add client to placedClients so it can't be placed in another message
                placedClients.add(c.uuid);
                return true;
            });

            message.readByClients = readByClients;
            return message;
        });

        return messages.reverse();
    }

    toggleMakeGroupConversation(threadId: ThreadId, makeGroupConversation: boolean): Observable<Thread> {
        return this.#messagesRepository.updateThread(threadId, { type: makeGroupConversation ? 'group' : 'private' });
    }

    updateNotSentMessage(threadId: ThreadId, form: MessageFormValue) {
        this.#notSentMessages.set(threadId, form);
    }

    updateThreadTitle(threadId: ThreadId, title: string): Observable<Thread> {
        return this.#messagesRepository.updateThread(threadId, { title }).pipe(
            tap(() => {
                const thread = this.#activeThread$.getValue();
                thread.title = title;
                this.#activeThread$.next(thread);
                this.updatedThreadTitle$.next(thread);
            }),
        );
    }

    #initListeners() {
        this.markThreadAsSeen$.pipe(mergeMap((threadId) => this.#messagesRepository.markThreadAsSeen(threadId))).subscribe();

        this.changedThreadParticipants$
            .pipe(
                tap((clients) => {
                    const thread = this.#activeThread$.value;
                    thread.clients = clients;
                    this.#activeThread$.next(thread);
                }),
            )
            .subscribe();

        this.deleteMessage$
            .pipe(mergeMap((message) => this.#messagesRepository.deleteMessage(this.#activeThread$.value, message)))
            .subscribe();

        this.newMessageSent$
            .pipe(
                map((message) => [this.#configService.get<IMessagesConfig>('messages'), message]),
                filter(
                    ([config, _]: [IMessagesConfig, [Message, Thread]]) =>
                        config.notifications.push.enabled && config.notifications.push.sendAsParallell,
                ),
                concatMap(([_, [message, thread]]: [IMessagesConfig, [Message, Thread]]) =>
                    this.sendPushNotificationToThreadRecipients(thread, message),
                ),
            )
            .subscribe();
    }
}
