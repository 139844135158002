import { Component, ContentChild, Input } from '@angular/core';
import { AlertActionsDirective } from './alert-actions.directive';
import { NgClass, NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'app-alert',
    templateUrl: 'alert.component.html',
    imports: [NgClass, NgTemplateOutlet]
})
export class AlertComponent {
    @Input() type: string = 'info';
    @Input() label: string;

    @ContentChild(AlertActionsDirective) alertActions: AlertActionsDirective;

    constructor() {}
}
