import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { BaseDialogComponent } from '@helpers/base';
import { FileUploaderService } from '@services/http';
import { SnackbarService } from '../../services';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';

@Component({
    selector: 'support-dialog',
    templateUrl: 'support-dialog.html',
    styleUrls: ['./support-dialog.scss'],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatToolbarModule,
        MatDialogModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
    ]
})
export class SupportDialogComponent extends BaseDialogComponent implements OnInit {
    form: UntypedFormGroup;

    IS_LOADING: boolean;

    constructor(
        public dialogRef: MatDialogRef<SupportDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public fb: UntypedFormBuilder,
        public snackbarService: SnackbarService,
        public fileUploaderService: FileUploaderService,
    ) {
        super();
    }

    ngOnInit() {
        this.buildForm();
    }

    buildForm() {
        this.form = this.fb.group({
            message: ['', Validators.required],
        });
    }

    submit() {
        // TODO: Make this work someday
        // let formData = new FormData();

        this.IS_LOADING = true;

        // const data: any = {
        //     subject: 'Aware Admin',
        //     title: 'Support & feedback',
        //     content: this.form.value.message,
        //     recipients: JSON.stringify(['support@appbolaget.se']),
        //     metadata: JSON.stringify(this._getClientInfo()),
        // };

        // html2canvas(document.getElementById('main'))
        //     .then((canvas) => {
        //         let image = canvas.toDataURL('screenshot.png');

        //         urlToFile(image, 'Screenshot.png', 'image/png').then(
        //             (file) => {
        //                 this.sendEmail(data, [file]);
        //             },
        //             () => {
        //                 this.sendEmail(data);
        //             }
        //         );
        //     })
        //     .catch((err) => {
        //         this.sendEmail(data);
        //     });
    }

    sendEmail(data, files: Array<File> = []) {
        this.fileUploaderService.upload('email', data, files).then(() => {
            this.snackbarService.success('Meddelandet skickades. Vi ska försöka svara så snabbt vi kan!');

            this.dismiss();
        });
    }

    // private _getClientInfo() {
    //     return {
    //         timeOpened: moment().format('YYYY-MM-DD HH:mm'),
    //         timezone: new Date().getTimezoneOffset() / 60,

    //         pageon: window.location.pathname,
    //         referrer: document.referrer,
    //         previousSites: history.length,

    //         browserName: navigator.appName,
    //         browserEngine: navigator.product,
    //         browserVersion1a: navigator.appVersion,
    //         browserVersion1b: navigator.userAgent,
    //         browserLanguage: navigator.language,
    //         browserOnline: navigator.onLine,
    //         browserPlatform: navigator.platform,
    //         javaEnabled: navigator.javaEnabled(),
    //         dataCookiesEnabled: navigator.cookieEnabled,
    //         dataCookies1: document.cookie,

    //         sizeScreenW: screen.width,
    //         sizeScreenH: screen.height,
    //         sizeInW: innerWidth,
    //         sizeInH: innerHeight,
    //         sizeAvailW: screen.availWidth,
    //         sizeAvailH: screen.availHeight,
    //         scrColorDepth: screen.colorDepth,
    //         scrPixelDepth: screen.pixelDepth,

    //         'Active unit': this.auth.unit.title,
    //         'Active org': this.auth.org.title,

    //         'Attached units': this.auth.units.map((unit) => unit.title).join(', '),
    //     };
    // }
}
