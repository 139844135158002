<div class="Dialog Dialog--post">
  <mat-toolbar color="primary" class="Dialog__header">
    <div>
      <h2 class="Dialog__title" mat-dialog-title><mat-icon>qr_code</mat-icon> QR-kod</h2>
    </div>
    <span class="flex-1"></span>
    <div class="Dialog__header-buttons">
      <button mat-icon-button (click)="print()"><mat-icon>print</mat-icon></button>
      <button mat-icon-button type="button" (click)="dismiss()"><mat-icon>close</mat-icon></button>
    </div>
  </mat-toolbar>
  <div class="Dialog__content Post" mat-dialog-content>
    <qr-code [value]="data.uuid" [size]="400"></qr-code>
  </div>
  <!-- <div class="Dialog__actions" mat-dialog-actions>
    <span class="flex-1"></span>
    <button type="button" mat-button 
    (click)="dialogRef.close()">Stäng</button>
  </div> -->
</div>
