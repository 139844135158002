import { Component, Input, OnInit, OnDestroy, HostListener, inject } from '@angular/core';
import { AbstractControl, UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import ClassicEditor from '@appbolaget/ckeditor5-custom-build';

import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@viewservices';
import { AwareHtmlEditorComponent } from './html-editor.component';
import { take, takeWhile } from 'rxjs/operators';
import { EditorConfig } from '@ckeditor/ckeditor5-core';
import { SecureImagePipe } from '@helpers/pipes';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MediaService } from '@modules/media/services/media.service';
import { File as AwareFile, Unit } from '@appbolaget/aware-model';

@Component({
    selector: 'aw-editor',
    templateUrl: 'editor.component.html',
    styleUrls: ['./editor.component.scss'],
    providers: [SecureImagePipe],
    imports: [CKEditorModule, FormsModule, ReactiveFormsModule],
})
export class EditorComponent implements OnInit, OnDestroy {
    @Input()
    control: AbstractControl;
    @Input()
    height: any = 300;
    @Input()
    toolbar = 'full';
    @Input()
    customPlaceholder: any = 'PAGE.TYPE_HERE';
    @Input() unit: Unit;

    @HostListener('window:aware-media-library', ['$event'])
    openMediaLibrary(_) {
        this._openMediaLibrary();
    }

    get formControl(): UntypedFormControl {
        return this.control as UntypedFormControl;
    }

    editor = ClassicEditor;

    editorConfig: EditorConfig;
    editorInstance: ClassicEditor;
    data: string;

    ALIVE: boolean;

    #secureImagePipe = inject(SecureImagePipe);
    #mediaService = inject(MediaService);
    constructor(
        private translate: TranslateService,
        private dialogService: DialogService,
    ) {}

    ngOnInit() {
        this.ALIVE = true;

        this.translate
            .get(this.customPlaceholder)
            .pipe(takeWhile(() => this.ALIVE))
            .subscribe((placeholder: string) => {
                this.customPlaceholder = placeholder;
            });

        this.data = this.control.value;

        this._configEditor();
    }

    ngOnDestroy() {
        this.ALIVE = false;

        document.removeEventListener(`awareMedia:open:${this.editorInstance.id}`, this._openMediaLibrary.bind(this));
    }

    editorCreated(e: ClassicEditor) {
        this.editorInstance = e;

        document.addEventListener(`awareMedia:open:${e.id}`, this._openMediaLibrary.bind(this));
    }

    openCodeDialog() {
        const html = this.editorInstance.getData();

        const dialog = this.dialogService.open(AwareHtmlEditorComponent, {
            width: '600px',
            data: {
                html,
            },
        });

        dialog
            .afterClosed()
            .pipe(take(1))
            .subscribe((newHtml) => {
                if (newHtml) {
                    this.editorInstance.setData(newHtml);
                }
            });
    }

    private async _configEditor() {
        const placeholder = this.translate.instant(this.customPlaceholder);

        this.editorConfig = {
            placeholder,
            language: 'sv',
            image: {
                // Configure the available styles.
                // styles: ['full', 'alignLeft', 'alignCenter', 'alignRight'],

                // Configure the available image resize options.
                resizeOptions: [
                    {
                        name: 'resizeImage:original',
                        label: 'Original',
                        value: null,
                    },
                    {
                        name: 'resizeImage:50',
                        label: '50%',
                        value: '50',
                    },
                    {
                        name: 'resizeImage:75',
                        label: '75%',
                        value: '75',
                    },
                ],
            },
        };
    }

    private async _openMediaLibrary() {
        const dialog = await this.#mediaService.openDialog({ canReturnMultiple: false, expectsReturnedValue: true });

        const file = await dialog.afterClosed().pipe(take(1)).toPromise();

        if (file instanceof AwareFile) {
            const content = this.editorInstance.model.change((writer) => {
                const docFrag = writer.createDocumentFragment();

                if (file.isImage()) {
                    const image = writer.createElement('imageBlock', {
                        src: this.#secureImagePipe.transform(file.getFilePath({ w: 1280 }), null, true, false),
                    });

                    writer.append(image, docFrag);

                    return docFrag;
                }

                const element = writer.createText(file.title, {
                    linkHref: this.#secureImagePipe.transform(file.getFilePath(), null, true, false),
                });

                writer.append(element, docFrag);

                return docFrag;
            });

            this.editorInstance.model.insertContent(content, this.editorInstance.model.document.selection);
        }
    }
}
