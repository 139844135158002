import { AfterViewInit, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'aw-meta-form',
    templateUrl: 'meta-form.component.html',
    imports: [FormsModule, ReactiveFormsModule, NgTemplateOutlet, MatFormFieldModule, MatInputModule, TextFieldModule, TranslateModule]
})
export class MetaFormComponent implements AfterViewInit {
    @Input() form: UntypedFormGroup;

    INITTED: boolean;

    @ViewChild('textFieldTemplate') textFieldTemplate: TemplateRef<any>;
    @ViewChild('textAreaTemplate') textAreaTemplate: TemplateRef<any>;
    @ViewChild('colorPickerTemplate') colorPickerTemplate: TemplateRef<any>;

    get metaArray(): UntypedFormArray {
        return this.form.get('meta') as UntypedFormArray;
    }

    templateTypes: any;

    ngAfterViewInit() {
        this.templateTypes = {
            text: this.textFieldTemplate,
            textarea: this.textAreaTemplate,
            color: this.colorPickerTemplate,
        };

        // Expression changed bs
        setTimeout(() => {
            this.INITTED = true;
        }, 10);
    }
}
