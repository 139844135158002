<div class="flex items-center gap-4 overflow-hidden my-3 px-2">
  <app-client-avatar
    [clients]="thread.clients | excludeMyself"
    [group]="thread.isGroupConversation"
    class="w-10 h-10 flex-none"
  ></app-client-avatar>
  <div class="flex flex-col flex-1 overflow-hidden items-start">
    <div class="flex gap-1 justify-start overflow-hidden w-full">
      <h3
        class="text-base text-left font-normal mb-0 overflow-hidden flex-1 text-ellipsis"
        [class.font-semibold]="(thread | hasUnreadMessages) || selected"
      >
        {{ thread.clients | threadTitle: thread.title }}
      </h3>
      @if (thread.last_message?.created_at; as createdAt) {
        <span class="flex-none text-black/[.38] text-xs">{{ createdAt | amCalendar }}</span>
      }
    </div>
    @if (thread.last_message | lastMessage; as lastMessage) {
      <span class="text-black/[.54] text-sm whitespace-nowrap overflow-hidden text-ellipsis gap-1">
        @if (lastMessage.icon; as icon) {
          <mat-icon class="!w-4 !h-4 text-sm">{{ icon }}</mat-icon>
        }
        <span [innerHTML]="lastMessage.message"></span>
      </span>
    }
  </div>
</div>
