import { Pipe, PipeTransform, inject } from '@angular/core';
import { AwareAuthService } from '@appbolaget/aware-auth';
import { Client } from '@appbolaget/aware-model';

@Pipe({
    name: 'excludeMyself',
    standalone: true,
})
export class ExcludeMyselfPipe implements PipeTransform {
    #awareAuth = inject(AwareAuthService);

    transform(clients: Client[]): Client[] {
        return clients.filter((client) => client.uuid !== this.#awareAuth.client.uuid);
    }
}
