export function isJSONArray(value) {
  return Array.isArray(value);
}
export function isJSONObject(value) {
  return value !== null && typeof value === 'object' && (value.constructor === undefined ||
  // for example Object.create(null)
  value.constructor.name === 'Object') // do not match on classes or Array
  ;
}
export function isJSONPatchOperation(value) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return value && typeof value === 'object' ? typeof value.op === 'string' : false;
}
export function isJSONPatchAdd(value) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return value && typeof value === 'object' ? value.op === 'add' : false;
}
export function isJSONPatchRemove(value) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return value && typeof value === 'object' ? value.op === 'remove' : false;
}
export function isJSONPatchReplace(value) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return value && typeof value === 'object' ? value.op === 'replace' : false;
}
export function isJSONPatchCopy(value) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return value && typeof value === 'object' ? value.op === 'copy' : false;
}
export function isJSONPatchMove(value) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return value && typeof value === 'object' ? value.op === 'move' : false;
}
export function isJSONPatchTest(value) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return value && typeof value === 'object' ? value.op === 'test' : false;
}
