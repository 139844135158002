@if (config$ | async; as conf) {
  <div class="Table flex flex-col">
    @if (search) {
      <aw-search-bar
        class="w-[400px] self-center my-2"
        #search
        [style]="'modern'"
        [searchValue]="query$ | async"
        [placeholder]="'COMMON.SEARCH' | translate"
        (searchChange)="query$.next($event); page$.next(1)"
      ></aw-search-bar>
    }
    @if (query$ | async; as query) {
      @if (query?.length && !IS_LOADING) {
        <div class="Table__searchresults">
          Din sökning efter&nbsp;<strong>{{ query }}</strong
          >&nbsp;gav&nbsp;
          @if ((result$ | async).next) {
            <span>fler än &nbsp;</span>
          }
          <strong>{{ (result$ | async).data.length }}</strong
          >&nbsp;resultat
        </div>
      }
    }
    <ngx-datatable
      #datatable
      class="material fullscreen Table__table"
      columnMode="force"
      [headerHeight]="40"
      [footerHeight]="40"
      [rowHeight]="40"
      [class.Table__table--viewonly]="READONLY"
      [externalSorting]="conf.externalSorting"
      [sorts]="sorts$ | async"
      [loadingIndicator]="IS_LOADING"
      [reorderable]="false"
      [messages]="messages"
      [rows]="(result$ | async)?.data"
      [columns]="conf.columns"
      [count]="(result$ | async)?.data.length"
      [limit]="conf.limit"
      [rowClass]="getRowClass"
      [selected]="selected"
      [selectionType]="selectionTypes.checkbox"
      (sort)="sort$.next({ prop: $event.column.prop, dir: $event.newValue })"
      (select)="rowSelect.emit($event.selected)"
      (activate)="onActivate($event)"
    >
      @if (conf.options.selectable) {
        <ngx-datatable-column
          [width]="30"
          [sortable]="false"
          [canAutoResize]="false"
          [draggable]="false"
          [resizeable]="false"
          [headerCheckboxable]="true"
          [checkboxable]="true"
        >
        </ngx-datatable-column>
      }
      @for (column of conf.columns; track column) {
        <ngx-datatable-column
          [name]="column.name"
          [prop]="column.prop"
          [cellTemplate]="column.cellTemplateRef"
          [sortable]="column.disableSorting ? false : true"
          [flexGrow]="column.flexGrow ? column.flexGrow : null"
          [width]="column.width ? column.width : null"
          [minWidth]="column.minWidth ? column.minWidth : null"
          [maxWidth]="column.maxWidth ? column.maxWidth : null"
          [pipe]="column.pipe ? column.pipe : null"
          [frozenRight]="column.frozenRight ? column.frozenRight : false"
          [frozenLeft]="column.frozenLeft ? column.frozenLeft : false"
          [canAutoResize]="false"
          [draggable]="false"
          [resizeable]="false"
        >
        </ngx-datatable-column>
      }
      <ngx-datatable-footer>
        <ng-template ngx-datatable-footer-template let-rowCount="rowCount">
          @if (result$ | async; as result) {
            <div class="Table__footer flex-1 flex items-center justify-between">
              <div>{{ 'PAGE.PAGE' | translate }} {{ result.page }}: {{ rowCount }} {{ 'PAGE.RESULTS' | translate }}</div>
              <div>
                <button mat-icon-button (click)="page$.next(1)" [disabled]="result.page === 1 || IS_LOADING">
                  <mat-icon>first_page</mat-icon>
                </button>
                <button mat-icon-button (click)="page$.next(result.page - 1)" [disabled]="result.page === 1 || IS_LOADING">
                  <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
                <button mat-icon-button (click)="page$.next(result.page + 1)" [disabled]="!result.next || IS_LOADING">
                  <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
              </div>
            </div>
          }
        </ng-template>
      </ngx-datatable-footer>
    </ngx-datatable>
  </div>
}

<ng-template #titleCellTemplate let-column="title" let-value="value" let-row="row">
  <div class="flex items-center">
    @if (config.stream && row[config.stream.column] !== unit.id) {
      <mat-icon fontSet="material-icons-outlined" class="mr-1">arrow_circle_up</mat-icon>
    }
    @if (row.publish_at && row.publish_at > now) {
      <mat-icon fontSet="material-icons-outlined" [matTooltip]="'Publiceras ' + row.publish_at + ''" class="mr-1 text-gray-500"
        >schedule</mat-icon
      >
    }
    <span>{{ value }}</span>
  </div>
</ng-template>

<ng-template #colorCellTemplate let-column="column" let-row="row" let-value="value">
  <div
    class="Table__color-badge"
    [style.--color]="value"
    [matTooltipDisabled]="!getColumnConfigFromProp(column.prop, 'tooltip')?.enabled"
    [matTooltip]="
      (getColumnConfigFromProp(column.prop, 'tooltip')?.value ?? getColumnConfigFromProp(column.prop, 'tooltip')?.valueProp)
        ? row[getColumnConfigFromProp(column.prop, 'tooltip').valueProp]
        : null
    "
    [matTooltipPosition]="getColumnConfigFromProp(column.prop, 'tooltip')?.position || 'left'"
  ></div>
</ng-template>

<ng-template #attributeSimpleCellTemplate let-column="column" let-row="row" let-value="value">
  <span
    [matTooltipDisabled]="!getColumnConfigFromProp(column.prop, 'tooltip')?.enabled"
    [matTooltip]="getColumnConfigFromProp(column.prop, 'tooltip')?.value || row.attr(column.prop)"
    [matTooltipPosition]="getColumnConfigFromProp(column.prop, 'tooltip')?.position || 'left'"
    >{{ row.attr(column.prop) }}</span
  >
</ng-template>

<ng-template #attributeTextCellTemplate let-column="column" let-row="row" let-value="value">
  <span
    [matTooltipDisabled]="!getColumnConfigFromProp(column.prop, 'tooltip')?.enabled"
    [matTooltip]="getColumnConfigFromProp(column.prop, 'tooltip')?.value || row.attr(column.prop, 'text')"
    [matTooltipPosition]="getColumnConfigFromProp(column.prop, 'tooltip')?.position || 'left'"
    >{{ row.attr(column.prop, 'text') }}</span
  >
</ng-template>
