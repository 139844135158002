<div class="Dialog">
  <mat-toolbar color="primary" class="Dialog__header">
    <h2 class="Dialog__title" mat-dialog-title>Vänta lite...</h2>
    <span class="flex-1"></span>
    <button mat-icon-button type="button" (click)="dismiss()"><mat-icon>close</mat-icon></button>
  </mat-toolbar>
  <div class="Dialog__content" mat-dialog-content>
    <div class="Dialog__inner">
      <app-alert class="w-96 flex mb-2">{{ dialogMessage }}</app-alert>
      <ul class="list-none m-0 p-0">
        @for (button of buttons; track button) {
          <li class="flex">
            <button mat-stroked-button class="w-96 !h-auto" color="primary" (click)="selectThreadAction(button.value)">
              <div class="flex items-center w-80 p-2 gap-4">
                <mat-icon class="!w-8 !h-8 !text-3xl">{{ button.icon }}</mat-icon>
                <span class="flex-1 text-left text-secondary">{{ button.label }}</span>
              </div>
            </button>
          </li>
        }
      </ul>
      @if (hasMultipleThreads) {
        <span class="my-4 flex font-semibold">{{
          buttons.length ? 'Eller öppna någon av redan existerande trådar:' : 'Öppna någon av de existerande trådarna:'
        }}</span>
        <ul class="list-none m-0 p-0">
          @for (thread of data.threads; track thread) {
            <li>
              <button type="button" (click)="dialogRef.close(thread)" class="hover:bg-gray-200 w-full">
                <app-messages-thread-list-item [thread]="thread"></app-messages-thread-list-item>
              </button>
            </li>
          }
        </ul>
      }
    </div>
  </div>
  <div class="Dialog__actions" mat-dialog-actions>
    <span class="flex-1"></span>
    <button type="button" mat-button (click)="dialogRef.close(null)">Avbryt</button>
  </div>
</div>
