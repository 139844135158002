<div class="Searchbar Searchbar--{{ style }} flex items-center justify-start">
  <div class="Searchbar__wrapper flex-1 flex items-center justify-start">
    @if (nav) {
      <button class="Searchbar__button md:hidden" mat-icon-button (click)="nav.toggle()"><mat-icon>menu</mat-icon></button>
    }
    <form class="Searchbar__form flex-1 flex items-center justify-start" autocomplete="off" (ngSubmit)="onSearch(searchValue)">
      <mat-icon>search</mat-icon>
      <input class="Searchbar__input flex-1" [(ngModel)]="searchValue" name="searchValue" type="search" [placeholder]="placeholder" />
      <button mat-icon-button type="button" [hidden]="!searchValue?.length" (click)="clear()"><mat-icon>clear</mat-icon></button>
    </form>
  </div>
</div>
