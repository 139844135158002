<form class="Dialog Dialog--repeater" [formGroup]="form" (ngSubmit)="submit()">
  <mat-toolbar color="primary" class="Dialog__header">
    <div>
      <h2 class="Dialog__title" mat-dialog-title><mat-icon>repeat</mat-icon> Upprepa aktivitet</h2>
    </div>
    <span class="flex-1"></span>
    <div class="Dialog__header-buttons">
      <button mat-icon-button type="button" (click)="dismiss()"><mat-icon>close</mat-icon></button>
    </div>
  </mat-toolbar>
  <div class="Dialog__content" mat-dialog-content>
    <div class="Dialog__form">
      <mat-form-field appearance="fill">
        <mat-label>Frekvens</mat-label>
        <mat-select formControlName="frequency" [compareWith]="compareFn">
          @for (option of service.frequencies; track option) {
          <mat-option [value]="option">{{ option.label }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      @if (form.get('frequency').value.value !== 'weekday') {
      <mat-form-field appearance="fill">
        <mat-label>Intervall</mat-label>
        <mat-select formControlName="interval">
          <mat-select-trigger>
            {{ form.get('interval').value }} {{ form.get('interval').value > 1 ? form.get('frequency').value.labelMultiple :
            form.get('frequency').value.labelSingle }}
          </mat-select-trigger>
          @for (option of interval; track option) {
          <mat-option [value]="option"
            >{{ option }} {{ option > 1 ? form.get('frequency').value.labelMultiple : form.get('frequency').value.labelSingle }}</mat-option
          >
          }
        </mat-select>
      </mat-form-field>
      } @if (form.get('frequency').value.value === 'weekday') {
      <mat-form-field appearance="fill">
        <mat-label>Välj veckodagar</mat-label>
        <mat-select multiple formControlName="interval">
          @for (day of service.weekdays; track day) {
          <mat-option [value]="day.value">{{ day.label }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      }
      <p class="mat-caption">Avsluta upprepning</p>
      <mat-radio-group formControlName="end_activity" appearance="fill">
        <mat-radio-button color="primary" value="never" class="mr-2">Aldrig</mat-radio-button>
        <mat-radio-button color="primary" value="date">Välj datum</mat-radio-button>
      </mat-radio-group>

      <mat-form-field [hidden]="form.get('end_activity').value !== 'date'" appearance="fill" class="mt-2">
        <mat-label>Välj datum</mat-label>
        <input matInput formControlName="end_date" [matDatepicker]="endDatePicker" [min]="min" />
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>

      <p class="mat-caption">{{ service.getRepeatLabel(form.value) }}</p>
    </div>
  </div>
  <div class="Dialog__actions" mat-dialog-actions>
    <span class="flex-1"></span>
    <button type="button" mat-button (click)="dialogRef.close()">Stäng</button>
    <button type="submit" color="primary" mat-raised-button [disabled]="form.invalid">Spara</button>
  </div>
</form>
