import { Injectable, inject } from '@angular/core';
import { IAwareCollection } from '@appbolaget/aware-http';
import { Client, Thread } from '@appbolaget/aware-model';
import {
    Observable,
    Subject,
    delay,
    delayWhen,
    distinctUntilChanged,
    filter,
    firstValueFrom,
    map,
    merge,
    of,
    scan,
    shareReplay,
    switchMap,
    take,
    tap,
    timer,
} from 'rxjs';
import { format, sub } from 'date-fns';
import { MessagesRepositoryService } from './messages.repository';
import { DOCUMENT } from '@angular/common';
import { AwareAuthService } from '@appbolaget/aware-auth';
import { ChatService } from './components/chat/chat.service';
import { Router } from '@angular/router';
import { MESSAGES_ROUTE, THREAD_ACTIONS, isAwareCollection } from './symbols';
import { Config } from '@services/config';
import { IMessagesConfig } from './messages.config';
import { ThreadSelectionPromptResult } from './components/thread-selection-prompt/thread-selection-prompt.component';
@Injectable({ providedIn: 'root' })
export class MessagesService {
    #awareAuth = inject(AwareAuthService);
    #document = inject(DOCUMENT);
    #router = inject(Router);
    #repository = inject(MessagesRepositoryService);
    #chatService = inject(ChatService);
    #messagesConfig = inject(Config).get<IMessagesConfig>('messages');

    lastFetchedThreadsTimestamp: Date = null;
    threadDeleted$ = new Subject<void>();

    threadsAfterTimestamp$ = merge(
        timer(this.#messagesConfig.threadRefreshIntervalInSeconds * 1000, this.#messagesConfig.threadRefreshIntervalInSeconds * 1000).pipe(
            filter(() => this.#document.visibilityState === 'visible'),
            delayWhen(() => this.#awareAuth.isAuthenticated$.pipe(filter((isAuthenticated) => isAuthenticated))),
        ),
        this.#chatService.newThreadCreated$.pipe(delay(300)),
    ).pipe(
        switchMap(() => this.#repository.getThreadsAfterTimestamp(format(this.lastFetchedThreadsTimestamp, 'yyyy-MM-dd HH:mm:ss'))),
        tap(() => {
            this.lastFetchedThreadsTimestamp = sub(new Date(), { seconds: 1 });
        }),
    );

    threadActions$ = [
        this.#chatService.newThreadCreated$.pipe(map((v) => ({ action: THREAD_ACTIONS.NewThreadCreated, data: v }))),
        this.#chatService.updatedThreadTitle$.pipe(map((v) => ({ action: THREAD_ACTIONS.UpdatedThreadTitle, data: v }))),
        this.#chatService.markThreadAsSeen$.pipe(map((v) => ({ action: THREAD_ACTIONS.MarkThreadAsSeen, data: v }))),
        this.#chatService.newMessageSent$.pipe(map((v) => ({ action: THREAD_ACTIONS.NewMessageSent, data: v[0] }))),
        this.#chatService.newMessagesRecieved$.pipe(map((v) => ({ action: THREAD_ACTIONS.NewMessagesRecieved, data: v }))),
        this.#chatService.changedThreadParticipants$.pipe(
            map((v) => ({ action: THREAD_ACTIONS.ChangedThreadParticipants, data: v })),
            switchMap((v) =>
                this.#chatService.activeThread$.pipe(
                    filter((thread) => !!thread),
                    map((thread) => ({ ...v, thread })),
                ),
            ),
        ),
        this.#chatService.leaveThread$.pipe(
            switchMap((thread) => this.#repository.deleteThread(thread).pipe(map(() => thread))),
            map((v) => ({ action: THREAD_ACTIONS.LeftThread, data: v })),
            tap(() => {
                this.#chatService.setActiveThread(null);
                this.#router.navigate(MESSAGES_ROUTE);
            }),
        ),
    ];

    threads$: Observable<IAwareCollection<Thread>> = this.#awareAuth.onLogin$.pipe(
        tap(() => {
            this.lastFetchedThreadsTimestamp = new Date();
        }),
        delayWhen(() =>
            this.#awareAuth.isAuthenticated$.pipe(
                distinctUntilChanged(),
                filter((isAuthenticated) => isAuthenticated),
            ),
        ),
        switchMap(() =>
            merge(this.#repository.getInitialThreads(), this.threadsAfterTimestamp$, ...this.threadActions$).pipe(
                scan((acc, curr) => {
                    // First scan operation, acc is null
                    if (!acc) {
                        if (isAwareCollection(curr)) {
                            return curr as IAwareCollection<Thread>;
                        }

                        return {
                            data: [],
                            page: 1,
                            next: false,
                        };
                    }

                    // Curr is an AwareCollection, so we merge the data
                    if (isAwareCollection(curr)) {
                        const data = [...curr.data, ...(acc as IAwareCollection<Thread>).data];
                        const uniqueData: Thread[] = data.reduce((acc, curr) => {
                            if (!acc.find((t) => t.uuid === curr.uuid)) {
                                acc.push(curr);
                            }

                            return acc;
                        }, []);

                        return {
                            ...(acc as IAwareCollection<Thread>),
                            data: uniqueData,
                        };
                    }

                    // Here we can assume curr is a THREAD_ACTION, so we manipulate the acc
                    switch (curr.action) {
                        case THREAD_ACTIONS.NewThreadCreated:
                            const newThread = curr.data;
                            const data = [...acc.data, newThread];
                            const uniqueData: Thread[] = data.reduce((acc, curr) => {
                                if (!acc.find((t) => t.uuid === curr.uuid)) {
                                    acc.push(curr);
                                }

                                return acc;
                            }, []);

                            return {
                                ...acc,
                                data: uniqueData,
                            };
                        case THREAD_ACTIONS.ChangedThreadParticipants:
                            const threads = acc.data.map((thread) => {
                                if (thread.uuid === curr.thread.uuid) {
                                    thread.clients = curr.data;
                                }

                                return thread;
                            });

                            return {
                                ...acc,
                                data: threads,
                            };
                        case THREAD_ACTIONS.UpdatedThreadTitle:
                            const thread = curr.data;
                            const threads2 = acc.data.map((thread2) => {
                                if (thread2.uuid === thread.uuid) {
                                    thread2.title = thread.title;
                                }

                                return thread2;
                            });

                            return {
                                ...acc,
                                data: threads2,
                            };
                        case THREAD_ACTIONS.MarkThreadAsSeen:
                            const threadId = curr.data;
                            const threads3 = acc.data.map((thread3) => {
                                if (thread3.uuid === threadId) {
                                    thread3.clients = thread3.clients.map((c) => {
                                        if (c.uuid === this.#awareAuth.client.uuid) {
                                            c.seen_at = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
                                        }
                                        return c;
                                    });
                                }
                                return thread3;
                            });

                            return {
                                ...acc,
                                data: threads3,
                            };

                        case THREAD_ACTIONS.NewMessageSent:
                        case THREAD_ACTIONS.NewMessagesRecieved:
                            const newMessage = Array.isArray(curr.data) ? curr.data[curr.data.length - 1] : curr.data;
                            const threads4 = acc.data.map((thread4) => {
                                if (thread4.id === newMessage.thread_id) {
                                    thread4.last_message = {
                                        client: newMessage.client.uuid,
                                        message: newMessage.message,
                                        created_at: newMessage.created_at,
                                        type: null,
                                        uuid: newMessage.uuid,
                                    };
                                }
                                return thread4;
                            });

                            return {
                                ...acc,
                                data: threads4,
                            };

                        case THREAD_ACTIONS.LeftThread:
                            const threads5 = acc.data.filter((thread5) => thread5.uuid !== curr.data.uuid);

                            return {
                                ...acc,
                                data: threads5,
                            };
                    }

                    return acc;
                }, null as IAwareCollection<Thread>),
            ),
        ),
        shareReplay(1),
    );

    unreadThreads$: Observable<number> = this.threads$.pipe(
        filter((threads) => !!threads),
        map((threads) => threads.data.filter((thread) => this.threadHasUnreadMessages(thread)).length),
    );

    async getMeetingByThread(thread: Thread): Promise<Thread> {
        // Meeting already exists on thread
        if (thread.meetings?.length) {
            return thread;
        }

        const newFetched = await firstValueFrom(this.getThreadByUuid(thread.uuid));

        // Meeting exists on re fetched thread. For example if my fellow chatter presses the button right before I do.
        if (newFetched.meetings?.length) {
            return newFetched;
        }

        const updatedThread: Thread = await firstValueFrom(
            this.#repository.updateThread(thread.uuid, {
                meeting: {
                    start_at: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
                    type: 'video',
                    seats: thread.clients.length,
                },
            }),
        );

        // Meeting is created and returned with the updated thread
        if (updatedThread.meetings?.length) {
            return updatedThread;
        }

        throw new Error('Kunde ej starta videomöte');
    }

    getThreads(): Observable<IAwareCollection<Thread>> {
        return this.#repository.getInitialThreads().pipe(
            map((threads) => {
                threads.data = threads.data.filter((thread) => !!thread.last_message?.uuid);

                return threads;
            }),
        );
    }

    getThreadByUuid(uuid: string): Observable<Thread> {
        return this.threads$.pipe(
            filter((threads) => !!threads),
            take(1),
            switchMap((threads) => {
                const thread = threads.data.find((thread) => thread.uuid === uuid);
                return thread ? of(thread) : this.#repository.getThreadByUuid(uuid);
            }),
        );
    }

    makeThreadWithClients(clients: Client[]): Thread {
        const thread = new Thread({ clients: [...clients, this.#awareAuth.client] });

        return thread;
    }

    openThreadWithClients(clients: Client[]): Observable<ThreadSelectionPromptResult> {
        return this.#repository.getThreadsWithClients([...clients, this.#awareAuth.client]).pipe(
            map((threads) => {
                if (threads.data.length) {
                    const privateConversation = threads.data.find((thread) => !thread.isGroupConversation);
                    if (clients.length === 1 && privateConversation && threads.data.length === 1) {
                        return privateConversation;
                    }

                    return threads.data;
                }

                return THREAD_ACTIONS.CreateNew;
            }),
            switchMap((threadOrThreads) => {
                if (Array.isArray(threadOrThreads)) {
                    const privateConversation = threadOrThreads.find((thread) => !thread.isGroupConversation);
                    return this.#chatService.showThreadSelectionPrompt(threadOrThreads, null, false, !privateConversation);
                }

                return of(threadOrThreads);
            }),
            tap((action) => {
                if (action instanceof Thread) {
                    this.#router.navigate([...MESSAGES_ROUTE, action.uuid]);
                } else if (action === THREAD_ACTIONS.CreateNew) {
                    this.#chatService.openNewThreadWithClients(clients);
                }
            }),
        );
    }

    threadHasUnreadMessages(thread: Thread): boolean {
        if (!thread.last_message || thread.last_message.client === this.#awareAuth.client.uuid) {
            return false;
        }

        const me = thread.clients.find((c) => c.uuid === this.#awareAuth.client.uuid);
        if (!me?.seen_at) {
            return true;
        }

        return me?.seen_at < thread.last_message.created_at;
    }
}
