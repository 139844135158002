<div class="Dialog Help w-[350px]">
  <mat-toolbar class="Dialog__header">
    <mat-toolbar-row class="Dialog__toolbar">
      <h2>{{ data?.title || 'Vad behöver du hjälp med?' }}</h2>
    </mat-toolbar-row>
    @if (!data?.hideSearch) {
      <mat-toolbar-row class="Dialog__toolbar Help__searchrow">
        <mat-form-field class="w-full">
          <mat-label>Sök</mat-label>
          <input matInput type="search" [formControl]="searchControl" autocomplete="off" />
        </mat-form-field>
      </mat-toolbar-row>
    }
  </mat-toolbar>

  <mat-progress-bar mode="indeterminate" [hidden]="!IS_LOADING"></mat-progress-bar>
  <div mat-dialog-content class="Dialog__content" [class.Dialog__content--nosearch]="data?.hideSearch">
    @if (posts$ | async; as posts) {
      <mat-nav-list class="pt-0">
        @for (post of posts.data; track post) {
          <a mat-list-item (click)="viewPost($event, post)" href="#" class="Help-item py-2">
            <div matListItemTitle>
              <div class="flex flex-col">
                @if (post.categories?.length) {
                  <div class="flex">
                    @for (category of post.categories; track category) {
                      <span class="Help-item__category" [style.--category-background]="category.getMeta('color')">{{
                        category.title
                      }}</span>
                    }
                  </div>
                }
                <h3 class="Help-item__title mb-3">
                  @if (data?.showNotificationBadge) {
                    <span class="Help-item__title__unread-notification" [hidden]="!(postHasNotification(post) | async)"></span>
                  }
                  {{ post.title }}
                </h3>
              </div>
            </div>
            @if (post.excerpt) {
              <p matListItemLine class="Help-item__excerpt">
                <span [innerHtml]="post.excerpt || (post.content | striphtml)"></span>
              </p>
            }
            @if (data?.showDate) {
              <div matListItemLine>
                <span class="text-gray text-small">{{ post.publish_at | amDateFormat: 'YYYY-MM-DD HH:mm' }}</span>
              </div>
            }
            @if (post.attr('keyword')) {
              <div class="flex pb-1" matListItemLine>
                @for (tag of post.attrs('keyword'); track tag) {
                  <span class="Help-item__category Help-item__category--tag">{{ tag }}</span>
                }
              </div>
            }
          </a>
        }
      </mat-nav-list>
    }
  </div>
</div>
