<div [formGroup]="form">
  @if (INITTED) {
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2" formArrayName="meta">
      @for (meta of metaArray.controls; track meta; let i = $index) {
        <div class="flex flex-col" [formGroupName]="i">
          <ng-container
            [ngTemplateOutlet]="templateTypes[meta.get('type').value.type]"
            [ngTemplateOutletContext]="{ meta: meta, index: i }"
          ></ng-container>
        </div>
      }
    </div>
  }
</div>

<ng-template #colorPickerTemplate let-meta="meta" let-index="index">
  <div [formGroup]="form">
    <div formArrayName="meta">
      <div [formGroupName]="index">
        <mat-form-field appearance="fill" formGroupName="meta" class="w-full">
          <mat-label>{{ meta.get('type').value.label | translate }}</mat-label>
          <input matInput formControlName="value" type="color" />
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #textFieldTemplate let-meta="meta" let-index="index">
  <div [formGroup]="form">
    <div formArrayName="meta">
      <div [formGroupName]="index">
        <mat-form-field appearance="fill" formGroupName="meta" class="w-full">
          <mat-label>{{ meta.get('type').value.label | translate }}</mat-label>
          <input matInput formControlName="value" [type]="meta.get('type').value.inputType ? meta.get('type').value.inputType : 'text'" />
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #textAreaTemplate let-meta="meta" let-index="index">
  <div [formGroup]="form">
    <div formArrayName="meta">
      <div [formGroupName]="index">
        <mat-form-field appearance="fill" formGroupName="meta" class="w-full">
          <mat-label>{{ meta.get('type').value.label | translate }}</mat-label>
          <textarea matInput formControlName="value" cdkTextareaAutosize></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-template>
