import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BaseDialogComponent } from '@helpers/base';
import { QrCodeModule } from 'ng-qrcode';

@Component({
    selector: 'app-qr-code',
    templateUrl: 'qr-code.component.html',
    standalone: true,
    imports: [MatToolbarModule, MatIconModule, QrCodeModule, MatButtonModule, MatDialogModule],
})
export class QrCodeDisplayComponent extends BaseDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<QrCodeDisplayComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        super();
    }

    print() {
        window.print();
    }
}
