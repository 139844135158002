<aw-card>
  <ng-template awCardHeader>
    <div class="Widget__header__inner p-3 flex items-center justify-between">
      <div class="flex-1">
        <mat-card-title class="Widget__title">{{ title }}</mat-card-title>
        @if (subtitle) {
        <mat-card-subtitle class="Widget__subtitle">{{ subtitle }}</mat-card-subtitle>
        }
      </div>
      <div>
        <ng-container *awIsGranted="config?.remove.required_permissions">
          @if (canRemove) {
          <button mat-icon-button (click)="remove()" [matTooltip]="removeButtonTooltip | translate" matTooltipPosition="above">
            <mat-icon>delete</mat-icon>
          </button>
          }
        </ng-container>
        <ng-container *awIsGranted="config?.add.required_permissions">
          @if (canAdd) {
          <button mat-icon-button (click)="add()" [matTooltip]="'COMMON.ADD' | translate" matTooltipPosition="above">
            <mat-icon>playlist_add</mat-icon>
          </button>
          }
        </ng-container>
      </div>
    </div>
  </ng-template>

  <ng-content></ng-content>
</aw-card>
