<div class="flex flex-col" [formGroup]="group">
  <mat-slide-toggle class="mb-3" formControlName="enabled" color="primary">Aktivera</mat-slide-toggle>

  <aw-multiselect-2
    [hidden]="!formGroup.get('enabled').value"
    [request]="rolesRequest"
    [control]="formGroup.get('roles')"
    [label]="'Sök efter roller'"
    [enableSearch]="true"
    [multiple]="true"
    appearance="fill"
  ></aw-multiselect-2>
</div>
