import { Component, Inject, OnDestroy, OnInit, inject } from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { AwareHttpService, IAwareCollection } from '@appbolaget/aware-http';
import { Post } from '@appbolaget/aware-model';
import { Notification } from '@modules/notification/Notification';
import { NotificationState } from '@modules/notification/notification.state';
import { Store } from '@ngxs/store';
import { DialogService } from '@viewservices';
import { Observable } from 'rxjs';
import { debounceTime, finalize, startWith, switchMap, takeWhile, tap } from 'rxjs/operators';
import { HelpPostDialogComponent } from '../post-dialog/post-dialog.component';
import { AwareAuthService } from '@appbolaget/aware-auth';
import { MomentModule } from 'ngx-moment';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AsyncPipe } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { StripHtmlPipe } from '@helpers/pipes';

@Component({
    selector: 'help-main-dialog',
    templateUrl: 'main-dialog.component.html',
    styleUrls: ['./main-dialog.component.scss'],
    imports: [
        MatToolbarModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatProgressBarModule,
        MatDialogModule,
        MatListModule,
        AsyncPipe,
        MomentModule,
        StripHtmlPipe,
    ]
})
export class HelpMainDialogComponent implements OnInit, OnDestroy {
    ALIVE: boolean;
    IS_LOADING: boolean;

    posts$: Observable<IAwareCollection<Post>>;
    searchControl = new UntypedFormControl();

    private awareAuth = inject(AwareAuthService);
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private api: AwareHttpService,
        private dialogService: DialogService,
        private store: Store,
    ) {}

    ngOnInit() {
        this.ALIVE = true;

        this._getPosts();
    }

    ngOnDestroy() {
        this.ALIVE = false;
    }

    postHasNotification(post: Post): Observable<Notification> {
        return this.store.select(NotificationState.notificationByValue(post.uuid));
    }

    viewPost(e, post: Post) {
        e.preventDefault();

        this.dialogService.open(HelpPostDialogComponent, {
            data: { post },
            width: '90%',
            maxWidth: '1000px',
        });
    }

    private _getPosts() {
        this.IS_LOADING = true;

        this.posts$ = this.searchControl.valueChanges.pipe(
            debounceTime(500),
            tap(() => (this.IS_LOADING = true)),
            startWith(''),
            takeWhile(() => this.ALIVE),
            switchMap((query: string) => {
                const me = this.awareAuth.client;

                const attributes: any = {
                    env: ['admin', '*'],
                };

                const myRoleKeys = me.roles.filter((r) => r.key !== null).map((r) => r.key);
                if (myRoleKeys?.length) {
                    attributes.role = [...myRoleKeys, '*'];
                }

                return this.api
                    .get('posts/search')
                    .parameter('query', query)
                    .parameter('attributes', JSON.stringify(attributes))
                    .sort('model.publish_at', 'asc')
                    .parameters(this.data?.params || {})
                    .type(this.data?.type || 'help')
                    .with('attributes,categories.meta')
                    .header('hidden', '0')
                    .stream('up')
                    .toCollection(Post)
                    .execute()
                    .pipe(finalize(() => (this.IS_LOADING = false)));
            }),
        );
    }
}
