<form [formGroup]="form" class="flex flex-col">
  <mat-list [formArrayName]="remindersKey">
    @for (reminder of reminderControls; track reminder; let i = $index) {
      <mat-list-item [formGroupName]="i" class="Reminder" [class.Reminder--toDelete]="reminder.get('toDelete').value">
        <mat-icon matListItemIcon class="!mr-3">circle_notifications</mat-icon>
        <div class="flex items-center justify-between w-full">
          <span class="mat-body whitespace-pre-wrap"
            >Påminnelse skickas
            {{
              'POST.REMINDERS.FREQUENCIES.' + reminder.get('frequency').value
                | translate: { amount: reminder.get('amount').value }
                | translateSelector: reminder.get('amount').value
            }}
            {{ 'POST.REMINDERS.in_advance' | translate }}</span
          >
          <button mat-icon-button class="mr-2" (click)="removeReminder(reminder)">
            <mat-icon>{{ reminder.get('toDelete').value ? 'restore_from_trash' : 'delete' }}</mat-icon>
          </button>
        </div>
      </mat-list-item>
    }
  </mat-list>
  <div class="px-3 mb-3 flex flex-col">
    @if (reminders.length < 1 || config.multiple) {
      <button mat-button color="primary" [matMenuTriggerFor]="newReminderPopover">
        <mat-icon>add_alert</mat-icon> {{ 'POST.REMINDERS.createNew' | translate }}
      </button>
    }
  </div>
</form>

<mat-menu #newReminderPopover="matMenu">
  <ng-template matMenuContent>
    <div class="p-3 flex flex-col" [formGroup]="newReminderForm" (click)="$event.stopPropagation()">
      <h3 class="mat-headline-6">{{ 'POST.REMINDERS.createNew' | translate }}</h3>
      <mat-form-field appearance="fill">
        <mat-label>Antal</mat-label>
        <input matInput type="number" formControlName="amount" />
      </mat-form-field>

      <mat-radio-group formControlName="frequency" class="flex flex-col mb-3" color="primary">
        @for (freq of frequencies; track freq) {
          <mat-radio-button [value]="freq.value"
            >{{ freq.label }} {{ freq.value === newReminderForm.get('frequency').value ? ' i förväg' : '' }}</mat-radio-button
          >
        }
      </mat-radio-group>

      <mat-form-field appearance="fill">
        <mat-label>Mottagare av påminnelse</mat-label>
        <mat-select formControlName="inherit">
          @for (channel of channels; track channel) {
            <mat-option [value]="channel.value">{{ channel.label }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <button mat-raised-button color="primary" (click)="addReminder()">{{ 'POST.REMINDERS.add' | translate }}</button>
    </div>
  </ng-template>
</mat-menu>

<!-- <mde-popover #newReminderPopover="mdePopover" [mdePopoverCloseOnClick]="false" mdePopoverPositionX="before" mdePopoverPositionY="above" [mdePopoverOverlapTrigger]="false">
    <mat-card class="Card p-3 flex flex-col" style="width: 300px" [formGroup]="newReminderForm">
      <h3 class="mat-title">{{ 'POST.REMINDERS.createNew' | translate }}</h3>
      <mat-form-field appearance="fill">
        <mat-label>Antal</mat-label>
        <input matInput type="number" formControlName="amount">
      </mat-form-field>

      <mat-radio-group formControlName="frequency" class="flex flex-col mb-3" color="primary">
        <mat-radio-button *ngFor="let freq of frequencies" [value]="freq.value">{{ freq.label }} {{ freq.value === newReminderForm.get('frequency').value ? ' i förväg' : '' }}</mat-radio-button>
      </mat-radio-group>

      <mat-form-field appearance="fill">
        <mat-label>Mottagare av påminnelse</mat-label>
        <mat-select formControlName="inherit">
          <mat-option *ngFor="let channel of channels" [value]="channel.value">{{ channel.label }}</mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-raised-button color="primary" (click)="addReminder()">{{ 'POST.REMINDERS.add' | translate }}</button>
    </mat-card>
  </mde-popover> -->
