import {
    ContentChild,
    ContentChildren,
    DestroyRef,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    QueryList,
    TemplateRef,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject, debounceTime, delay, filter, fromEvent, map, merge, startWith, takeUntil, tap } from 'rxjs';

@Directive({ selector: '[topBarUnitTree]', standalone: true })
export class TopBarUnitTreeDirective {
    #el = inject(ElementRef);
    #destroyRef = inject(DestroyRef);

    destroyed$ = new Subject<void>();
    @Input() gapWidths = 20;
    @Output() onHiddenElements = new EventEmitter<string[]>();
    @ContentChild('goUpButton') goUpButton: TemplateRef<any>;
    @ContentChildren('unitHierarchyItem') unitHierarchyItems: QueryList<ElementRef<HTMLElement>>;

    hiddenElements: Map<HTMLElement, number> = new Map();
    unitItemChanges$: Subject<QueryList<ElementRef<HTMLElement>>> = new Subject();
    domManipulated$ = new Subject<void>();
    windowResize$ = fromEvent(window, 'resize').pipe(takeUntilDestroyed(), debounceTime(50));

    eventListeners$ = merge(
        this.unitItemChanges$.pipe(
            tap(() => {
                this.hiddenElements.clear();
                this.onHiddenElements.emit([]);
            }),
            delay(10),
        ),
        this.windowResize$,
        this.domManipulated$.pipe(
            tap(() => {
                const hiddenElementUuids = Array.from(this.hiddenElements.keys()).map((el) => el.dataset.uuid);
                this.onHiddenElements.emit(hiddenElementUuids);
            }),
        ),
    );

    ngOnInit() {
        this.#destroyRef.onDestroy(() => {
            this.destroyed$.next();
            this.destroyed$.complete();
        });

        this.eventListeners$
            .pipe(
                takeUntil(this.destroyed$),
                filter(() => true),
                map((_) => [this.#el, this.unitHierarchyItems.toArray()]),
                tap(([unitTreeElement, items]: [ElementRef<HTMLElement>, ElementRef<HTMLElement>[]]) => {
                    const containerWidth = unitTreeElement.nativeElement.offsetWidth;
                    const childrenArray: HTMLElement[] = [].slice.call(unitTreeElement.nativeElement.children);
                    const treeWidth = childrenArray.reduce((acc, child) => acc + child.offsetWidth, 0);

                    if (containerWidth < treeWidth + this.gapWidths) {
                        const firstVisibleItem = items.find((item) => item.nativeElement.offsetLeft > 0);
                        if (firstVisibleItem) {
                            this.hiddenElements.set(firstVisibleItem.nativeElement, firstVisibleItem.nativeElement.offsetWidth);
                            firstVisibleItem.nativeElement.style.display = 'none';
                            this.domManipulated$.next();
                        }
                    } else {
                        const hiddenItems = items.filter((item) => item.nativeElement.style.display === 'none');
                        const lastHiddenItem = hiddenItems[hiddenItems.length - 1];

                        if (lastHiddenItem) {
                            const widthOflastHiddenItem = this.hiddenElements.get(lastHiddenItem.nativeElement);

                            const showItem = containerWidth > treeWidth + widthOflastHiddenItem + this.gapWidths;
                            if (showItem) {
                                lastHiddenItem.nativeElement.style.display = '';
                                this.hiddenElements.delete(lastHiddenItem.nativeElement);
                                this.domManipulated$.next();
                            }
                        }
                    }
                }),
            )
            .subscribe();
    }

    ngAfterContentInit() {
        this.unitHierarchyItems.changes
            .pipe(
                takeUntil(this.destroyed$),
                startWith(this.unitHierarchyItems),
                tap((items: QueryList<ElementRef>) => {
                    this.hiddenElements.clear();
                    this.unitItemChanges$.next(items);
                }),
            )
            .subscribe();
    }
}
