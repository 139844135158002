import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgTemplateOutlet } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
    selector: 'aw-attribute-form',
    templateUrl: 'attribute-form.component.html',
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgTemplateOutlet,
        MatFormFieldModule,
        MatChipsModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatOptionModule,
        MatCheckboxModule,
        MatButtonModule,
        MatDatepickerModule,
        MatButtonModule,
        TextFieldModule,
    ]
})
export class AttributeFormComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() form: UntypedFormGroup;
    @Input() property = 'attributes';
    @Input() customAttributes = false;
    @Input() customAttributesProperty = 'customAttributes';
    @Input() keywords = false;
    @Input() keywordsProperty = 'keywordAttributes';
    @Input() containerClass = 'col-12 col-md-6 col-xl-4';

    ALIVE: boolean;
    INITTED: boolean;

    @ViewChild('textFieldTemplate') textFieldTemplate: TemplateRef<any>;
    @ViewChild('textAreaTemplate') textAreaTemplate: TemplateRef<any>;
    @ViewChild('colorPickerTemplate') colorPickerTemplate: TemplateRef<any>;
    @ViewChild('selectTemplate') selectTemplate: TemplateRef<any>;
    @ViewChild('checkboxTemplate') checkboxTemplate: TemplateRef<any>;
    @ViewChild('datepickerTemplate') datepickerTemplate: TemplateRef<any>;

    myCustomAttributes = new UntypedFormArray([]);
    myCustomKeywords = new UntypedFormArray([]);

    get attributeArray(): UntypedFormArray {
        return this.form.get(this.property) as UntypedFormArray;
    }

    get customAttributeArray(): UntypedFormArray {
        return this.form.get(this.customAttributesProperty) as UntypedFormArray;
    }

    get keywordArray(): UntypedFormArray {
        return this.form.get(this.keywordsProperty) as UntypedFormArray;
    }

    customForm: UntypedFormGroup;
    templateTypes: any;

    constructor(
        private fb: UntypedFormBuilder,
        private cdRef: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.ALIVE = true;

        // Check if form has attributes property and if not create it
        if (!this.form.get(this.property)) {
            this.form.addControl(this.property, this.fb.array([]));
        }

        // Check if form has customAttributes property and if not create it
        if (this.customAttributes && !this.form.get(this.customAttributesProperty)) {
            this.form.addControl(this.customAttributesProperty, this.fb.array([]));
        }

        // Check if form has keywordAttributes property and if not create it
        if (this.keywords && !this.form.get(this.keywordsProperty)) {
            this.form.addControl(this.keywordsProperty, this.fb.array([]));
        }

        this._buildForm();
    }

    ngOnDestroy() {
        this.ALIVE = false;
    }

    ngAfterViewInit() {
        this.templateTypes = {
            text: this.textFieldTemplate,
            textarea: this.textAreaTemplate,
            color: this.colorPickerTemplate,
            select: this.selectTemplate,
            datepicker: this.datepickerTemplate,
            checkbox: this.checkboxTemplate,
        };

        // Expression changed bs
        setTimeout(() => {
            this.INITTED = true;
            this.cdRef.detectChanges();
        }, 10);
    }

    addCustomAttribute(values) {
        this.customAttributeArray.push(this.fb.group(values));

        this.customForm.patchValue({
            key: '',
            value: '',
        });
    }

    addKeyword(e: MatChipInputEvent) {
        const value = e.value.trim();

        if (!value) {
            return;
        }

        const exists = this.keywordArray.controls.find((c) => c.value.value.toLowerCase() === value.toLowerCase());

        if (exists) {
            return;
        }

        this.keywordArray.push(
            this.fb.group({
                key: 'keyword',
                value: e.value,
                type: 'simple',
                deleted: false,
            }),
        );

        e.input.value = '';
    }

    removeKeyword(control: UntypedFormControl, index: number) {
        if (control.value.uuid) {
            control;
        }

        this.keywordArray.removeAt(index);
    }

    toggleDeleteCustomAttribute(array: UntypedFormArray = this.customAttributeArray, index: number) {
        const attr = array.at(index);

        if (attr.get('uuid')) {
            array.at(index).patchValue({
                deleted: array.at(index).get('deleted').value ? false : true,
            });
        } else {
            array.removeAt(index);
        }
    }

    private _buildForm() {
        this.customForm = this.fb.group({
            key: ['', Validators.required],
            value: ['', Validators.required],
            type: ['simple', Validators.required],
            deleted: false,
        });
    }
}
