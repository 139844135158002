import { Component, OnInit, inject } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';

import { VersionService } from './services';
import { SnackbarService } from './views/services';
import { LanguageService } from './views/services/language.service';
import { filter, tap } from 'rxjs/operators';
import { ProgressBarMode, MatProgressBarModule } from '@angular/material/progress-bar';
import { AwareAuthService } from '@appbolaget/aware-auth';
import { environment } from '@env';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [MatProgressBarModule, RouterOutlet]
})
export class AppComponent implements OnInit {
    title = 'app';

    progressbar: { mode: ProgressBarMode; show: boolean } = {
        mode: 'indeterminate',
        show: false,
    };

    #awareAuth = inject(AwareAuthService);

    constructor(
        public router: Router,
        public version: VersionService,
        public snackbar: SnackbarService,
        private languageService: LanguageService,
    ) {
        this.languageService.init();
    }

    ngOnInit() {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
            window.scrollTo(0, 0);
        });

        this.#awareAuth.onLogout$
            .pipe(
                tap(({ state }) => {
                    if (state.oauthProvider && state.oauthSignoutRequest && state.oauthUnit) {
                        const url = `${environment.api.url}/authenticate/signout?options=${JSON.stringify({
                            [state.oauthProvider]: {
                                config_id: state.oauthUnit,
                                redirect_url: `http://${window.location.host}/auth/login`,
                            },
                        })}&token=${state.token}`;

                        window.location.href = url;
                    }
                }),
            )
            .subscribe();

        // this.actions$
        //     .pipe(
        //         ofActionDispatched(Logout),
        //         tap(() => {
        //             this.router.navigate(['/auth']);
        //         })
        //     )
        //     .subscribe();
    }
}
